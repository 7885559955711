<script lang="ts">
  import {onMount} from 'svelte';
  import {fade} from 'svelte/transition';
  import {notification} from '../config/stores';
  import Button from "./Button.svelte";

  let mode = '';

  function clear() {
    notification.set(null);
  }

  onMount(() => {
    const unSub = notification.subscribe((value) => {
      if (value) {
        setTimeout(clear, 5000);
      }
    });

    const params = new URLSearchParams(location.search);
    mode = params.get('mode');

    return () => {
      unSub();
    };
  });
</script>

{#if $notification}
  <aside transition:fade={{ duration: 200 }} class:ios={mode === 'ios'}>
    <main class="p-a-s">
      <section class="ta-center">{$notification}</section>
    </main>
    <footer class="flex jc-center p-a-s">
      <Button size="small" on:click={clear}>Dismiss</Button>
    </footer>
  </aside>
{/if}

<style>
  aside {
    z-index: 10;
    position: fixed;
    top: 0;
    width: 100%;
    border-top: .5px solid #392F2F;
    background-color: rgba(0,0,0,0.5);
    color: #ffffffe0;
  }

  aside.ios {
    padding: 30px;
  }
</style>
