<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { STRIPE } from '../../config/config';
  import { page } from '../../config/stores';

  let stripe: any;
  let message = 'Loading...';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });

  function stripeLoaded() {
    // @ts-ignore
    stripe = window.Stripe(STRIPE.publicKey);

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          message = 'Success! Payment received.';
          setTimeout(() => {
            let parsed: any;

            try {
              const item = localStorage.getItem('last-state');

              if (item) {
                parsed = JSON.parse(item);
              }
            } catch (e) {}

            if (parsed && parsed.path) {
              const {path, ...state} = parsed;
              navigate(path, {state});
            } else {
              navigate('/');
            }
          }, 4000);
          break;

        case 'processing':
          message =
            "Payment processing. We'll update you when payment is received.";
          break;

        case 'requires_payment_method':
          message = 'Payment failed. Please try another payment method.';
          break;

        default:
          message = 'Something went wrong.';
          break;
      }
    });
  }

  onMount(() => {
    // @ts-ignore
    if (window.Stripe) {
      stripeLoaded();
    }
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">P∆YMENT STATUS</h1>
  <p class="col-12 p-y-0 c-white">{message}</p>
</div>

<svelte:head>
  {#if !window.Stripe}
    <script src="https://js.stripe.com/v3/" on:load={stripeLoaded}></script>
  {/if}
</svelte:head>
