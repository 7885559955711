import { random } from '@jaspero/utils';
import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { CanvasTexture, Color, Matrix3, Mesh, MeshPhysicalMaterial, Object3D, SphereGeometry, Spherical, Vector2, Vector3 } from 'three';
import { COLORS } from '../../../config/colors.const';
import { FlakesTexture } from './FlakesTexture';
import { Tentacle } from './Tentacle';
import variables from './variables';
const SPHERE_PROPS = {
    radius: 14
};
const MEASUREMENT_PROPS = {
    maxResult: 1000
};
const TENTACLE_PROPS = {
    count: 233,
    lengthMin: 10,
    lengthMax: 16
};
const SCALE_PROPS = {
    tentacleMin: .5,
    tentacleMax: 1.8,
    sphereMin: .5,
    sphereMax: 2.4
};
export class Organism {
    constructor(scene, results) {
        var _a, _b, _c;
        this.scene = null;
        this.group = null;
        this.sphere = null;
        this.tentacles = [];
        this.target = null;
        this.scene = scene;
        this.results = results;
        this.radius = SPHERE_PROPS.radius;
        this.group = new Object3D();
        this.scene.add(this.group);
        const normalMap = new CanvasTexture(new FlakesTexture());
        normalMap.wrapS = THREE.RepeatWrapping;
        normalMap.wrapT = THREE.RepeatWrapping;
        normalMap.repeat.x = 10;
        normalMap.repeat.y = 6;
        normalMap.anisotropy = 16;
        const sphere = new Mesh(new SphereGeometry(SPHERE_PROPS.radius, 64, 32), new MeshPhysicalMaterial({
            metalness: 0.1,
            roughness: 0.3,
            opacity: 0,
            color: new Color('white'),
            normalMap: normalMap,
            transparent: true,
            normalScale: new Vector2(0.15, 0.15)
        }));
        sphere.receiveShadow = true;
        sphere.castShadow = true;
        this.group.add(sphere);
        this.sphere = sphere;
        this.setRadialProp();
        this.setupTentacles((_a = this.results) === null || _a === void 0 ? void 0 : _a.tentacles);
        // Set rediating tentacle
        if ((_b = this.results) === null || _b === void 0 ? void 0 : _b.airQuality) {
            let ind = Math.round((this.results.airQuality * COLORS.length) / 500);
            /**
             * TODO:
             * For some reason low numbers aren't being shown properly.
             * This is something we need to investigate.
             */
            if (ind < 30) {
                ind = 30;
            }
            const index = this.getTentacleByIndex(ind);
            if (index) {
                this.radiate(index, 1, 0);
            }
        }
        // Set initial scale
        if ((_c = this.results) === null || _c === void 0 ? void 0 : _c.lungCapacity) {
            this.scale(this.results.lungCapacity);
        }
    }
    getTentacleByIndex(index) {
        return this.tentacles[index];
    }
    setRadialProp() {
        const diff = (this.radius - Math.sqrt((this.radius * this.radius - 0.2 * 0.2)));
        this.radialPos = this.radius - diff - 0.05;
    }
    scale(lungCapacity) {
        const tRange = SCALE_PROPS.tentacleMax - SCALE_PROPS.tentacleMin;
        const tScale = (lungCapacity * tRange) / 233;
        const tAdjusted = tScale + SCALE_PROPS.tentacleMin;
        const sRange = SCALE_PROPS.sphereMax - SCALE_PROPS.sphereMin;
        const sScale = (lungCapacity * sRange) / 233;
        const sAdjusted = sScale + SCALE_PROPS.sphereMin;
        this.group.scale.set(sAdjusted, sAdjusted, sAdjusted);
        this.radius = SPHERE_PROPS.radius * sAdjusted;
        this.setRadialProp();
        this.tentacles.forEach(tentacle => {
            tentacle.object.scale.set(tAdjusted, tAdjusted, tAdjusted);
        });
    }
    resetScale() {
        this.group.scale.set(1, 1, 1);
        this.radius = SPHERE_PROPS.radius;
        this.setRadialProp();
        this.tentacles.forEach(tentacle => {
            tentacle.object.scale.set(1, 1, 1);
            tentacle.userData = Object.assign(Object.assign({}, tentacle.userData), tentacle.defaultRandoms);
        });
    }
    setupTentacles(tentacles) {
        const { count } = TENTACLE_PROPS;
        const offset = 2 / count;
        const increment = Math.PI * (3 - Math.sqrt(5));
        const randomness = 1;
        // https://scholar.rose-hulman.edu/cgi/viewcontentgetInverse.cgi?article=1387&context=rhumj
        [...Array(233).keys()].map(i => {
            var _a, _b, _c, _d, _e;
            const ri = i + 1;
            if (tentacles === null || tentacles === void 0 ? void 0 : tentacles[i]) {
                this.addTentacle(i, tentacles[i].size, !!((_b = (_a = this.results) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b[ri]), COLORS[i], new Vector3(tentacles[i].position.x, tentacles[i].position.y, tentacles[i].position.z), tentacles[i].bottomThickness, tentacles[i].centerThickness, tentacles[i].reduceThreshold, tentacles[i].strainThreshold);
                return;
            }
            let length;
            let bottomThickness;
            let centerThickness;
            let reduceThreshold;
            let strainThreshold;
            let y = ((i * offset) - 1) + (offset / 2);
            let distance = Math.sqrt(1 - Math.pow(y, 2));
            let phi = ((i + randomness) % count) * increment;
            let x = Math.cos(phi) * distance;
            let z = Math.sin(phi) * distance;
            strainThreshold = random.float(0.4, 0.6);
            let btMin = .12;
            let btMax = .22;
            let ctMin = .8;
            let ctMax = 2.0;
            let rtMin = 2.5;
            let rtMax = 3.0;
            if (this.results) {
                length = TENTACLE_PROPS.lengthMin;
                bottomThickness = btMin;
                centerThickness = ctMin;
                reduceThreshold = rtMin;
                if ((_c = this.results.global) === null || _c === void 0 ? void 0 : _c[ri]) {
                    const result = this.results.global[ri] > MEASUREMENT_PROPS.maxResult ?
                        MEASUREMENT_PROPS.maxResult :
                        this.results.global[ri];
                    length += (result * (TENTACLE_PROPS.lengthMax - TENTACLE_PROPS.lengthMin) / 1000);
                    bottomThickness += (result * (btMax - btMin) / 1000);
                    centerThickness += (result * (ctMax - ctMin) / 1000);
                    bottomThickness += (result * (rtMax - rtMin) / 1000);
                }
            }
            else {
                length = random.float(TENTACLE_PROPS.lengthMin, TENTACLE_PROPS.lengthMax);
                bottomThickness = random.float(btMin, btMax);
                centerThickness = random.float(ctMin, ctMax);
                reduceThreshold = random.float(rtMin, rtMax);
            }
            x = x * .1;
            y = y * .1;
            z = z * .1;
            this.addTentacle(i, length, !!((_e = (_d = this.results) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e[ri]), COLORS[i], new Vector3(x, y, z), bottomThickness, centerThickness, reduceThreshold, strainThreshold);
        });
    }
    addTentacle(name, size, radiates, color, pos, bottomThickness, centerThickness, reduceThreshold, strainThreshold) {
        const tentacle = new Tentacle(pos, this, name, color, size, bottomThickness, centerThickness, reduceThreshold, strainThreshold);
        this.tentacles.push(tentacle);
        if (radiates) {
            this.radiate(tentacle, 1, 0.1);
        }
        tentacle.update();
    }
    radiate(tentacle, start, end) {
        if (!this.strainThreshold) {
            this.strainThreshold = tentacle.userData.strainThreshold.value * 2;
            this.bottomThickness = tentacle.userData.bottomThickness.value * 1.4;
            this.centerThickness = tentacle.userData.centerThickness.value * 1.4;
        }
        tentacle.userData.strainThreshold.value = this.strainThreshold;
        tentacle.userData.centerThickness.value = this.centerThickness;
        tentacle.userData.bottomThickness.value = this.bottomThickness;
        new TWEEN.Tween({ opacity: start })
            .to({ opacity: end }, 200)
            .start()
            .onUpdate(({ opacity }) => tentacle.userData.metalness.value = opacity)
            .onComplete(() => this.radiate(tentacle, end, start));
    }
    animate() {
        this.updateTentaclePositions();
    }
    updateTentaclePositions() {
        const radius = this.radius;
        const FRICTION_CONSTANT = 2;
        const TENTACLE_MASS = 0.1;
        const DT = variables.DT.get();
        const MAX_FORCE = 30;
        this.tentacles.forEach(tentacle => {
            let coulombForce = new Vector3();
            let pos = tentacle.point.position;
            this.tentacles.forEach(otherTentacle => {
                if (tentacle !== otherTentacle) {
                    let direction = pos.clone().sub(otherTentacle.point.position);
                    const length = direction.length();
                    const magnitude = Math.min(tentacle.charge * otherTentacle.charge / Math.pow(length, 2), MAX_FORCE);
                    if (length === 0) {
                        direction.set(0, 0, 1);
                    }
                    direction.normalize();
                    const force = direction.multiplyScalar(magnitude);
                    coulombForce.add(force);
                }
            });
            const normal = new Spherical().setFromVector3(pos);
            const { phi, theta } = normal;
            const sph2cart = new Matrix3().set(radius * Math.sin(phi) * Math.sin(theta), radius * Math.cos(phi) * Math.sin(theta), radius * Math.cos(theta), radius * Math.cos(phi), -radius * Math.sin(phi), 0, radius * Math.sin(phi) * Math.cos(theta), radius * Math.cos(phi) * Math.cos(theta), -radius * Math.sin(theta));
            const { radius: r, phi: p, theta: t } = tentacle.angularVel;
            const radialFriction = new Vector3(-r * FRICTION_CONSTANT, -p * FRICTION_CONSTANT, -t * FRICTION_CONSTANT);
            const torque = coulombForce.clone().applyMatrix3(sph2cart.clone().transpose());
            torque.add(radialFriction);
            const angularAccel = new Spherical();
            angularAccel.phi = torque.y / TENTACLE_MASS;
            angularAccel.theta = torque.z / TENTACLE_MASS;
            tentacle.angularVel.phi += angularAccel.phi * DT;
            tentacle.angularVel.theta += angularAccel.theta * DT;
            const sphericalPos = new Spherical().setFromVector3(pos);
            sphericalPos.phi += tentacle.angularVel.phi * DT;
            sphericalPos.theta += tentacle.angularVel.theta * DT;
            if (sphericalPos.phi >= Math.PI || sphericalPos.phi < 0) {
                sphericalPos.theta += Math.PI;
                if (sphericalPos.phi < 0) {
                    sphericalPos.phi = Math.abs(sphericalPos.phi);
                }
                else {
                    sphericalPos.phi = 2 * Math.PI - sphericalPos.phi;
                }
                tentacle.angularVel.phi = -tentacle.angularVel.phi;
            }
            sphericalPos.theta %= (Math.PI * 2);
            if (sphericalPos.theta < 0) {
                sphericalPos.theta += (Math.PI * 2);
            }
            sphericalPos.radius = this.radialPos;
            tentacle.setPosition((new Vector3()).setFromSpherical(sphericalPos));
            tentacle.update();
        });
    }
}
