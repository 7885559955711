export function formSubmit(el, method) {
    const buttonEl = el.querySelector('button[type="submit"]');
    function handleSubmit(event) {
        event.preventDefault();
        buttonEl.classList.add('loading');
        method()
            .finally(() => buttonEl.classList.remove('loading'));
    }
    el.addEventListener('submit', handleSubmit);
    return {
        destroy() {
            el.removeEventListener('submit', handleSubmit);
        }
    };
}
