<script lang="ts">
  import { doc, increment, updateDoc } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Anima from '../../components/anima/Anima.svelte';
  import Button from '../../components/Button.svelte';
  import Checkbox from '../../components/Checkbox.svelte';
  import { db } from '../../config/firebase';
  import { page, user, menuOpen } from '../../config/stores';
  import { CanvasToImage } from '../../utils/canvas-to-image';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: false
  });

  let state: {
    createdOn: number;
    frequency: number;
    amplitude: number;
    maxDistance: number;
    globeInitialColor: string;
    cilinderInitialColor: string;
    startCilinder: boolean;
    lungCapacity: number;
    maxScaledVolume?: number;
    scaledAverageVolume?: number;
    lat?: number;
    lng?: number;
  };

  let showMeasurements = false;
  let showCoordinets = false;
  let showTime = false;
  let labels;

  let coordinatesText;
  let measurementText;
  let dateText;
  let renderer;

  $: if (showCoordinets) {
    const mobile = isMobile();
    const fontSize = mobile ? 14 : 16;

    coordinatesText = [
      createLabel('coordinates-label', state.lat, fontSize, '#ffffffe0'),
      createLabel('coordinates-label', state.lng, fontSize, '#ffffffe0'),
    ];
  } else if (coordinatesText) {
    coordinatesText.forEach((t) => labels.removeChild(t));
  }

  $: if (showMeasurements) {
    const mobile = isMobile();
    const fontSize = mobile ? 14 : 16;

    measurementText = [];

    [
      { label: 'Avg', value: state.scaledAverageVolume },
      { label: 'Max', value: state.maxScaledVolume },
      { label: 'Cap', value: state.lungCapacity },
    ].forEach((prop) => {
      if (prop.value) {
        measurementText.push(
          createLabel(
            'measurements-label',
            prop.label + ': ' + prop.value.toFixed(2),
            fontSize,
            '#ffffffe0'
          )
        );
      }
    });
  } else if (measurementText) {
    measurementText.forEach((t) => labels.removeChild(t));
  }

  $: if (showTime) {
    const mobile = isMobile();
    const fontSize = mobile ? 14 : 16;

    dateText = [
      createLabel(
        'date-label',
        new Date(state.createdOn).toLocaleString(),
        fontSize,
        '#ffffffe0'
      ),
    ];
  } else if (dateText) {
    dateText.forEach((t) => labels.removeChild(t));
  }

  function createLabel(
    className,
    text,
    size,
    color,
    textAlign = 'center',
    textBaseline = 'middle'
  ) {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');

    canvas.classList.add(className);

    context.font = `${size}px monospace`;

    const textMetrics = context.measureText(text);
    const width = textMetrics.width;
    const height = size;

    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    const scale = window.devicePixelRatio;

    canvas.width = Math.floor(width * scale);
    canvas.height = Math.floor(height * scale);

    context.scale(scale, scale);

    context.font = `${size}px monospace`;
    context.textAlign = textAlign;
    context.textBaseline = textBaseline;

    context.fillStyle = 'transparent';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.fillStyle = color;
    context.fillText(text, width / 2, height / 2);

    labels.appendChild(canvas);

    return canvas;
  }

  onMount(() => {
    if (!history.state.anima) {
      navigate('/');
      return;
    }

    state = {
      frequency: history.state.anima.frequency,
      amplitude: history.state.anima.amplitude,
      maxDistance: history.state.anima.maxDistance,
      globeInitialColor: history.state.globeColor,
      cilinderInitialColor: history.state.lungColor,
      startCilinder: true,
      maxScaledVolume: history.state.maxScaledVolume || 0,
      scaledAverageVolume: history.state.scaledAverageVolume || 0,
      lat: history.state.lat,
      lng: history.state.lng,
      lungCapacity: history.state.lungCapacity,
      createdOn: history.state.createdOn,
    };
  });

  async function highRes() {
    const { credits, uid } = $user;

    if (credits) {
      download(4000);
      await updateDoc(doc(db, 'users', uid), {
        credits: increment(-1),
      });
    } else {
      localStorage.setItem(
        'last-state',
        JSON.stringify({ path: '/view', ...history.state })
      );
      navigate('/payment');
    }
  }

  function download(width = 680) {

    const memC = document.createElement('canvas');
    const memCtx = memC.getContext('2d');
    const mobile = isMobile();

    memC.width = renderer.domElement.width;
    memC.height = renderer.domElement.height;

    const canvasEl = renderer.domElement;

    const canvasWidth = renderer.domElement.width;
    const canvasHeight = renderer.domElement.height;
    const ratio = canvasHeight / canvasWidth;
    const height = width * ratio;

    memCtx.drawImage(canvasEl, 0, 0);

    let startHeight = canvasHeight - (mobile ? 300 : 200);
    let startWidth = mobile ? 200 : (canvasWidth - 300);

    [
      ...dateText || [],
      ...measurementText || [],
      ...coordinatesText || []
    ]
      .forEach(canvas => {
        memCtx.drawImage(canvas, startWidth, startHeight);
        startHeight += mobile ? 40 : 20;
      })

    CanvasToImage.saveAsPNG(memC, width, height, 'airsphere');
  }

  function isMobile() {
    return window.innerWidth < 900;
  }
</script>

{#if state}
  <Anima real={true} bind:renderer {...state} />
{/if}

<div class="controls" class:menu-hidden={$menuOpen}>
  <Checkbox bind:checked={showTime}>Show Time</Checkbox>
  <Checkbox bind:checked={showMeasurements}>Show Measurements</Checkbox>
  <Checkbox bind:checked={showCoordinets}>Show Coordinates</Checkbox>
</div>

<div class="canvas-labels" class:menu-hidden={$menuOpen} bind:this={labels} />

<footer class:menu-hidden={$menuOpen}>
  <Button size="small" on:click={() => download()}>Low Res</Button>
  {#if $user}
    &nbsp; &nbsp;
    <Button size="small" on:click={highRes}>High Res</Button>
  {/if}
</footer>

<style>
  .controls {
    position: fixed;
    left: 20px;
    top: 80px;
    z-index: 1;
  }
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .canvas-labels {
    position: fixed;
    bottom: 200px;
    right: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  :global(.measurements-label) {
    margin-bottom: 5px;
    order: 2;
  }

  :global(.coordinates-label) {
    margin-bottom: 5px;
    order: 3;
  }
  :global(.date-label) {
    margin-bottom: 5px;
    order: 1;
  }

  @media (max-width: 900px) {
    .canvas-labels {
      bottom: 100px;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
