<script lang="ts">
  import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter
  } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import Button from '../../components/Button.svelte';
  import HistoryCard from '../../components/HistoryCard.svelte';
  import { db } from '../../config/firebase';
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });

  let items = [];
  let hasMore = true;
  let loading = true;
  let ref;

  async function loadMore() {
    loading = true;

    const operations = [
      orderBy('createdOn', 'desc'),
      ...ref ? [startAfter(ref)] : [],
      limit(11)
    ];

    const { docs } = await getDocs(
      query(
        collection(db, 'history'),
        ...operations
      )
    );

    items = [
      ...items,
      ...docs.slice(0, 10).map(it => it.data())
    ];

    hasMore = docs.length === 11;
    ref = docs[10];
    loading = false;
  }

  onMount(async () => {
    await loadMore();
  })
</script>

<div class="grid">
  {#each items as item}
    <div class="col-12 m-b-l">
      <HistoryCard {item} />
    </div>
  {/each}

  {#if hasMore}
    <div class="col-12">
      <Button {loading} on:click={loadMore}>Load More</Button>
    </div>
  {/if}
</div>