<script lang="ts">
  import { random } from '@jaspero/utils';

  import TWEEN from '@tweenjs/tween.js';
  import { onDestroy, onMount } from 'svelte';
  import * as THREE from 'three';
  import {
    AmbientLight,
    Color,
    Mesh,
    MeshBasicMaterial,
    PerspectiveCamera,
    PointLight,
    Scene,
    SphereGeometry,
    Vector3,
    WebGLRenderer,
    Object3D,
    Quaternion
  } from 'three';
  import { ObjectControls } from 'threejs-object-controls';
  import { convertDEGToDMS } from '../../../components/anima/utils/convert-deg-to-dms';
  import { ShaderLoader } from '../../../components/anima/utils/shader-loader';
  import Capacity from '../../../components/Capacity.svelte';
  import { COLORS } from '../../../config/colors.const';
  import { AQI_TOKEN } from '../../../config/config';
  import { animationStarted } from '../../../config/stores';
  import { RESOURCES } from '../../../state';
  import { distance } from '../../../utils/distance';
  import { Organism } from './Organism';
  import { SHADERS } from './shaders';

  export let results: {
    global: { [key: string]: number };
    user?: { [key: string]: number };
    multiplier?: number
  };
  export let data: {
    lat: number;
    lng: number;
  };
  export let audioGranted;
  export let scene: Scene;

  let canvas: HTMLDivElement;
  let renderer: WebGLRenderer;
  let camera: PerspectiveCamera;
  let organism: Organism;
  let animationFrame: any;
  let control: ObjectControls;
  let lungCapacity = 0;

  let animation = false;
  let locating: any = false;
  let started = false;
  let animationSub;
  let content: any = '';
  let locationData;

  const particles: any[] = [
    { color: new Color('#949c9b'), x: 0.3, y: 0.5, z: 0.7 },
    { color: new Color('#141e2e'), x: 0.7, y: 0.3, z: 0.5 },
    { color: new Color('#16161f'), x: 0.3, y: 0.7, z: 0.5 },
    { color: new Color('#ccb800'), x: 0.4, y: 0.8, z: 0.8 },
    { color: new Color('#ff0019'), x: 0.9, y: 0.4, z: 0.4 },
  ];
  const width = window.innerWidth;
  const height = window.innerHeight - 1;
  const aspectRatio = width / height;
  const computed = {
    initialLookAtPosition: () => new Vector3(0, 0, 0),
    cameraInitialPosition: () => new Vector3(0, 0, 200 * (results?.multiplier || 1)),
  };

  function initialSetup() {
    scene = new Scene();
    renderer = new WebGLRenderer({
      precision: 'highp',
      powerPreference: 'high-performance',
      antialias: true,
      preserveDrawingBuffer: true,
    });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.25;
    renderer.outputEncoding = THREE.sRGBEncoding;
    canvas.appendChild(renderer.domElement);
    /**
     * Camera
     */
    camera = new PerspectiveCamera(60, aspectRatio, 1, 10000);
    camera.updateProjectionMatrix();
    camera.position.copy(computed.cameraInitialPosition());
    camera.lookAt(computed.initialLookAtPosition());
    scene.add(camera);
    /**
     * Organisms
     */
    organism = new Organism(scene, results);
    organism.group.position.x = 0;

    RESOURCES.anima = {
      tentacles: organism.tentacles.map(tentacle => ({
        position: {
          x: tentacle.originalPos.x,
          y: tentacle.originalPos.y,
          z: tentacle.originalPos.z,
        },
        size: tentacle.size,
        bottomThickness: tentacle.defaultRandoms.bottomThickness.value,
        centerThickness: tentacle.defaultRandoms.centerThickness.value,
        reduceThreshold: tentacle.defaultRandoms.reduceThreshold.value,
        strainThreshold: tentacle.defaultRandoms.strainThreshold.value,
      }))
    }

    /**
     * Lights
     */
    const ambientLight = new AmbientLight();
    ambientLight.intensity = 0.2;
    scene.add(ambientLight);

    // Central Light
    const centralLight = new Mesh(
      new SphereGeometry(0, 16, 16),
      new MeshBasicMaterial({ color: new Color('#0e2663') })
    );
    scene.add(centralLight);
    centralLight.add(new PointLight(new Color('#0e2663'), 5));

    // Elements
    particles.forEach((particle) => {
      particle.obj = new Mesh(
        new SphereGeometry(0.2, 16, 16),
        new MeshBasicMaterial({ color: particle.color })
      );
      scene.add(particle.obj);
      particle.obj.add(new PointLight(particle.color, 5));
    });
    /**
     * Control
     */
    control = new ObjectControls(camera, renderer.domElement, organism.group);
    control.enableVerticalRotation();
    control.setDistance(0, 1000);
    control.setZoomSpeed(10);
    control.setRotationSpeed(.05);
    control.setRotationSpeedTouchDevices(.05);

    animate();
  }

  function animate() {
    if (!renderer) {
      return;
    }
    animationFrame = requestAnimationFrame(animate);
    const timer = Date.now() * 0.0005;
    particles.forEach(({ obj, x, y, z }) => {
      obj.position.x = Math.sin(timer * x) * 70;
      obj.position.y = Math.cos(timer * y) * 100;
      obj.position.z = Math.cos(timer * z) * 70;
    });

    if (locating) {
      const { rotation } = organism.group;

      switch (locating.state) {
        case 'animation':
          if (locating.tentacle && locating.ticksElapsed > locating.ticks) {
            locating.state = 'searching';
          } else {
            rotation.x += locating.speedX;
            rotation.y += locating.speedY;
            locating.ticksElapsed += 1;
          }

          break;
        case 'searching':
          if (!locating.tween) {
            const rotation = {
              start: new Quaternion(),
              end: new Quaternion(),
              result: new Quaternion(),
              factor: 0,
            };

            const tempObj = new Object3D().copy(organism.group);

            tempObj.lookAt(locating.tentacle.position);

            rotation.start.copy(organism.group.quaternion);
            rotation.end.copy(tempObj.quaternion);

            locating.tween = new TWEEN.Tween({ factor: 0 })
              .to({ factor: 1 }, 1000)
              .onUpdate(({ factor }) => {
                rotation.result.slerpQuaternions(
                  rotation.start,
                  rotation.end,
                  factor
                );

                organism.group.setRotationFromQuaternion(rotation.result);
              })
              .onComplete(() => {
                locating.state = 'found';
              })
              .start();
          }
          break;
        case 'found':
          content = false;

          if (!locating.tweenColor) {
            locating.tweenColor = true;
            organism.radiate(locating.tentacle, 1, 0);
          }

          if (started) {
            organism.scale(lungCapacity);
          }

          control.enableVerticalRotation();
          control.enableHorizontalRotation();
          break;
      }
    }

    organism.animate();
    TWEEN.update();
    renderer.render(scene, camera);
  }

  function triggered() {
    organism.resetScale();
  }

  function start() {
    started = true;
  }

  onMount(async () => {
    const shaderLoader = new ShaderLoader();

    const [tentacle] = await Promise.all([
      shaderLoader.load('/shaders/archive/tentacle/', ['fragment', 'vertex']),
    ]);

    SHADERS.loaded = { tentacle };

    initialSetup();

    animationSub = animationStarted.subscribe((value) => {
      animation = value;

      if (!value || !data) {
        return;
      }

      locating = {
        ticks: random.aToB(150, 450),
        ticksElapsed: 0,
        direction: random.fromArray([-1, 1]),
        speedY: random.float(0.02, 0.07),
        speedX: random.float(0.001, 0.012),
        state: 'animation',
      };
      control.disableVerticalRotation();
      control.disableHorizontalRotation();

      fetch(
        `https://api.waqi.info/feed/geo:${data.lat};${data.lng}/?lat=${data.lat}&lng=${data.lng}&token=${AQI_TOKEN}`
      )
        .then((res) => res.json())
        .then((d: any) => {
          try {
            const [lat, lng] = d.data.city.geo;

            const dist = distance(lat, lng, data.lat, data.lng);

            if (dist > 200) {
              content += `<p>Approximate results.</p>`;
            }

            locating.lat = data.lat;
            locating.lng = data.lng;
          } catch (e) {}

          locating.index = Math.round((d.data.aqi * COLORS.length) / 500);
          locating.tentacle = organism.getTentacleByIndex(locating.index);

          RESOURCES.airQuality = d.data.aqi;
          locationData = true;
        })
        .catch((e) => {
          content = `There was an error retrieving air quality.`;
          console.error(e);
        });

      content = `
          <p>Determining airquality for</p>
          <p>${convertDEGToDMS(data.lat)}</p>
          <p>${convertDEGToDMS(data.lng, false)}</p>
        `;
    });
  });

  onDestroy(() => {
    cancelAnimationFrame(animationFrame);
    if (animationSub) {
      animationSub();
    }
  });
</script>

<div bind:this={canvas} />

{#if animation}
  <footer>
    {#if content === false}
      <Capacity
        stream={audioGranted}
        showGraph={false}
        col="windrose-history"
        link="actual"
        linkLabel="Go To History"
        bind:lungCapacity={lungCapacity}
        on:triggered={triggered}
        on:started={start}
        on:finished={() => (started = false)}
      />
    {:else}
      {@html content}
    {/if}
  </footer>
{/if}

<style>
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding: 1rem;
    color: white;
    text-align: center;
  }
</style>
