<script type="ts">
  import { collection, doc, getDoc } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import { db } from '../../config/firebase';

  export let id: string;

  let item: any;

  onMount(async () => {
    const ref = await getDoc(doc(collection(db, 'stardust-gallery'), id));
    item = ref.data();
  });
</script>

{#if item}
  <div class="card">
    <div class="flex jc-between">
      <div class="username">{item.username}</div>
      <div class="time">{new Date(item.createdOn).toLocaleString()}</div>
    </div>
    <div class="content">{item.content}</div>
    <img class="image" src={item.url} />
  </div>
{/if}
