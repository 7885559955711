import * as THREE from 'three';
export class FBO {
    constructor(width, height, renderer, simulationMaterial, renderMaterial) {
        this.scene = new THREE.Scene();
        this.orthoCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1);
        const options = {
            minFilter: THREE.NearestFilter,
            magFilter: THREE.NearestFilter,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
        };
        this.rtt = new THREE.WebGLRenderTarget(width, height, options);
        const geom = new THREE.BufferGeometry();
        geom.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
            -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0,
        ]), 3));
        geom.setAttribute('uv', new THREE.BufferAttribute(new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0]), 2));
        this.scene.add(new THREE.Mesh(geom, simulationMaterial));
        const l = width * height;
        const vertices = new Float32Array(l * 3);
        for (let i = 0; i < l; i++) {
            const i3 = i * 3;
            vertices[i3] = (i % width) / width;
            vertices[i3 + 1] = i / width / height;
        }
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        this.particles = new THREE.Points(geometry, renderMaterial);
        this.renderer = renderer;
    }
    update() {
        this.renderer.setRenderTarget(this.rtt);
        this.renderer.clear();
        this.renderer.render(this.scene, this.orthoCamera);
        this.renderer.setRenderTarget(null);
        this.particles.material.uniforms.positions.value = this.rtt.texture;
    }
}
