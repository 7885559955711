export class ShaderLoader {
    async loadFile(url) {
        const res = await fetch(url);
        return res.text();
    }
    async load(url = '/', files) {
        const shaders = await Promise.all(files.map(file => this.loadFile(url + file + '.glsl')));
        return files.reduce((acc, cur, index) => {
            acc[cur] = shaders[index];
            return acc;
        }, {});
    }
}
