<script lang="ts">
  import { page } from '../../config/stores';
  import { Link } from 'svelte-routing';

  import Button from '../../components/Button.svelte';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">M∆NU∆L</h1>

  <p class="col-12 p-y-0 c-white">
    <b>ST∆RT:</b> the app measures air quality of your local site and visualizes
    it with the specific coloured "∆IRSPHERE"
    <br /> <br />
    <b>CHECK YOUR INPUT:</b> the app measures your individual input (blowing
    into your microphone) and visualizes it with the specific coloured ”BRE∆TH
    B∆RREL”
    <br /> <br />
    <b>SAVE RESULTS:</b> the app archives air quality ("AIRSPHERE") and your
    individual input (”BRE∆TH B∆RREL”) at the respective moment - both visible
    in the ARCHIVE (for individual result recognition, registration is required)
    <br /> <br />
    <b>∆RCHIVE:</b> the app displays cumulated measurements of air quality and
    visualizes it with the specific "∆IRSPHERE"/”BRE∆TH BARREL”
    <br /> <br />
    <b>LOW RES / HIGH RES:</b> the app allows you to (1) download and share a
    chosen view of the "∆IRSPHERE" for free, as well as to (2) download a
    printable view (this version is not for free: the payed amount is 100% used
    for supporting artistic projects in public spaces).
    <br /> <br />
    <b>∆CTIONS:</b> the app enables you to participate in public actions - both
    on- and offline, and -combined. If an action is expired, it is archived at
    the bottom.
    <br /> <br />
    <b>LOG IN / REGISTER:</b> the app allows you to return and compare the
    results of your individual inputs. Only as a logged and registered user, you
    can use the basic function of storing the measured results, which is a
    prerequisite for later sharing and further engaging with the actual public
    projects of the artist and her affiliates.
    <br /> <br />
    <b>SH∆RE:</b> Based on actual data and real inputs of diverse research and
    common institutions and supported by the individual usages, the ∆NIM∆ ∆PP is
    an artistic effort to widen the scope of visualizing the conflux of global,
    local and individual air streams and to interpret them in an even more
    profound and pro-active way. If you wish to share your experiences, to
    spread the “good air” around the globe and to collaboratively shape the
    inputs built out of your individual interactions with the world´s air
    quality index – please feel free to engage with further development steps of
    the ∆NIM∆ ∆PP: You can contact us directly and you are very welcome to make
    our joint efforts the discussion topics in the social media.
    <br /> <br />
  </p>

  <div class="left">
    <Button size="small" link="/">Start</Button>
  </div>

  <div class="links col-12">
    <div><Link to="/concept">CONCEPT</Link></div>
    <div><Link to="/video">VIDEO</Link></div>
    <div><Link to="/data-and-algorithms">D∆T∆</Link></div>
  </div>
</div>

<style>
  .left {
    text-align: left;
    width: 100%;
    margin-left: 1rem;
  }
</style>
