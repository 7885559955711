<script>
  import { onDestroy, onMount } from 'svelte';
  import Anima from '../../components/anima/Anima.svelte';
  import { animationStarted, page } from '../../config/stores';
  import { RESOURCES } from '../../state';

  page.set({
    background: 'transparent',
    footer: false,
    header: 'transparent',
    anima: false,
  });

  const data = {
    lat: 52.51876,
    lng: 13.398983,
  };

  let permissionGranted;
  let audioGranted;

  async function permissions() {
    /**
     * Request audio permission
     */
    if (!audioGranted) {
      try {
        audioGranted = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });
        RESOURCES.stream = audioGranted;
      } catch (e) {
        console.error('Audio', e);
      }
    }

    permissionGranted = !!audioGranted;
  }

  onMount(async () => {
    RESOURCES.location.lat = data.lat;
    RESOURCES.location.lng = data.lng;
    RESOURCES.airPlaySession = 'berlin';

    await permissions();

    if (permissionGranted) {
      animationStarted.set(true);
    }
  });

  onDestroy(() => {
    animationStarted.set(false);
  });
</script>

<Anima real={true} laser={true} {data} {audioGranted} />

{#if permissionGranted === false}
  <footer>
    <div class="permission-warning">
      <p class="c-white">
        Please activate location tracking and microphone.
      </p>
    </div>
  </footer>
{/if}

<style>
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
</style>
