<script lang="ts">
  import { Link } from 'svelte-routing';
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">IMPRINT & CREDITS</h1>

  <p class="col-12 p-y-0 c-white">
    The ∆NIM∆ ∆PP is an artistic project by Sabine Kacunko.
    <br /> <br />
    Studio Sabine Kacunko Berlin Phone: +49 (0)176 43529053
    <br /> <br />
    Labelled also as "hybrid breathing" and "the world´s first collaborative lung
    machine", this ongoing project is initially made possible by a support of the
    non-profit organization MICRO HUMAN and requires the support and involvement
    of the broader user community for its long-term viability. The ∆NIM∆ ∆PP relies
    hence on funding from a wide range of sources and your support is vital in enabling
    us to fulfill its mission, aiming at leading to new awareness, knowledge and
    action in supporting global efforts to limit the human-induced consequences of
    the climate change.
    <br /> <br />
    <b>CREDITS:</b>
    <br /> <br />
    International Year of Basic Sciences for Sustainable Development (IYBSSD2022)
    <br />
    <a
            href="https://www.iybssd2022.org"
            target="_blank"
            style="text-decoration: underline;"
    >(https://www.iybssd2022.org)</a
    >
    <br /> <br />
    Micro Human
    <br />
    <a
            href="https://microhuman.org"
            target="_blank"
            style="text-decoration: underline;"
    >(https://microhuman.org)</a
    >
    <br /> <br />
    Air Quality Open Data Platform (https://waqi.info)
    <br /> <br />
    German Environment Agency (https://www.umweltbundesamt.de/en)
    <br /> <br />
    Global Carbon Atlas-platform (http://www.globalcarbonatlas.org)
    <br /> <br />
    <b>SUPPORT:</b>
    <br /> <br />
    To support, go directly to Micro Human NPO (non-profit organization) - support
    page:
    <a
      href="https://microhuman.org/en/#/cat/unterstuetzen/"
      target="_blank"
      style="text-decoration: underline;"
      >https://microhuman.org/en/#/cat/support-us/</a
    >
    <br /> <br />
    <br /> <br />
  </p>
  <div class="links col-12">
    <div><Link to="/terms">∆CCEPTABLE USE POLICY</Link></div>
    <div><Link to="/privacy">PRIVACY POLICY</Link></div>
  </div>
</div>
