<script lang="ts">
  import { random } from '@jaspero/utils';
  import { onDestroy, onMount } from 'svelte';
  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  import { COLORS } from '../../config/colors.const';
  import { AQI_TOKEN } from '../../config/config';
  import { animationStarted } from '../../config/stores';
  import { RESOURCES } from '../../state';
  import { distance } from '../../utils/distance';
  import Capacity from '../Capacity.svelte';
  import { convertDEGToDMS } from './utils/convert-deg-to-dms';
  import { FBO } from './utils/fbo';
  import { ShaderLoader } from './utils/shader-loader';
  import { innerHeight } from '../../utils/inner-height';

  export let data: {
    lat: number;
    lng: number;
  };
  export let frequency: number;
  export let amplitude: number;
  export let maxDistance: number;
  export let audioGranted;
  export let interactive = true;
  export let parent = '';
  export let globeInitialColor = '#fff';
  export let cilinderInitialColor = '#fff';
  export let startCilinder = false;
  export let real = false;
  export let laser = false;

  export let scene;
  export let renderer;

  /**
   * Configuration
   */
  const config: any = {
    globe: {
      initialColor: new THREE.Color(globeInitialColor),
      width: 512,
      height: 512,
      size: 128,
      frequency: {
        min: 0.01,
        max: 0.03,
      },
      amplitude: {
        min: 15,
        max: 50,
      },
      maxDistance: {
        min: 20,
        max: 30,
      },
    },
    cilinder: {
      initialColor: new THREE.Color(cilinderInitialColor),
      start: false,
      initialWidth: 128,
      initialHeight: 128,
      initialSize: 128 / 4,
      finalWidth: 100,
      finalHeight: 160,
      finalSize: 128 / 4,
    },
  };

  let shaders: {
    globeSimulationFs: string;
    globeSimulationVs: string;
    globeRenderFs: string;
    globeRenderVs: string;
    cRenderFs: string;
    cRenderVs: string;
    cSimulationFs: string;
    cSimulationVs: string;
  };
  let camera;
  let controls;

  /**
   * Globe
   */
  let gSimShader;
  let gRenderShader;
  let gFb;

  /**
   * Sphere to Cilindar
   */
  let cSimShader;
  let cRenderShader;
  let cFb;

  /**
   * Laser
   */
  let laserCylinder;

  let animation = false;
  let animationSub;
  let content: any = '';
  let cColor = cilinderInitialColor;
  let locationData;
  let animationFrame: any;

  function getPoint(v, size) {
    v.x = Math.random() * 2 - 1;
    v.y = Math.random() * 2 - 1;
    v.z = Math.random() * 2 - 1;

    if (v.length() > 1) {
      return getPoint(v, size);
    }

    return v.normalize().multiplyScalar(size);
  }

  function getRandomData(width, height, size) {
    const len = width * height * 4;
    const data = new Float32Array(len);
    const p = new THREE.Vector3();

    for (let i = 0; i < len; i += 4) {
      getPoint(p, size);
      data[i] = p.x;
      data[i + 1] = random.float(-height, height);
      data[i + 2] = p.y;
      data[i + 3] = 1;
    }

    return data;
  }

  function getSphere(count, size) {
    const len = count * 4;
    const data = new Float32Array(len);
    const p = new THREE.Vector3();

    for (var i = 0; i < len; i += 4) {
      getPoint(p, size);
      data[i] = p.x;
      data[i + 1] = p.y;
      data[i + 2] = p.z;
      data[i + 3] = 1;
    }

    return data;
  }

  function onResize() {
    const w = window.innerWidth;
    const h = innerHeight();
    renderer.setSize(w, h);
    camera.aspect = w / h;
    camera.updateProjectionMatrix();
  }

  function init() {
    const w = window.innerWidth;
    const h = innerHeight();
    
    renderer = new THREE.WebGLRenderer({
      precision: 'highp',
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
      powerPreference: 'high-performance',
    });
    
    renderer.setSize(w, h);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x00);

    if (parent) {
      document.querySelector(parent).appendChild(renderer.domElement);
    } else {
      document.body.appendChild(renderer.domElement);
    }

    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(60, w / h, 1, 1000);

    camera.position.z = window.innerWidth < 768 ? 600 : 400;

    if (interactive) {
      controls = new OrbitControls(camera, renderer.domElement);
      controls.minDistance = 100;
      controls.maxDistance = 800;
      controls.enablePan = false;
    }

    // @ts-ignore
    const textureA = new THREE.DataTexture(
      getSphere(
        config.cilinder.initialWidth * config.cilinder.initialHeight,
        config.cilinder.initialSize
      ),
      config.cilinder.initialWidth,
      config.cilinder.initialHeight,
      THREE.RGBAFormat,
      THREE.FloatType,
      // @ts-ignore
      THREE.DEFAULT_MAPPING,
      THREE.RepeatWrapping,
      THREE.RepeatWrapping
    );
    textureA.needsUpdate = true;

    // @ts-ignore
    const textureB = new THREE.DataTexture(
      getRandomData(
        config.cilinder.finalWidth,
        config.cilinder.finalHeight,
        config.cilinder.finalSize
      ),
      config.cilinder.finalWidth,
      config.cilinder.finalHeight,
      THREE.RGBAFormat,
      THREE.FloatType,
      // @ts-ignore
      THREE.DEFAULT_MAPPING,
      THREE.RepeatWrapping,
      THREE.RepeatWrapping
    );
    textureB.needsUpdate = true;

    // @ts-ignore
    const texture = new THREE.DataTexture(
      getSphere(config.globe.width * config.globe.height, config.globe.size),
      config.globe.width,
      config.globe.height,
      THREE.RGBAFormat,
      THREE.FloatType
    );
    texture.needsUpdate = true;

    gSimShader = new THREE.ShaderMaterial({
      uniforms: {
        tx: { type: 't', value: texture },
        timer: { type: 'f', value: 0 },
        frequency: { type: 'f', value: config.globe.frequency.value },
        amplitude: { type: 'f', value: config.globe.amplitude.value },
        maxDistance: { type: 'f', value: config.globe.maxDistance.value },
      },
      vertexShader: shaders['globeSimulationVs'],
      fragmentShader: shaders['globeSimulationFs'],
    });

    gRenderShader = new THREE.ShaderMaterial({
      uniforms: {
        transition: { type: 'f', value: 0 },
        positions: { type: 't', value: null },
        pointSize: { type: 'f', value: 3 },
        color: { type: 'v3', value: config.globe.initialColor },
        arColor: { type: 'v3', value: config.globe.initialColor },
      },
      vertexShader: shaders['globeRenderVs'],
      fragmentShader: shaders['globeRenderFs'],
      transparent: true,
      side: THREE.DoubleSide,
      blending: THREE.AdditiveBlending,
    });

    cSimShader = new THREE.ShaderMaterial({
      uniforms: {
        textureA: { type: 't', value: textureA },
        textureB: { type: 't', value: textureB },
        timer: { type: 'f', value: 0 },
        frequency: { type: 'f', value: 0.01 },
        amplitude: { type: 'f', value: 5 },
        maxDistance: { type: 'f', value: 5 },
        destinationFrequency: { type: 'f', value: 0.01 },
        destinationAmplitude: { type: 'f', value: 5 },
        destinationMaxDistance: { type: 'f', value: 5 },
      },
      vertexShader: shaders['cSimulationVs'],
      fragmentShader: shaders['cSimulationFs'],
    });

    cRenderShader = new THREE.ShaderMaterial({
      uniforms: {
        positions: { type: 't', value: null },
        pointSize: { type: 'f', value: 3 },
        color: { type: 'v3', value: config.cilinder.initialColor },
      },
      vertexShader: shaders['cRenderVs'],
      fragmentShader: shaders['cRenderFs'],
      transparent: true,
      side: THREE.DoubleSide,
      blending: THREE.AdditiveBlending,
    });

    gFb = new FBO(
      config.globe.width,
      config.globe.height,
      renderer,
      gSimShader,
      gRenderShader
    );
    scene.add(gFb.particles);

    if (startCilinder) {
      drawCFB();
      cSimShader.uniforms.timer.value = 1;
    }

    if (laser) {
      const laserGeometry = new THREE.CylinderGeometry(1.5, 1.5, 500, 32);
      const laserMaterial = new THREE.MeshBasicMaterial({
        color: config.cilinder.initialColor,
        opacity: .75,
        transparent: true
      });
      laserCylinder = new THREE.Mesh( laserGeometry, laserMaterial );
      scene.add( laserCylinder );

      /**
       * Display the big cylinder right away
       */
      // drawCFB();
      // cSimShader.uniforms.timer.value = 1;
    }

    window.addEventListener('resize', onResize);
    onResize();
    update();
  }

  function update() {
    animationFrame = requestAnimationFrame(update);

    if (controls) {
      controls.update();
    }

    gFb.update();

    gSimShader.uniforms.timer.value += 0.05;

    gFb.particles.rotation.x =
      ((Math.cos(Date.now() * 0.001) * Math.PI) / 180) * 2;
    gFb.particles.rotation.y -= (Math.PI / 180) * 0.05;

    if (animation && locationData) {
      if (gRenderShader.uniforms.transition.value < 1) {
        gRenderShader.uniforms.transition.value += 0.005;
      } else if (!cFb) {
        drawCFB();
        content = ' ';
      }
    }

    if (real && config.cilinder.start && cFb) {
      cFb.update();

      cSimShader.uniforms.timer.value +=
        cSimShader.uniforms.timer.value < 1 ? 0.005 : 0.1;

      cFb.particles.rotation.x =
        ((Math.cos(Date.now() * 0.001) * Math.PI) / 180) * 2;
      cFb.particles.rotation.y -= (Math.PI / 180) * 0.05;

      const color = new THREE.Color(cColor);

      cRenderShader.uniforms.color.value = color;

      if (laser) {
        laserCylinder.material.color = color;
      }

      if (cSimShader.uniforms.timer.value > 1 && content) {
        content = false;
      }
    }

    renderer.render(scene, camera);
  }

  function drawCFB() {
    config.cilinder.start = true;
    cFb = new FBO(
      config.cilinder.finalWidth,
      config.cilinder.finalHeight,
      renderer,
      cSimShader,
      cRenderShader
    );
    scene.add(cFb.particles);
  }

  onMount(async () => {
    const shaderLoader = new ShaderLoader();

    config.globe.frequency.value =
      frequency ||
      random.float(config.globe.frequency.min, config.globe.frequency.max);
    config.globe.amplitude.value =
      amplitude ||
      random.float(config.globe.amplitude.min, config.globe.amplitude.max);
    config.globe.maxDistance.value =
      maxDistance ||
      random.float(config.globe.maxDistance.min, config.globe.maxDistance.max);

    if (!real) {
      RESOURCES.anima = {
        frequency: config.globe.frequency.value,
        maxDistance: config.globe.maxDistance.value,
        amplitude: config.globe.amplitude.value,
      };
    }

    shaders = await shaderLoader.load('/shaders/anima/', [
      'globeSimulationFs',
      'globeSimulationVs',
      'globeRenderFs',
      'globeRenderVs',
      'cSimulationVs',
      'cSimulationFs',
      'cRenderVs',
      'cRenderFs',
    ]);

    init();

    if (!real) {
      return;
    }

    animationSub = animationStarted.subscribe((value) => {
      animation = value;
      if (value) {
        fetch(
          `https://api.waqi.info/feed/geo:${data.lat};${data.lng}/?lat=${data.lat}&lng=${data.lng}&token=${AQI_TOKEN}`
        )
          .then((res) => res.json())
          .then((d: any) => {
            try {
              const [lat, lng] = d.data.city.geo;

              const dist = distance(lat, lng, data.lat, data.lng);

              if (dist > 200) {
                content += `<p>Approximate results.</p>`;
              }
            } catch (e) {}

            const index = Math.round((d.data.aqi * COLORS.length) / 500);
            gRenderShader.uniforms.arColor.value = new THREE.Color(
              COLORS[index]
            );

            RESOURCES.airQuality = d.data.aqi;
            locationData = true;
          })
          .catch((e) => {
            content = `There was an error retrieving air quality.`;
            console.error(e);
          });

        content = `
          <p>Determining airquality for</p>
          <p>${convertDEGToDMS(data.lat)}</p>
          <p>${convertDEGToDMS(data.lng, false)}</p>
        `;
      }
    });
  });

  onDestroy(() => {
    try {
      renderer.domElement.parentElement.removeChild(renderer.domElement);
    } catch (e) {}

    if (animationSub) {
      animationSub();
    }
    cancelAnimationFrame(animationFrame);
  });
</script>

{#if animation}
  <footer>
    {#if content === false}
      <Capacity {laser} bind:color={cColor} stream={audioGranted} />
    {:else}
      {@html content}
    {/if}
  </footer>
{/if}

<style>
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding: 1rem;
    color: #ffffffe0;
    text-align: center;
  }
</style>
