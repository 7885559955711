<script lang="ts">
  import { signOut } from 'firebase/auth';
  import { Link, navigate } from 'svelte-routing';
  import { auth } from '../config/firebase';
  import { menuOpen, notification, page, user } from '../config/stores';

  import { onMount } from 'svelte';
  import Close from 'svelte-material-icons/Close.svelte';
  import Menu from 'svelte-material-icons/Menu.svelte';
  import Anima from './anima/Anima.svelte';

  let mode = null;

  const menues = {
    intro: false,
    archive: false,
    actual: false,
  };

  async function logOut() {
    await signOut(auth);
    notification.set('Logged out successfully.');
    navigate('/login');
  }

  async function logIn() {
    navigate('/login', { state: { redirect: location.pathname } });
    closeMenu(false);
  }

  function closeMenu(prop?: boolean) {
    menuOpen.set(prop !== undefined ? prop : !$menuOpen);
  }

  function expand(key: string) {
    for (const k in menues) {
      menues[k] = false;
    }
    menues[key] = true;
  }

  onMount(() => {
    const params = new URLSearchParams(location.search);
    mode = params.get('mode');
  });
</script>

<nav
  class="flex ai-center"
  class:ios={mode === 'ios'}
  class:open={$menuOpen}
  style="background:{$menuOpen
    ? 'transparent'
    : window.innerWidth < 900
    ? $page.background
    : $page.header}"
>
  <Link to="/" on:click={() => closeMenu(false)}>
    <div class="logo">∆NIM∆</div>
  </Link>

  <div class="flex-1" />

  {#if $page.video}
    <button class="skip" on:click={() => navigate('/globe')}>
      SKIP VIDEO
    </button>
  {:else}
    <button class="toggle-button" on:click={() => closeMenu()}>
      {#if $menuOpen}
        <Close size="24px" color="#fff" />
      {:else}
        <Menu size="24px" color="#fff" />
      {/if}
    </button>
  {/if}
</nav>

{#if $menuOpen}
  <div class="menu flex fd-col">
    <button
      class="toggle-about"
      class:active={menues.intro}
      on:click={() => expand('intro')}
    >
      INTRO
    </button>
    {#if menues.intro}
      <Link class="sub-menu" to="/manual" on:click={() => closeMenu(false)}
        >M∆NU∆L</Link
      >
      <Link class="sub-menu" to="/concept" on:click={() => closeMenu(false)}
        >CONCEPT</Link
      >
      <Link class="sub-menu" to="/video" on:click={() => closeMenu(false)}
        >VIDEO</Link
      >
      <Link
        class="sub-menu"
        to="/data-and-algorithms"
        on:click={() => closeMenu(false)}
      >
        D∆T∆
      </Link>
      <Link class="sub-menu" to="/artist" on:click={() => closeMenu(false)}
        >∆RTIST</Link
      >
      <Link class="sub-menu" to="/legal" on:click={() => closeMenu(false)}
        >IMPRINT</Link
      >
    {/if}
    <button
      class="toggle-about"
      class:active={menues.actual}
      on:click={() => expand('actual')}
    >
      ∆CTIONS
    </button>
    {#if menues.actual}
      <Link
        class="sub-menu"
        to="/announcing-anima"
        on:click={() => closeMenu(false)}>∆NNOUNCING ∆NIMA</Link
      >
      <Link
        class="sub-menu"
        to="/animated-sculpture"
        on:click={() => closeMenu(false)}>∆NIM∆TED SCULPTURE</Link
      >
      <Link
              class="sub-menu"
              to="/airplay"
              on:click={() => closeMenu(false)}>∆IRPL∆Y</Link
      >
      <!--   <Link class="sub-menu" to="/windrose" on:click={() => closeMenu(false)}>WINDROSE</Link> -->
    {/if}
    <Link to="/place" on:click={() => closeMenu(false)}>∆RCHIVE</Link>
    {#if $user}
      <button class="toggle-about" on:click={logOut}>LOG OUT</button>
    {:else}
      <button class="toggle-about" on:click={logIn}>LOG IN</button>
    {/if}
  </div>
{/if}

{#if $page.anima}
  <div id="header-anima-wrapper" class:hopen={$menuOpen} class:lifted={$page.backgroundBeforeAnima}>
    <Anima interactive={false} parent="#header-anima-wrapper" />
  </div>
{/if}

<style>
  .logo {
    font-family: 'Yellix SemiBold', sans-serif;
    font-size: 20px;
  }

  nav {
    z-index: 3;
    position: fixed;
    top: 0;
    color: #ffffffe0;
    font-size: 20pt;
    width: 100%;
    height: 84px;
    padding: 0 20px;
    background: black;
  }

  nav.ios {
    padding-top: 30px;
  }

  .open {
    z-index: 5;
  }

  .toggle-button {
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 2px;
    border: none;
    background: none;
    color: #ffffffe0;
    cursor: pointer;
  }

  .toggle-about {
    font-size: 40pt;
    font-family: 'Yellix SemiBold', sans-serif;
    color: #ffffffe0;
    padding: 0;
    margin: 0;
    text-align: left;
    background: none;
    border: none;
    border-radius: 0;
  }

  .toggle-about.active {
    opacity: 0.3;
  }

  .menu {
    z-index: 4;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    color: #ffffffe0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 114px;
    padding-left: 30px;
    font-size: 40pt;
    line-height: 1.1;
    font-family: 'Yellix SemiBold', sans-serif;
  }

  .skip {
    border: none;
    background: none;
    color: #ffffffe0;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
    font-family: 'Yellix Regular', sans-serif;
  }
  button {
    cursor: pointer;
  }

  #header-anima-wrapper {
    position: fixed;
    top: 0;
    z-index: -1;
  }

  #header-anima-wrapper.lifted {
    visibility: hidden;
  }

  #header-anima-wrapper.hopen {
    visibility: visible;
    z-index: 3;
  }
</style>
