import { BufferGeometry, Float32BufferAttribute } from 'three';
export class EmptyTentacleGeometry extends BufferGeometry {
    constructor(tubularSegments, radialSegments) {
        super();
        this.type = 'TentacleGeometry';
        let vertices = [];
        let normals = [];
        let uvs = [];
        let indices = [];
        for (let i = 0; i < tubularSegments; i++) {
            for (let j = 0; j <= radialSegments; j++) {
                normals.push(0, 1, 0);
                vertices.push(i, 1, Math.sin(j));
            }
        }
        for (let j = 0; j <= radialSegments; j++) {
            normals.push(0, 1, 0);
            vertices.push(0, 1, Math.sin(j));
        }
        for (let i = 0; i <= tubularSegments; i++) {
            for (let j = 0; j <= radialSegments; j++) {
                uvs.push(i / tubularSegments, j / radialSegments);
            }
        }
        // generateIndices
        for (let j = 1; j <= tubularSegments; j++) {
            for (let i = 1; i <= radialSegments; i++) {
                const a = (radialSegments + 1) * (j - 1) + (i - 1);
                const b = (radialSegments + 1) * j + (i - 1);
                const c = (radialSegments + 1) * j + i;
                const d = (radialSegments + 1) * (j - 1) + i;
                // faces
                indices.push(a, b, d);
                indices.push(b, c, d);
            }
        }
        // build geometry
        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
    }
}
