<script lang="ts">
  import { page } from '../../config/stores';
  import Scene from '../actual/components/Scene.svelte';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<Scene />
