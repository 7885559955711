export function innerHeight() {
    if (!navigator.userAgent.match(/iphone|ipod|ipad/i)) {
        /**
         * Avoids conditional logic in the implementation
         * @return {number} - window's innerHeight measurement in pixels
         */
        return window.innerHeight - 1;
    }
    // Store initial orientation
    const axis = Math.abs(window.orientation);
    // And hoist cached dimensions
    const dims = { w: 0, h: 0 };
    /**
     * Creates an element with a height of 100vh since iOS accurately
     * reports vp height (but not window.innerHeight). Then destroy it.
     */
    const createRuler = function () {
        let ruler = document.createElement('div');
        ruler.style.position = 'fixed';
        ruler.style.height = '100vh';
        ruler.style.width = 0;
        ruler.style.top = 0;
        document.documentElement.appendChild(ruler);
        // Set cache conscientious of device orientation
        dims.w = axis === 90 ? ruler.offsetHeight : window.innerWidth;
        dims.h = axis === 90 ? window.innerWidth : ruler.offsetHeight;
        // Clean up after ourselves
        document.documentElement.removeChild(ruler);
        ruler = null;
    };
    // Measure once
    createRuler();
    /**
     * Returns window's cached innerHeight measurement
     * based on viewport height and device orientation
     * @return {number} - window's innerHeight measurement in pixels
     */
    if (Math.abs(window.orientation) !== 90) {
        return dims.h;
    }
    return dims.w;
}
