<script lang="ts">
  import TWEEN from '@tweenjs/tween.js';
  import { onDestroy,onMount } from 'svelte';
  import * as THREE from 'three';
  import {
    AmbientLight,
    Color,
    Mesh,
    MeshBasicMaterial,
    PerspectiveCamera,
    PointLight,
    Scene,
    SphereGeometry,
    Vector3,
    WebGLRenderer
  } from 'three';
  import { ObjectControls } from 'threejs-object-controls';
  import { ShaderLoader } from '../../../components/anima/utils/shader-loader';
  import { Organism } from './Organism';
  import { SHADERS } from './shaders';

  export let results: {
    global: {[key: string]: number};
    user?: {[key: string]: number};
  };

  let canvas: HTMLDivElement;
  let scene: Scene;
  let renderer: WebGLRenderer;
  let camera: PerspectiveCamera;
  let organism: Organism;
  let animationFrame: any;

  const particles: any[] = [
    { color: new Color('#949c9b'), x: 0.3, y: 0.5, z: 0.7 },
    { color: new Color('#141e2e'), x: 0.7, y: 0.3, z: 0.5 },
    { color: new Color('#16161f'), x: 0.3, y: 0.7, z: 0.5 },
    { color: new Color('#ccb800'), x: 0.4, y: 0.8, z: 0.8 },
    { color: new Color('#ff0019'), x: 0.9, y: 0.4, z: 0.4 },
  ];
  const width = window.innerWidth;
  const height = window.innerHeight - 90;
  const aspectRatio = width / height;
  const computed = {
    initialLookAtPosition: () => new Vector3(0, 0, 2),
    cameraInitialPosition: () => new Vector3(0, 0, isMobile() ? 300 : 150),
  };

  function initialSetup() {
    scene = new Scene();
    renderer = new WebGLRenderer({
      precision: 'highp',
      powerPreference: 'high-performance',
      antialias: true,
      preserveDrawingBuffer: true
    });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.25;
    renderer.outputEncoding = THREE.sRGBEncoding;
    canvas.appendChild(renderer.domElement);
    /**
     * Camera
     */
    camera = new PerspectiveCamera(60, aspectRatio, 1, 10000);
    camera.updateProjectionMatrix();
    camera.position.copy(computed.cameraInitialPosition());
    camera.lookAt(computed.initialLookAtPosition());
    scene.add(camera);
    /**
     * Organisms
     */
    organism = new Organism(scene, results);
    organism.group.position.x = 0;
    /**
     * Lights
     */
    const ambientLight = new AmbientLight();
    ambientLight.intensity = 0.2;
    scene.add(ambientLight);

    // Central Light
    const centralLight = new Mesh(
      new SphereGeometry(0, 16, 16),
      new MeshBasicMaterial({ color: new Color('#0e2663') })
    );
    scene.add(centralLight);
    centralLight.add(new PointLight(new Color('#0e2663'), 5));

    // Elements
    particles.forEach((particle) => {
      particle.obj = new Mesh(
        new SphereGeometry(0.2, 16, 16),
        new MeshBasicMaterial({ color: particle.color })
      );
      scene.add(particle.obj);
      particle.obj.add(new PointLight(particle.color, 5));
    });
    /**
     * Control
     */

    const control = new ObjectControls(
      camera,
      renderer.domElement,
      organism.group
    );
    control.enableVerticalRotation();
    control.setDistance(0, 1000);
    control.setZoomSpeed(10);
    control.setRotationSpeed(.05);
    control.setRotationSpeedTouchDevices(.05);

    animate();
  }

  function animate() {
    if (!renderer) {
      return;
    }
    animationFrame = requestAnimationFrame(animate);
    const timer = Date.now() * 0.0005;
    particles.forEach(({ obj, x, y, z }) => {
      obj.position.x = Math.sin(timer * x) * 56;
      obj.position.y = Math.cos(timer * y) * 80;
      obj.position.z = Math.cos(timer * z) * 56;
    });
    organism.animate();
    TWEEN.update();
    renderer.render(scene, camera);
  }

  function isMobile() {
    return window.innerWidth < 900;
  }

  onMount(async () => {
    const shaderLoader = new ShaderLoader();

    const [tentacle] = await Promise.all([
      shaderLoader.load('/shaders/archive/tentacle/', ['fragment', 'vertex'])
    ]);

    SHADERS.loaded = { tentacle };

    initialSetup();
  });

  onDestroy(() => {
    cancelAnimationFrame(animationFrame);
  });
</script>

<div bind:this={canvas} />
