export const RESOURCES = {
    assets: {},
    video: 'assets/macro.mp4',
    location: {},
    airQuality: 0,
    stream: null,
    saveResults: false,
    anima: {},
    airPlaySession: false
};
