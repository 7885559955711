<script lang="ts">
  import { createUserWithEmailAndPassword } from 'firebase/auth';
  import { updateDoc } from 'firebase/firestore';
  import { navigate } from 'svelte-routing';
  import { formSubmit } from '../../actions/form-submit';
  import Button from '../../components/Button.svelte';
  import Field from '../../components/Field.svelte';
  import Password from '../../components/Password.svelte';
  import { auth } from '../../config/firebase';
  import { notification,page } from '../../config/stores';
  import { RESOURCES } from '../../state';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });

  let email = '';
  let password = '';
  let repeatPassword = '';
  let type = 'password';
  let loading = false;

  let passEl;
  let repeatPassEl;

  function toggle() {
    type = type === 'password' ? 'text' : 'password';
    passEl.type = type;
    repeatPassEl.type = type;
  }

  async function submit() {
    loading = true;
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (RESOURCES.saveResults) {
        await updateDoc(RESOURCES.saveResults as any, { user: user.uid });
        RESOURCES.saveResults = false;
        navigate('/place');
      } else {
        navigate('/');
      }

      notification.set('Registration successful.');
    } catch (e) {
      notification.set(e.message);
    }
    loading = false;
  }
</script>

<div class="grid">
  <h1 class="col-12 m-b-l c-white">REGISTER</h1>

  <form class="w-full" use:formSubmit={submit}>
    <div class="col-12">
      <Field
        type="email"
        id="E-mail"
        label="E-mail"
        required={true}
        bind:value={email}
      />
    </div>

    <div class="col-12">
      <Password
        label="Password"
        id="Password"
        required={true}
        bind:value={password}
      />
    </div>

    <div class="col-12">
      <Password
        label="Repeat Password"
        id="Repeat Password"
        required={true}
        bind:value={repeatPassword}
        pattern={password}
        title={`Passwords don't match.`}
      />
    </div>

    <div class="col-12">
      <label class="container" for="terms">
        <input type="checkbox" id="terms" required />
        <span class="checkmark" />
        <span class="c-white">
          I understand, accept, and agree to the following <a href="/terms"
            >Terms and conditions</a
          >.
        </span>
      </label>
    </div>

    <div class="col-12">
      <label class="container" for="privacy">
        <input type="checkbox" id="privacy" required />
        <span class="checkmark" />
        <span class="c-white">
          I have read, understand and accepted our <a href="/privacy"
            >Privacy Policy</a
          >.
        </span>
      </label>
    </div>

    <div class="col-12">
      <Button type="submit" backgroundColor="#272727cc" {loading}
        >Register</Button
      >
    </div>
  </form>
</div>

<style>
  a {
    text-decoration: underline;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: #fff;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #0e7bd2;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
