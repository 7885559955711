<script>
  import EyeOffOutline from 'svelte-material-icons/EyeOffOutline.svelte';
  import EyeOutline from 'svelte-material-icons/EyeOutline.svelte';

  /**
   * @type {string}
   */
  export let id = undefined;

  /**
   * @type {string}
   */
  export let type = 'password';

  /**
   * @type {boolean}
   */
  export let required = false;

  /**
   * @type {string}
   */
  export let pattern = undefined;

  /**
   * @type {string}
   */
  export let title = '';

  /**
   *  Specify input label
   *  @type {string}
   */
  export let label = 'Label';

  /**
   *  Specify input value
   *  @type {string}
   */
  export let value = '';

  /**
   * Specify autocomplete
   * @type {"new-password" | "current-password"}
   */
  export let autocomplete = 'off';

  function toggleVisibility() {
    type = type === 'password' ? 'text' : 'password';
  }
</script>

<label for="{id}">
  {#if type === 'text'}
    <input placeholder="{label}" {id} {pattern} {title} {autocomplete} {required} bind:value={value}>
  {:else}
    <input type="password" placeholder="{label}" {pattern} {title} {autocomplete} {required} bind:value={value}>
  {/if}
  <span class="label">{label}</span>
  <span class="suffix pointer" on:click={toggleVisibility}>
    {#if type === 'password'}
      <EyeOffOutline size="24px"/>
    {:else}
      <EyeOutline size="24px"/>
    {/if}
  </span>
</label>

<style>
  label {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px transparent;
    background-color: white;
    height: 50px;
    border-radius: 16px;
    transition: border .3s, box-shadow .3s;
  }

  label:hover {
    border: 1px solid rgba(0, 0, 0, .3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .3);
  }

  label:focus-within {
    border: 1px solid rgba(0, 0, 0, .6);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .6);
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: rgba(0, 0, 0, .9);
    -webkit-background-clip: text;
  }

  input {
    display: flex;
    position: relative;
    line-height: 16px;
    font-size: 16px;
    padding: 12px 16px;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    color: rgba(0, 0, 0, .9);
    caret-color: rgba(0, 0, 0, .9);
  }

  input:not(:placeholder-shown), input:focus:not(:placeholder-shown) {
    padding: 24px 16px 4px;
  }

  input:not(:placeholder-shown) + .label, input:focus:not(:placeholder-shown) + .label {
    transform: translateY(-10px);
    font-size: 10px;
  }

  .label {
    display: block;
    position: absolute;
    left: 16px;
    color: rgba(0, 0, 0, .6);
    transition: transform .3s, font-size .3s;
    pointer-events: none;
    user-select: none;
    font-size: 16px;
  }

  ::placeholder {
    visibility: hidden;
  }

  .suffix {
    min-width: 36px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
</style>
