<script lang="ts">
  import { doc, increment, updateDoc } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import { db } from '../../config/firebase';
  import { page, user } from '../../config/stores';
  import { CanvasToImage } from '../../utils/canvas-to-image';
  import Scene from '../windrose/components/Scene.svelte';
  import {
    Texture,
    MeshBasicMaterial,
    Mesh,
    PlaneGeometry,
    DoubleSide,
  } from 'three';
  import Checkbox from '../../components/Checkbox.svelte';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: false
  });

  let state: {
    globeInitialColor: string;
    cilinderInitialColor: string;
    startCilinder: boolean;
    lungCapacity: number;
    maxScaledVolume?: number;
    scaledAverageVolume?: number;
    lat?: number;
    lng?: number;
  };
  let results = {};
  let multiplier = 1;

  let showMeasurements = false;
  let showCoordinets = false;
  let scene;

  let coordinatesText;
  let measurementText;

  $: if (showCoordinets) {
    const mobile = isMobile();
    const fontSize = mobile ? 5 : 12;
    const fontWidth = -80 * multiplier;

    if (!coordinatesText) {
      coordinatesText = [
        createLabel(state.lat, fontWidth, 0, fontSize, '#ffffffe0'),
        createLabel(state.lng, fontWidth, 10, fontSize, '#ffffffe0'),
      ];
    }

    coordinatesText.forEach((t) => scene.add(t));
  } else if (coordinatesText) {
    coordinatesText.forEach((t) => scene.remove(t));
  }

  $: if (showMeasurements) {
    if (!measurementText) {
      const mobile = isMobile();
      const fontSize = mobile ? 5 : 12;
      const fontWidth = (mobile ? 115 : 135) * multiplier;

      measurementText = [];

      let offset = 0;

      [
        { label: 'Avg', value: state.scaledAverageVolume },
        { label: 'Max', value: state.maxScaledVolume },
        { label: 'Cap', value: state.lungCapacity },
      ].forEach((prop) => {
        if (prop.value) {
          measurementText.push(
            createLabel(
              prop.label + ': ' + prop.value.toFixed(2),
              fontWidth,
              offset,
              fontSize,
              '#ffffffe0'
            )
          );
          offset += 10;
        }
      });
    }

    measurementText.forEach((t) => scene.add(t));
  } else if (measurementText) {
    measurementText.forEach((t) => scene.remove(t));
  }

  function createLabel(text, x, y, size, color, textAlign = 'center', textBaseline = 'middle') {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');

    context.font = `${size}px monospace`;

    const textMetrics = context.measureText(text);
    const width = textMetrics.width + 2;
    const height = size;

    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    const bsr = context.webkitBackingStorePixelRatio ||
      context.mozBackingStorePixelRatio ||
      context.msBackingStorePixelRatio ||
      context.oBackingStorePixelRatio ||
      context.backingStorePixelRatio || 1;
    const scale = window.devicePixelRatio / bsr;

    canvas.width = width * scale;
    canvas.height = height * scale;

    context.scale(scale, scale);

    context.font = `${size}px monospace`;
    context.textAlign = textAlign;
    context.textBaseline = textBaseline;

    context.fillStyle = 'transparent';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.fillStyle = color;
    context.fillText(text, width / 2, height / 2);

    const texture = new Texture(canvas);

    texture.needsUpdate = true;

    const material = new MeshBasicMaterial({
      map: texture,
      transparent: true,
      side: DoubleSide,
    });

    const mesh = new Mesh(new PlaneGeometry(width, height, 50, 50), material);

    mesh.overdraw = true;
    mesh.position.x = x - width / 2;
    mesh.position.y = y - height / 2;

    return mesh;
  }

  onMount(async () => {
    if (!history.state.anima) {
      navigate('/');
      return;
    }

    state = {
      globeInitialColor: history.state.globeColor,
      cilinderInitialColor: history.state.lungColor,
      startCilinder: true,
      maxScaledVolume: history.state.maxScaledVolume || 0,
      scaledAverageVolume: history.state.scaledAverageVolume || 0,
      lat: history.state.lat,
      lng: history.state.lng,
      lungCapacity: history.state.lungCapacity,
    };

    if (state.lungCapacity && state.lungCapacity > 100) {
      multiplier = state.lungCapacity / 100;
    }

    results = {
      tentacles: history.state.anima.tentacles,
      lungCapacity: history.state.lungCapacity,
      airQuality: history.state.airQuality,
      multiplier
    };
  });

  function download(width = 680) {
    const canvasEl = document.querySelector('canvas');

    const canvasWidth = canvasEl.width;
    const canvasHeight = canvasEl.height;
    const ratio = canvasHeight / canvasWidth;
    const height = width * ratio;

    CanvasToImage.saveAsPNG(
      document.querySelector('canvas'),
      width,
      height,
      'windrose'
    );
  }

  async function highRes() {
    const { credits, id } = $user;

    if (credits) {
      download(4000);
      await updateDoc(doc(db, 'users', id), {
        credits: increment(-1),
      });
    } else {
      localStorage.setItem('last-state', JSON.parse({path: '/windrose-view', ...history.state}));
      navigate('/payment');
    }
  }

  function isMobile() {
    return window.innerWidth < 900;
  }
</script>

{#if results}
  <Scene bind:scene {results} />
{/if}

<div class="controls">
  <Checkbox bind:checked={showMeasurements}>Show Measurements</Checkbox>
  <Checkbox bind:checked={showCoordinets}>Show Coordinates</Checkbox>
</div>

<footer>
  <Button size="small" on:click={() => download()}>
    Low Res
  </Button>
  {#if $user}
    &nbsp; &nbsp;
    <Button size="small" on:click={highRes}>
      High Res
    </Button>
  {/if}
</footer>

<style>
  .controls {
    position: fixed;
    left: 20px;
    top: 80px;
    z-index: 1;
  }
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
</style>
