<script lang="ts">
  import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
  } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { db } from '../../config/firebase';
  import { page, user } from '../../config/stores';
  import { facebookShare } from '../../utils/facebook-share';
  import { twitterShare } from '../../utils/twitter-share';

  const pageSize = 100;

  let items = [];
  let hasMore = true;
  let loading = false;
  let ref;

  page.set({
    background: 'transparent',
    footer: false,
    header: 'transparent',
    anima: true
  });

  async function loadMore() {
    if (loading) {
      return;
    }

    loading = true;

    const operations = [
      orderBy('createdOn', 'desc'),
      ...(ref ? [startAfter(ref)] : []),
      limit(pageSize + 1),
    ];

    const { docs } = await getDocs(
      query(collection(db, 'stardust-gallery'), ...operations)
    );

    items = [
      ...items,
      ...docs.slice(0, pageSize).map((it) => ({ id: it.id, ...it.data() })),
    ];

    hasMore = docs.length === 11;
    ref = docs[pageSize];
    loading = false;
  }

  onMount(async () => {
    await loadMore();
  });

  function link() {
    if ($user) {
      navigate('/stardust/add');
      return;
    }

    navigate('/login', { state: { redirect: '/stardust/add' } });
  }

  function shareTwitter(item) {
    twitterShare(item.content, 'stardust/gallery', item.id);
  }

  function shareFacebook(id: string) {
    facebookShare('stardust/gallery', id);
  }

  async function deleteItem(id: string) {
    await deleteDoc(doc(collection(db, 'stardust-gallery'), id));
    items.splice(
      items.findIndex((it) => it.id === id),
      1
    );
    items = [...items];
  }
</script>

<div class="grid jc-start">
  {#each items as item}
    <div class="col-4 col-m-6 col-s-12">
      <div class="card">
        <div class="flex jc-between">
          <div class="username">{item.username}</div>
          <div class="time">{new Date(item.createdOn).toLocaleString()}</div>
        </div>
        <div class="content">{item.content}</div>
        <img class="image" src={item.url} />

        <div class="flex jc-between m-t-s">
          <div>
            <button class="share" on:click={() => shareFacebook(item.id)}>
              <img src="/assets/facebook-logo.svg" alt="Facebook Logo" />
            </button>
            <button class="share" on:click={() => shareTwitter(item)}>
              <img src="/assets/twitter-logo.svg" alt="Twitter Logo" />
            </button>
          </div>
          {#if $user?.uid === item.user}
            <div>
              <button class="delete" on:click={() => deleteItem(item.id)}>
                <img src="/assets/delete.png" alt="Delete" />
              </button>
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/each}
</div>

<button class="add" on:click={link}>+</button>

<style>
  .card {
    background-color: #21212180;
    border: 0.5px solid #392f2f;
    border-radius: 20px;
    padding: 20px 20px 10px;
    color: #ffffffe0;
    height: 100%;
  }

  .username,
  .time {
    font-size: 12px;
  }
  .content {
    font-size: 18px;
  }
  .add {
    position: fixed;
    background: rgba(39, 39, 39, 0.8);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 20px;
    border: none;
    color: #ffffffe0;
    font-size: 30px;
    cursor: pointer;
  }
  .content {
    margin: 10px 0;
  }

  .share,
  .delete  {
    border: none;
    cursor: pointer;
    background: none;
  }

  .share > img,
  .delete > img {
    width: 30px;
  }
</style>
