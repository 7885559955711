<script>
  import { createEventDispatcher } from 'svelte';
  import { Link } from 'svelte-routing';

  let dispatch = createEventDispatcher();

  /**
   *  Specify button size
   *  @type {"button" | "submit" | "reset"}
   */
  export let type = 'button';

  /**
   *  Specify button size
   *  @type {'regular' | 'small'}
   */
  export let size = 'regular';

  /**
   *  Specify button text color
   *  @type {string}
   */
  export let color = '#FFFFFF';

  /**
   *  Specify if button is a link
   *  @type {string}
   */
  export let link = undefined;

  /**
   *  Specify button background color
   *  @type {string}
   */
  export let backgroundColor = 'rgba(39, 39, 39, 0.8)';

  export let loading = false;
  export let disabled = false;
</script>

{#if link}
  <Link to={link}>
    <button
      class={size}
      {type}
      style="color: {color}; background-color: {backgroundColor};"
    >
      <slot />
    </button>
  </Link>
{:else}
  <button
    class={size}
    class:loading={loading}
    {type}
    {disabled}
    on:click={() => dispatch('click')}
    style="color: {color}; background-color: {backgroundColor};"
  >
    <div style="user-select: none;">
      <slot />
    </div>
  </button>
{/if}

<style>
  button {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Yellix Regular', sans-serif;
    border: none;
    cursor: pointer;
  }

  button.regular {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 16px;
    font-size: 16px;
  }

  button.small {
    height: 40px;
    padding: 0 24px;
    line-height: 40px;
    border-radius: 10px;
    font-size: 14px;
  }

  .loading {
    position: relative;
    color: transparent !important;
    pointer-events: none !important;
    user-select: none !important;
    -webkit-user-select: none !important;
  }

  .loading:after {
    content: '';
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid white;
    border-bottom-color: transparent;
    animation: loading 0.75s infinite;
  }

  @keyframes loading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
