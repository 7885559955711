<script lang="ts">
  import { navigate } from 'svelte-routing';
  import { menuOpen, page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true,
  });
</script>

<div class="grid">
  <div class="col-12">
    <video width="100%" controls>
      <source src="/assets/airplay.mp4" type="video/mp4" />
    </video>
    <p class="m-t-l">
      <b>∆IRPL∆Y</b> is the first public presentation of the <b>∆NIM∆ ΔPP</b>:
      At sunset on September 23rd (7.03 pm) 2022, the Pleasure Garden
      (Lustgarten) on the UNESCO World Heritage Site of Museum Island in Berlin
      is becoming the location and venue for interactive laser performance by
      Sabine Kacunko. In its focus stands the artistic exploration of the local
      air quality index (AQI) and individual human breathing and their
      interaction.
    </p>

    <p class="m-t-m">
      The current color of the laser beam indicates the local air quality, while
      the <b>∆NIM∆ ΔPP</b> users on site can change this color again and again
      by using the breathing values they have measured themselves – this “<b
        >∆IRPL∆Y</b
      >“ makes the laser beam rhythmically shining on. With this interplay of
      individual breath and local air quality, <b>∆IRPL∆Y</b> signals “virtual breathing”
      and symbolizes a “collective lung machine” coupled to the concrete measurement
      data. The reflexive and vital functions of inhaling and exhaling thus create
      a fundamental connection between breathing beings and their planet.
    </p>

    <img src="/assets/airplay-cta.jpg" class="m-t-xl" />

    <h2 class="m-t-xl">TIME AND PLACE</h2>

    <p class="m-t-m">
      Autumnal Equinox Day (September 23rd, 2022) is celebrated around the world
      as Educational Technology Day, Temperature Control Day, but also as Native
      American Day, Bisexuality Day, and more. All of today’s actions and
      demonstrations – not least here in Berlin – are primarily about regaining
      balance – between humans and nature, nature and culture, inside and
      outside, male and female, day and night, darkness and light.
    </p>

    <p class="m-t-m">
      The Museum Island Berlin has been a UNESCO World Heritage Site for 23
      years and the Pleasure Garden (Lustgarten) is its largest open and public
      green space. At sunset, a gigantic laser beam rises vertically from its
      center into the sky: Sabine Kacunko's art project aims to get to the heart
      of the interaction between human and nature – breathing and the air become
      symbols of the vital dynamics of the world and a model of an expanded
      environmental awareness.
    </p>

    <img src="/assets/timeandplace.png" alt="" class="m-t-xl" />

    <h2 class="m-t-l">ORBITAL MEDIA SCULPTURE</h2>

    <p class="m-t-m">
      The 600-watt strong interactive laser projection represents the locally
      and individually measured air quality and breathing air and thus becomes
      the carrier of an orbitally oriented, collective message of humanity to
      itself: The "I" of the local input – the light beam – stretches for
      kilometers up into the stratosphere and it "nudges" the spherical "O" of
      the orbital output – the Earth – creating a virtual Φ (Phi).
    </p>

    <p class="m-t-m">
      Φ is the 21st letter of the Greek alphabet, considered the "perfect"
      description of the principles of growth and dynamism in nature. It has
      always been viewed by artists and scientists as a "measurable value" that
      yields both beauty and efficiency. The 233 colors of the color spectrum
      used by the artist is a number from the infinite sequence of Φ and the
      Fibonacci number sequence based on it.
    </p>

    <img src="/assets/orbitalmediasculpture.png" alt="" class="m-t-xl" />

    <h2 class="m-t-l">CITIZEN ΔRT & SCIENCE</h2>

    <p class="m-t-m">
      With <b>∆IRPL∆Y</b>, like with other artistic actions coupled with the
      <b>∆NIM∆ ∆PP</b>, a participatory collection on environmental changes
      caused by humans is being created, which aims to go beyond the current
      forms of participation in museums and research institutions. As a result,
      concepts such as “Citizen Science” and “Planetary Health” will be further
      developed in public space as <b>CITIZEN ΔRT & SCIENCE</b>.
    </p>

    <p class="m-t-m">
      As a step in the <b>∆NIM∆</b> project, <b>∆IRPL∆Y</b> addresses the
      aspired “healing” aspects of our (non-)doing by pointing to the
      interconnectedness of all “living” or “animated” beings that are
      ultimately relevant to human as well as “planetary” health. In
      perspective, <b>∆IRPL∆Y</b> points to a <b>F∆IRPL∆Y</b>.
    </p>

    <img src="/assets/qrcode.png" class="m-t-xl" />

    <iframe class="m-t-xl" width="100%" height="315" src="https://www.youtube.com/embed/fx1-QcJudHQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <h2 class="m-t-xl">M∆NY TH∆NKS TO PROJECT P∆RTNERS</h2>

    <p class="m-t-m">LaserAnimation Sollinger GmbH</p>
    <p class="m-t-m">betteries AMPS GmbH</p>
    <p class="m-t-m">German Environment Agency</p>
    <p class="m-t-m">Global Carbon Atlas-platform</p>
    <p class="m-t-m">Air Quality Open Data Platform</p>
    <p class="m-t-m">Micro Human</p>
    <p class="m-t-m">
      International Year of Basic Sciences for Sustainable Development
      (IYBSSD2022)
    </p>
  </div>
</div>

<div class="bottom-buttons" class:menu-hidden={$menuOpen}>
  <div class="bottom-button" on:click={() => navigate('/airplay/berlin')}>
    <span>Berlin</span>
  </div>
</div>

<style>
  .grid {
    max-width: 800px;
    width: 90%;
    margin-bottom: 12%;
  }
  .pdf {
    object-fit: contain;
    width: 100%;
    height: 1100px;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding-top: 12%;
  }

  h2 {
    color: white;
  }

  p,
  a {
    font-size: 18px;
    color: white;
  }

  a {
    text-decoration: underline;
  }

  .bottom-buttons {
    justify-content: center;
  }

  @media (max-width: 500px) {
    p {
      font-size: 14px;
    }
    .pdf {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
    h2 {
      font-size: 1.2em;
    }
  }
</style>
