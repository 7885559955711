import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { user } from './stores';
const app = initializeApp({
    apiKey: 'AIzaSyCWxNuc-HBAgTg3o5BpWDGDdImdpuUNv_Y',
    authDomain: 'jp-anima.firebaseapp.com',
    projectId: 'jp-anima',
    storageBucket: 'jp-anima.appspot.com',
    messagingSenderId: '200232304945',
    appId: '1:200232304945:web:fc8e2d2b24471e03f5f010',
    measurementId: 'G-9452BEM0MR'
});
export const auth = getAuth(app);
export const functions = getFunctions(app, 'europe-west2');
export const db = getFirestore(app);
export const storage = getStorage(app);
let userSub;
// connectFunctionsEmulator(functions, "localhost", 5001);
onAuthStateChanged(auth, async (u) => {
    if (u) {
        userSub = onSnapshot(doc(db, 'users', u.uid), (doc) => {
            const parts = (u.email || '').split('@');
            const username = [parts[0].slice(-3), parts[1].slice(0, 3)].join('@');
            user.set(Object.assign({ uid: u.uid, username }, doc.data()));
        });
        return;
    }
    else if (userSub) {
        userSub();
        userSub = null;
    }
    user.set(u);
});
