<script>
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">∆CCEPTABLE USE POLICY</h1>

  <p class="col-12 p-y-0 c-white">
    This acceptable use policy (“Policy”) sets forth the general guidelines and
    acceptable and prohibited uses of the ∆NIM∆ ∆PP, i.e. anima-web.de website
    (“Website” or “Service”) and any of its related products and services
    (collectively, “Services”). This Policy is a legally binding agreement
    between you (“User”, “you” or “your”) and this Website operator (“Operator”,
    “we”, “us” or “our”). If you are entering into this agreement on behalf of a
    business or other legal entity, you represent that you have the authority to
    bind such entity to this agreement, in which case the terms “User”, “you” or
    “your” shall refer to such entity. If you do not have such authority, or if
    you do not agree with the terms of this agreement, you must not accept this
    agreement and may not access and use the Website and Services. By accessing
    and using the Website and Services, you acknowledge that you have read,
    understood, and agree to be bound by the terms of this Agreement. You
    acknowledge that this Agreement is a contract between you and the Operator,
    even though it is electronic and is not physically signed by you, and it
    governs your use of the Website and Services.
  </p>

  <h2 class="col-12 c-white">Prohibited activities and uses</h2>
  <p class="col-12 c-white">
    You may not use the Website and Services to publish content or engage in
    activity that is illegal under applicable law, that is harmful to others, or
    that would subject us to liability, including, without limitation, in
    connection with any of the following, each of which is prohibited under this
    Policy:
  </p>
  <ul class="col-12 c-white">
    <li>Distributing malware or other malicious code.</li>
    <li>Disclosing sensitive personal information about others.</li>
    <li>
      Collecting, or attempting to collect, personal information about third
      parties without their knowledge or consent.
    </li>
    <li>Distributing pornography or adult related content.</li>
    <li>Promoting or facilitating prostitution or any escort services.</li>
    <li>
      Hosting, distributing or linking to child pornography or content that is
      harmful to minors.
    </li>
    <li>
      Promoting or facilitating gambling, violence, terrorist activities or
      selling weapons or ammunition.
    </li>
    <li>
      Engaging in the unlawful distribution of controlled substances, drug
      contraband or prescription medications.
    </li>
    <li>
      Managing payment aggregators or facilitators such as processing payments
      on behalf of other businesses or charities.
    </li>
    <li>
      Facilitating pyramid schemes or other models intended to seek payments
      from public actors.
    </li>
    <li>
      Threatening harm to persons or property or otherwise harassing behavior.
    </li>
    <li>
      Infringing the intellectual property or other proprietary rights of
      others.
    </li>
    <li>
      Facilitating, aiding, or encouraging any of the above activities through
      the Website and Services.
    </li>
  </ul>
  <h2 class="col-12 c-white">System abuse</h2>
  <p class="col-12 c-white">
    Any User in violation of the Website and Services security is subject to
    criminal and civil liability, as well as immediate account termination.
    Examples include, but are not limited to the following:
  </p>
  <ul class="col-12 c-white">
    <li>
      Use or distribution of tools designed for compromising security of the
      Website and Services.
    </li>
    <li>
      Intentionally or negligently transmitting files containing a computer
      virus or corrupted data.
    </li>
    <li>
      Accessing another network without permission, including to probe or scan
      for vulnerabilities or breach security or authentication measures.
    </li>
    <li>
      Unauthorized scanning or monitoring of data on any network or system
      without proper authorization of the owner of the system or network.
    </li>
  </ul>
  <h2 class="col-12 c-white">Service resources</h2>
  <p class="col-12 c-white">
    You may not consume excessive amounts of the resources of the Website and
    Services or use the Website and Services in any way which results in
    performance issues or which interrupts the Services for other Users.
    Prohibited activities that contribute to excessive use, include without
    limitation:
  </p>
  <ul class="col-12 c-white">
    <li>
      Deliberate attempts to overload the Website and Services and broadcast
      attacks (i.e. denial of service attacks).
    </li>
    <li>
      Engaging in any other activities that degrade the usability and
      performance of the Website and Services.
    </li>
  </ul>
  <h2 class="col-12 c-white">Defamation and objectionable content</h2>
  <p class="col-12 c-white">
    We value the freedom of expression and encourage Users to be respectful with
    the content they post. We are not a publisher of User content and are not in
    a position to investigate the veracity of individual defamation claims or to
    determine whether certain material, which we may find objectionable, should
    be censored. However, we reserve the right to moderate, disable or remove
    any content to prevent harm to others or to us or the Website and Services,
    as determined in our sole discretion.
  </p>
  <h2 class="col-12 c-white">Copyrighted content</h2>
  <p class="col-12 c-white">
    Copyrighted material must not be published via the Website and Services without the explicit permission of the copyright owner or a person explicitly authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we may, at our discretion, run an investigation and, upon confirmation, may remove the infringing material from the Website and Services. We may terminate the Service of Users with repeated copyright infringements. Further procedures may be carried out if necessary. We will assume no liability to any User of the Website and Services for the removal of any such material. If you believe your copyright is being infringed by a person or persons using the Website and Services, please get in touch with us to report copyright infringement. User will keep hold of the own uploaded photographs, and we will not us it for any other purpose, except of keeping it visible on our Website. We are not responsible for what other users will potentially do with the uploaded and available photographs.
  </p>
  <h2 class="col-12 c-white">Security</h2>
  <p class="col-12 c-white">
    You take full responsibility for maintaining reasonable security precautions
    for your account. You are responsible for protecting and updating any login
    account provided to you for the Website and Services.
  </p>
  <h2 class="col-12 c-white">Enforcement</h2>
  <p class="col-12 c-white">
    We reserve our right to be the sole arbiter in determining the seriousness
    of each infringement and to immediately take corrective actions, including
    but not limited to:
  </p>
  <ul class="col-12 c-white">
    <li>
      Suspending or terminating your Service with or without notice upon any
      violation of this Policy. Any violations may also result in the immediate
      suspension or termination of your account.
    </li>
    <li>
      Disabling or removing any content which is prohibited by this Policy,
      including to prevent harm to others or to us or the Website and Services,
      as determined by us in our sole discretion.
    </li>
    <li>
      Reporting violations to law enforcement as determined by us in our sole
      discretion.
    </li>
    <li>
      A failure to respond to an email from our abuse team within 2 days, or as
      otherwise specified in the communication to you, may result in the
      suspension or termination of your account.
    </li>
  </ul>
  <p class="col-12 c-white">
    Suspended and terminated User accounts due to violations will not be
    re-activated. A backup of User’s data may be requested, however, we may not
    be able to provide you with one and, as such, you are strongly encouraged to
    take your own backups.
  </p>
  <p class="col-12 c-white">
    Nothing contained in this Policy shall be construed to limit our actions or
    remedies in any way with respect to any of the prohibited activities. In
    addition, we reserve at all times all rights and remedies available to us
    with respect to such activities at law or in equity.
  </p>
  <h2 class="col-12 c-white">Reporting violations</h2>
  <p class="col-12 c-white">
    If you have discovered and would like to report a violation of this Policy,
    please contact us immediately. We will investigate the situation and provide
    you with full assistance.
  </p>
  <h2 class="col-12 c-white">Changes and amendments</h2>
  <p class="col-12 c-white">
    We reserve the right to modify this Policy or its terms related to the
    Website and Services at any time at our discretion. When we do, we will
    revise the updated date at the bottom of this page. We may also provide
    notice to you in other ways at our discretion, such as through the contact
    information you have provided.
  </p>
  <p class="col-12 c-white">
    An updated version of this Policy will be effective immediately upon the
    posting of the revised Policy unless otherwise specified. Your continued use
    of the Website and Services after the effective date of the revised Policy
    (or such other act specified at that time) will constitute your consent to
    those changes.
  </p>
  <h2 class="col-12 c-white">Acceptance of this policy</h2>
  <p class="col-12 c-white">
    You acknowledge that you have read this Policy and agree to all its terms
    and conditions. By accessing and using the Website and Services you agree to
    be bound by this Policy. If you do not agree to abide by the terms of this
    Policy, you are not authorized to access or use the Website and Services.
  </p>
  <h2 class="col-12 c-white">Contacting us</h2>
  <p class="col-12 c-white">If you have any questions, concerns, or complaints regarding this Policy, we encourage you to contact us using the details below:</p>
  <p class="col-12 c-white"><a href="mailto:info@anima-web.de">info@anima-web.de</a></p>
  <p class="col-12 c-white m-b-l">This document was last updated on August 15th 2022</p>
</div>
