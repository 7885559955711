import { Matrix4 } from 'three';
import { EPSILON } from './constraints';
export class NormalConstraint {
    constructor(p1, p2, opts) {
        this.p1 = p1;
        this.p2 = p2;
        this.opts = opts;
        this.k_prime = Math.pow(1.0 - opts.kBend, 1.0 / opts.solverIterations);
        if (1 - this.k_prime > 1.0) {
            this.k_prime = 1.0;
        }
    }
    update() {
        const dist = this.p2.prediction.clone().sub(this.p1.prediction);
        const dir = dist.clone().normalize();
        const angle = Math.acos(this.opts.normal.dot(dir));
        if (angle <= EPSILON) {
            return;
        }
        const invMass1 = this.p1.invMass;
        const invMass2 = this.p2.invMass;
        const invMass = invMass1 + invMass2;
        if (invMass <= EPSILON) {
            return;
        }
        let rotPos = this.p1.prediction.clone();
        const mat = new Matrix4();
        let axis = dir.clone().cross(this.opts.normal);
        if (invMass1 > 0.0) {
            mat.makeRotationAxis(axis, -angle / 2 * (1 - this.k_prime));
            mat.multiply(new Matrix4().makeTranslation(-rotPos.x, -rotPos.y, -rotPos.z));
            mat.premultiply(new Matrix4().makeTranslation(rotPos.x, rotPos.y, rotPos.z));
            this.p1.prediction.copy(this.p1.prediction.clone().applyMatrix4(mat));
        }
        if (invMass2 > 0.0) {
            mat.makeRotationAxis(axis, angle / 2 * (1 - this.k_prime));
            mat.multiply(new Matrix4().makeTranslation(-rotPos.x, -rotPos.y, -rotPos.z));
            mat.premultiply(new Matrix4().makeTranslation(rotPos.x, rotPos.y, rotPos.z));
            this.p2.prediction.copy(this.p2.prediction.clone().applyMatrix4(mat));
        }
    }
}
