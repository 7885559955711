<script>
  import { COLORS } from '../../config/colors.const';
  import { page } from '../../config/stores';

  const lt = 100 / COLORS.length;

  page.set({
    header: 'transparent',
    background: `linear-gradient(90deg, ${COLORS.reverse().map((color, index) =>  [color, (index * lt) + '%'].join(' ')).join(', ')})`,
    anima: true,
    backgroundBeforeAnima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">D∆T∆ & ∆LGORITHMS</h1>

  <p class="col-12 p-y-0 c-white">
    From the technical point of view, ∆NIM∆ ∆PP is a progressive web application
    (PWA), a software delivered through the web that functions like a common
    website using HTML, CSS, Svelte, TypeScript, GLSL and JavaScript. While it is intended to work on any platform using standard browsers,
    it can be optionally installed on users´ devices when distributed through
    Google Play or Apple App Store.
    <br /> <br />
    <b>1. HOME: The GLOBAL INPUT</b> - function visualizes the air quality index (AQI) in form
    of a dynamic ∆IRSPHERE related to the “air pollution” anywhere in the world. The
    measured AQI contains three parameters:
    <br /> <br />
    (1) PM10 (Fine dust / alveolar dust) – a mixture of solid and fluid particles
    (aerosols), which influences human respiration and heart-circulation system;
    <br /> <br />
    (2) O3 (Ozon) – a toxic gas, which can cause chronic difficulties of the respiration
    system, and
    <br /> <br />
    (3) NO2 (Nitrogen dioxide) – a toxic gas, which leads to the inflammation of
    the respiration system and enhances the effects of O3 and PM10.
    <br /> <br />
    According to the GPS-coordinates, ∆NIM∆ ∆PP locates the user´s exact geographical
    coordinate and picks the average value of the air quality index from the nearest
    measuring station. Used are presently the dynamic open source data bases of Air
    Quality Open Data Platform (https://waqi.info), Global Carbon Atlas-platform
    (http://www.globalcarbonatlas.org) and of German Environment Agency (https://www.umweltbundesamt.de/en).
    Intended are refinements according to the development and density of the existing
    data bases and measuring stations. The automatic display of the results occurs
    via Application Programming Interface (API), respectively, a software intermediary
    that allows two applications to “talk” to each other.
    <br /> <br />
    ∆NIM∆ ∆PP widens the formal and chromatic spectrum of the existing custom global
    and regional maps and time series of carbon fluxes and contributes to the community
    and collaborative international research as an artistic effort intended to serve
    an even broader spectrum of users. Color gradations are hence refined by extension
    to 233 (a number on the Fibonacci-scale), following an algorithmic key that uses
    for PM10 approximately each number from 0-89, for O3 from 0-80, and for NO2 from
    0-64.
    <br /> <br />
    <b>2. HOME: The INDIVIDUAL INPUT</b> - function visualizes the intensity (volume) and extensity (duration) of
    the continuous exhalation by the user: We use microphone input volume and microphone input duration - more specifically: the average input volume and -duration. When the user blows or gives any kind
    of continuous voice into her mobile phone-microphone (function CHECK YOUR INPUT),
    the ∆NIM∆ ∆PP measures and visualizes it with the specific colored BRE∆TH B∆RREL.
    Although a “good” value might mean good lungs or good will to participate (capacity,
    effort: the longer the better), the point is that a visual and dynamic interpenetration
    of the ∆IRSPHERE and BRE∆TH B∆RREL takes place, so pointing to the interdependence
    of the global and individual input on the respective locality. 
    <br /> <br />
    Local AQI is sourced by sending an API request to WAQI.
    <br /> <br />
    Our color scheme consists of 233 colors, where 0 represents light green, and
    233 represents the darkest red. To accommodate the chosen range of colors everything
    needs to be scaled to a scale of 0 to 233. Sourced AQI needs to be scaled from
    a range of 0 - 500 to a range of 0 - 233.
    <br /> <br />

    The following equation is used for scaling AQI:
    <br /> <br />
    scaledAQI = sourcedAQI* 233 / 500
    <br /> <br />

    The number calculated determines the color of the globe. To calculate the
    user’s input the the following logic is used:
    <br /> <br />
    (1) For the first three seconds (the countdown) we calculate the threshold volume
    for silence. During the blowing phase, if the volume recorded by the microphone
    falls below the calculated “silence” threshold volume, the system concludes the
    session. The maximum volume that a smartphone’s microphone can record is 100,
    we also need to account for any noise. So the actual microphone volume scale
    that is used is: 
    <br /> <br />
    maxVolume = 100 - silenceThreshold 
    <br /> <br />
    The volume scale is then 0 to maxVolume
    <br /> <br />

    (2) Every 100ms the microphone volume is calculated and added to the
    volumeCounter. The cylinder is also colored every 100ms, so that the user's
    results can be seen in real time, as they are being calculated. To calculate
    the color of the cylinder we use two main parameters, the averageVolume
    recorded by the users microphone, and elapsedTime
    <br /> <br />
    The following equations are used by the algorithm: 
     <br /> <br />
    volumeCounter += currentVolume - silenceThreshold (This happens every 100ms) 
    averageVolume = volumeCounter / elapsedTime
     <br /> <br />
    averageVolume is scaled from a range of 0 to maxVolume to a range of 0 to 233,
    using the following equation:
    <br /> <br />
    scaledAverageVolume = averageVolume * 233 / maxVolume
    <br /> <br />
    The color of the cylinder representing the user’s result is calculated with the
    following equation:
    <br /> <br />
    cylinderColor =scaledAverageVolume + elapsedTime / 2
    <br /> <br />
    The session is automatically concluded after 23 300 milliseconds (23 second),
    this number could be changed to any preferred number, and we would only need
    to include one additional equation to the final cylinder calculation. We would
    need to scale the chosen max possible duration, to a scale of 0 to 233.
    <br /> <br />
    Since both scaledAverageVolume and elapsedTime are always a number between 0 and 233,
    the cylinderColor is always a number from 0 to 233, which is then matched to
    the corresponding color from the range of colors.
    <br /> <br />
    If you consider the equations you can conclude that a longer (larger) duration
    of the user’s input would grant a higher result, but a higher volume would mean
    a lower result. This is why we reverse the scale when considering the calculated
    microphone volume. 
    <br /> <br />
    <b>3. ∆RCHIVE: ∆IRSPHERE (The LOC∆L / INDIVIDUAL OUTPUT´s "snapshot")</b>
    <br /> <br />
    The globe is constructed using the equation for drawing sphere’s, although
    the number of dots from which the globe is constructed is random every time
    the user refreshes (enters again) the page. Each dot fluctuates/resonates at
    a random frequency, the frequency is randomized every time the page is
    refreshed or entered again.
    <br /> <br />
    This page shows the anonymous history of each user that went through the ∆NIM∆ process. Each result is saved as a dataset consisting of:
    <br /> <br />
    - The users recorded latitude and longitude
    - Date of sampling
    - record AQI as a number
    - recorded Lung capacity as a number
    <br /> <br />
    At the same time, the page shows the history of the logged in user (a "YOU" appears at the right upper side). 
    <br /> <br />
    The cylinder that passes through the globe is constructed using the equation
    for drawing cylinders, the number of dots from which the cylinder is
    constructed is always the same, as is its structure. But the fluctuation of
    each dot is randomized every time the user refreshes or re-enters the page.
    <br /> <br />

    <b>4. ∆ESTHETICS</b>
    <br /> <br />
    Both the ∆IRSPHERE and WINDROSE use 233 colors, respectively, defined by the color codes specified by the artist.
    <br /> <br />
    <img src="https://firebasestorage.googleapis.com/v0/b/jp-anima.appspot.com/o/skala_animaA-01.png?alt=media&token=d6deca6f-cfd8-4b28-8466-06cc71d050e6">
    <br /> <br />

    <b>5. SC∆L∆BILITY & SUPPORT</b>
    <br /> <br />
    This is a beta-version of the ∆NIM∆ ∆PP, which is supposed to be scalable (not
    only rigidly built for few users). However, the scalability is to be checked
    first upon a growing number of (simultaneous and revisiting) users. Feedback
    and involvement are hence highly appreciated in order to improve and expand the
    existing features of the ∆NIM∆ ∆PP. To provide feedback, please contact our team
    via email (info@anima-web.de) or communicate issues of broader interest to the
    community through the social media. 
    <br /> <br />
    The ∆NIM∆ ∆PP is initially made possible by a support of the non-profit organization MICRO HUMAN (https://microhuman.org/en/#/cat/micro-human/) and requires the support and involvement of the broader user community for its long-term viability.
    The ∆NIM∆ project relies hence on funding from a wide range of sources and your support is vital in enabling us to fulfill its mission, aiming at leading to new awareness, knowledge and action in supporting global efforts to limit the human-induced consequences of the climate change. Please consider supporting MICRO HUMAN today.
    <br /> <br />
    Go directly to Micro Human NPO (non-profit organization) - support page:
    https://microhuman.org/en/#/cat/support-us/
    <br /> <br />
    If you have any questions or special requests or would you like to arrange an individual appointment with us, please find our contact details below:
    <br /> <br />
    Micro-Human
    E-mail: info@microhuman.org
    Phone: +49 176 4352 9053
    <br /> <br />

    <b>6. ∆CTIONS</b> consists of actions that use the ∆NIM∆ ∆PP or serve as the latter´s extensions, both digital and analogue. It is aimed at reaching more profound visual and conceptual
    results, which requires hardware and software extensions. The extended outputs appear
    when number of individual inputs on particular location grows. In order to
    reach this, the ∆NIM∆ ∆PP is aimed to be presented on local occasions in the
    public spaces, where particularly larger LED- or other display surfaces (or
    other types of outputs, cf. project WINDROSE) shall be used to generate local inputs and
    discussions as well as display and share awareness and provoke appropriate
    actions. In some such cases, ∆NIM∆ ∆PP shall be extended with the appropriate
    interfaces to the local output equipment. The communication between the ∆NIM∆ ∆PP and the local/public software shall be reached via special Application Programming Interface (API).
    <br /> <br />
  </p>
</div>
