import { Matrix4, Vector3 } from 'three';
const TWO_PI = Math.PI * 2;
export class Segment {
    constructor(position, normal, height) {
        this.totalForce = new Vector3();
        this.velocity = new Vector3();
        this.position = new Vector3();
        this.prediction = new Vector3();
        this.positionOld = new Vector3();
        this.normal = new Vector3(0, 1, 0);
        this.points = [];
        this.radius = 0.1;
        this.height = 0.05;
        this.mass = 1.0;
        this.invMass = 1.0;
        this.density = 1000; //kg/m^3
        this.position = position;
        this.positionOld = position.clone();
        this.normal = normal;
        this.height = height;
        this.mass = Math.max(Math.PI * (this.radius * this.radius) * this.height * this.density, 0.1);
        this.invMass = 1 / this.mass;
    }
    createPoints(numOfPoints) {
        for (let i = 0; i < numOfPoints; i++) {
            this.points.push(new Vector3());
        }
        //this.updatePoints()
    }
    updatePoints() {
        const EPS = 10e-5;
        let Vz = new Vector3(0, 0, -1);
        let Vr = Vz.clone().cross(this.normal);
        let angle;
        // If normal is close to our vector, change its direction to produce better results
        if (Vr.length() < EPS) {
            Vz = new Vector3(0, 1, 0);
            Vr = Vz.clone().cross(this.normal);
        }
        let p0 = this.position.clone().add(Vr.multiplyScalar(this.radius));
        const mat = new Matrix4();
        for (let i = 0; i < this.points.length; i++) {
            angle = i / this.points.length * TWO_PI;
            mat.makeRotationAxis(this.normal, angle);
            mat.multiply(new Matrix4().makeTranslation(-this.position.x, -this.position.y, -this.position.z));
            mat.premultiply(new Matrix4().makeTranslation(this.position.x, this.position.y, this.position.z));
            this.points[i].copy(p0.clone().applyMatrix4(mat));
        }
    }
    update() {
        this.updatePoints();
    }
}
