<script lang="ts">
  import { hsvToHex } from '../utils/hsv-to-rgb';
  import { addDoc, collection, updateDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
  import { db } from '../config/firebase';
  import Button from './Button.svelte';
  import Countdown from './Countdown.svelte';
  import { RESOURCES } from '../state';
  import { user, notification, menuOpen } from '../config/stores';
  import { Link, navigate } from 'svelte-routing';
  import { select } from 'd3';
  import { createEventDispatcher } from 'svelte';
  import { COLORS } from '../config/colors.const';

  export let stream: MediaStream;
  export let color = '#fff';
  export let maxElapsed = 12000;
  export let showGraph = true;
  export let col = 'history';
  export let link = 'place';
  export let linkLabel = 'Go To Archive';
  export let lungCapacity = 0;
  export let scaledAverageVolume = 0;
  export let laser;

  /**
   * The interval in which measurements are taken
   */
  export let interval = 40;

  /**
   * Number of seconds to calibrate to deduce noise level
   */
  export let countdown = 3;

  const audioContext = new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();

  analyser.fftSize = 512;
  analyser.minDecibels = -127;
  analyser.maxDecibels = 0;
  analyser.smoothingTimeConstant = 0.4;

  audioSource.connect(analyser);

  const volumes = new Uint8Array(analyser.frequencyBinCount);
  const colors = COLORS.reverse();

  let started = false;
  let recording = false;
  let results = false;
  let ref: any;
  let loading = false;
  let saved = false;
  let graph = false;
  let graphEl;
  let graphData: any;
  let time = '00:00';
  let dispatch = createEventDispatcher();

  let int;
  let countdownCompleted: boolean;
  let silenceThreshold = 0;
  let elapsed;
  let maxScaledVolume;
  let forgiveRange = false;

  let laserDone = false;
  let laserStartIn = 0;
  let laserSub;
  let laserFormat;
  let laserDuration = 5000;
  let laserInterval;

  $: if (graphEl) {
    renderGraph();
  }

  $: if (results) {
    dispatch('finished');
  }

  $: laserFormat = formatTime(laserStartIn, false);

  function finish() {
    recording = false;
    started = false;
    results = true;
    graphData = null;

    addDoc(collection(db, col), {
      lungCapacity,
      createdOn: Date.now(),
      airQuality: RESOURCES.airQuality,
      lat: RESOURCES.location.lat,
      lng: RESOURCES.location.lng,
      anima: RESOURCES.anima,
      maxScaledVolume: maxScaledVolume || 0,
      scaledAverageVolume: scaledAverageVolume || 0,
      ...(RESOURCES.airPlaySession && {
        airPlaySessionId: RESOURCES.airPlaySession,
        airPlaySessionProcessed: false,
      }),
    })
      .then(d => {
        ref = d;

        if (laser) {
          laserSub = onSnapshot(
            query(
              collection(db, 'history'),
              where('airPlaySessionId', '==', RESOURCES.airPlaySession),
              where('airPlaySessionProcessed', '==', false),
              orderBy('createdOn', 'asc')
            ),
            snap => {
              const index = snap.docs.findIndex(it => it.id === d.id);

              if (laserInterval) {
                clearInterval(laserInterval);
              }

              const done = () => {

                if (laserInterval) {
                  clearInterval(laserInterval);
                  laserInterval = null;
                }

                setTimeout(() => {
                  laserDone = true;
                }, laserDuration);
              };

              if (index !== -1) {
                const before = snap.docs.slice(0, index + 1);
                laserStartIn = before.reduce(acc => acc + laserDuration, 0);

                laserInterval = setInterval(() => {
                  laserStartIn -= 10;
                  if (laserStartIn <= 0) {
                    done();
                  }
                }, 10);
              } else {
                laserStartIn = 0;
                done();
              }
            }
          )
        }
      });

    if (int) {
      clearInterval(int);
      int = null;
    }
  }

  function start() {
    dispatch('triggered');

    saved = false;
    graph = false;
    color = '#fff';

    laserDone = false;
    laserStartIn = 100;

    if (laserInterval) {
      clearInterval(laserInterval);
      laserInterval = null;
    }

    if (laserSub) {
      laserSub();
    }

    started = true;
    countdownCompleted = false;
    results = false;
    silenceThreshold = 0;

    const countDownMs = countdown * 1000;
    const averages = [];

    elapsed = 0;
    scaledAverageVolume = 0;
    maxScaledVolume = 0;

    let silenceThresholdLength = 0;
    let silenceThresholdSum = 0;
    let skipTicks = 5;
    let dispatched = false;

    const timeLost = countDownMs + skipTicks * interval;
    const limit = maxElapsed + timeLost;

    int = setInterval(() => {
      if (elapsed >= limit) {
        finish();
      }

      elapsed += interval;

      analyser.getByteFrequencyData(volumes);

      let sum = 0;
      let length = volumes.length;

      for (const volume of volumes) {
        sum += volume;
      }

      const currentVolume = sum / length;

      if (recording) {
        graph = true;

        if (skipTicks) {
          skipTicks--;
        } else {
          if (!dispatched) {
            dispatch('started');
            dispatched = true;
          }

          const maxVolume = 127 - silenceThreshold;
          const adjsutedWithSilance = currentVolume - silenceThreshold;
          const adjustedVolume =
            adjsutedWithSilance > 0 ? adjsutedWithSilance : 0;
          const scaledAdjustedVolume = (adjustedVolume * 233) / maxVolume;

          averages.push(adjustedVolume);

          const averageVolume =
            averages.reduce((acc, cur) => acc + cur, 0) / averages.length;

          scaledAverageVolume = (averageVolume * 233) / maxVolume;

          if (scaledAdjustedVolume > maxScaledVolume) {
            maxScaledVolume = scaledAdjustedVolume;
          }

          const scaledElapsed = ((elapsed - timeLost) * 23300) / maxElapsed;
          const sum = (scaledElapsed / 50 + scaledAverageVolume) / 2;

          lungCapacity = sum < 233 ? (sum > 0 ? sum : 0) : 233;

          color = colors[!lungCapacity ? lungCapacity : (Math.floor(lungCapacity) - 1)];

          if (graphData) {
            const adjustedBarSize =
              (adjustedVolume * graphData.barMaxHeight) / maxVolume;
            updateGraph(
              adjustedBarSize > graphData.barMinHeight
                ? adjustedBarSize
                : graphData.barMinHeight
            );

            time = formatTime(elapsed - timeLost);
          }

          if (currentVolume < silenceThreshold && !forgiveRange) {
            finish();
          }
        }
      } else if (elapsed >= countDownMs) {
        silenceThreshold = silenceThresholdSum / silenceThresholdLength;
        recording = true;
      } else {
        silenceThresholdLength += length;
        silenceThresholdSum += sum;
      }
    }, interval);
  }

  async function save() {
    const u = $user;

    if (!u) {
      RESOURCES.saveResults = ref;
      navigate('/login');
      return;
    }

    loading = true;

    await updateDoc(ref, { user: u.uid });

    notification.set('Results Saved Successfully!');

    loading = false;
    saved = true;
  }

  function renderGraph() {
    const width = window.innerWidth > 800 ? window.innerWidth * 0.8 : window.innerWidth - 32;
    const height = window.innerWidth > 800 ? 300 : (window.innerWidth > 400 ? 150 : 100);
    const bufferSize = 2;
    const barMinHeight = 1;
    const barMaxHeight = 450;
    const bars = 100;
    const barWidth = (width - bufferSize * bars) / bars;

    graphData = {
      barMinHeight,
      barMaxHeight,
      height,
      bars,
      refs: [],
      current: bars - 1,
    };

    graphData.svg = select(graphEl)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    for (let i = 0; i < bars; i++) {
      graphData.refs.push(
        graphData.svg
          .append('rect')
          .attr('x', i * (barWidth + bufferSize))
          .attr('y', height / 2 - barMinHeight / 2)
          .attr('width', barWidth)
          .attr('height', barMinHeight)
          .attr('fill', 'white')
          .attr('opacity', '0.7')
      );
    }
  }

  function updateGraph(size) {
    graphData.refs[graphData.current]
      .transition()
      .duration(100)
      .attr('y', graphData.height / 2 - size / 2)
      .attr('height', size);

    graphData.current = graphData.current
      ? graphData.current - 1
      : graphData.bars - 1;
  }

  function triggerStart() {
    countdownCompleted = true;
    forgiveRange = true;

    setTimeout(() => {
      forgiveRange = false;
    }, 1160);
  }

  function formatTime(timeInMilis, m = true) {
    const date = new Date(0);
    const milis = timeInMilis;
    date.setMilliseconds(milis > 0 ? milis : 0);
    return date.toISOString().slice(14, m ? 22 : 19).replace('.', ':');
  }
</script>

{#if graph}
  <table id="measurements" class:menu-hidden={$menuOpen}>
    <tr>
      <th>Avg:</th>
      <td
        >{((scaledAverageVolume || 0) > 0 ? scaledAverageVolume : 0).toFixed(
          2
        )}</td
      >
    </tr>
    <tr>
      <th>Max:</th>
      <td>{(maxScaledVolume || 0).toFixed(2)}</td>
    </tr>
    <tr>
      <th>Cap:</th>
      <td>{(lungCapacity || 0).toFixed(2)}</td>
    </tr>
  </table>
  {#if showGraph}
    <div class="gm-wrapper" class:menu-hidden={$menuOpen}>
      <div id="graph" bind:this={graphEl} />
      <div class="gm-time">
        {time}
      </div>
    </div>
  {/if}
{/if}

{#if started}
  {#if countdownCompleted}
    <div
      class="start c-white"
      style="font-size:{85 / 2}px;"
      class:menu-hidden={$menuOpen}
    >
      <span>ST∆RT<br />BLOW / VOICE</span>
    </div>
  {:else}
    <Countdown {countdown} on:completed={triggerStart} />
  {/if}
  <div class="flex jc-center top" class:menu-hidden={$menuOpen}>
    <Button size="small" on:click={finish}>Finish</Button>
  </div>
{:else if results}
  <div class="flex jc-center top" class:menu-hidden={$menuOpen}>
    {#if laser}
      {#if laserDone}
        <div class="m-r-s">
          <Button size="small" on:click={save} disabled={!ref} {loading}>
            Save results
          </Button>
        </div>
        <Button size="small" on:click={start}>Check again</Button>
      {:else}
        {#if laserStartIn}
          <p>Your result appears in {laserFormat}</p>
        {:else}
          <p>Results displayed now.</p>
        {/if}
      {/if}
    {:else}
      {#if saved}
        <div class="m-r-s">
          <Button size="small">
            <Link to={'/' + link}>{linkLabel}</Link>
          </Button>
        </div>
      {:else}
        <div class="m-r-s">
          <Button size="small" on:click={save} disabled={!ref} {loading}>
            Save results
          </Button>
        </div>
      {/if}
      <Button size="small" on:click={start}>Check again</Button>
    {/if}
  </div>

  {#if laser}
    {#if !$user && laserDone}
      <div class="laser">
        <p>Registration is required to save your results</p>
      </div>
    {/if}
  {/if}
  
{:else}
  <div class="flex jc-center top" class:menu-hidden={$menuOpen}>
    {#if laser}
      <div class="m-r-s">
        <Button size="small" on:click={start}>St∆rt</Button>
      </div>
      <Button size="small" on:click={() => navigate('/airplay')}>
        ∆irPl∆y
      </Button>
    {:else}
      <Button size="small" on:click={start}>Check your input</Button>
    {/if}
  </div>
{/if}

<style>
  .laser {
    z-index: 1000;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .start {
    font-size: 88px;
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: -1;
    font-weight: bold;
  }
  .top {
    z-index: 1000;
  }

  #graph {
    z-index: 10;
  }

  .gm-time {
    font-size: 25px;
    width: 100%;
    text-align: center;
  }

  #measurements {
    position: fixed;
    z-index: 10;
    left: 30px;
    top: 100px;
    text-align: left;
    font-size: 12px;
    border-spacing: 0.1px;
  }

  .gm-wrapper {
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    .gm-wrapper {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .gm-time {
      font-size: 20px;
    }
  }
</style>
