<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { onMount, onDestroy, afterUpdate } from 'svelte';
  import { tweened } from 'svelte/motion';
  import { menuOpen } from '../config/stores';

  export let countdown: number;

  const dispatch = createEventDispatcher();
  const numbers = Array.from(Array(countdown).keys())
    .map(it => it + 1)
    .reverse();
  const height = 85;
  const seconds = tweened(0, { duration: 300 }); 
  const initial = countdown;

  let timer = null;

  onMount(() => {
    timer = setInterval(() => {
      countdown -= 1;
    }, 1000);
  })

  afterUpdate(() => {
    seconds.set((initial - countdown) * height);
  })

  onDestroy(() => {
    if (timer) {
      clearInterval(timer);
    }
  })

  $: {
    if (countdown === 0) {
      clearInterval(timer);
      timer = null;
      dispatch('completed');
    }
  }
</script>

<p class="quiet c-white" style="font-size:{height/2}px;" class:menu-hidden={$menuOpen}>
    QUIET<br>PLE∆SE</p>
<div class="display" class:menu-hidden={$menuOpen}>
  <ul style="height:{height}px">
    {#each numbers as num}
      <li class="c-white" style="transform: translateY(-{$seconds}px);font-size:{height/2}px;line-height:{height}px;">
        {num}
      </li>
    {/each}
  </ul>
</div>
<p class="silence c-white" style="font-size:{height/4}px;" class:menu-hidden={$menuOpen}>C∆LCULATING SILENCE</p>

<style>
  .display {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
  }
  .quiet {
    bottom: 25%;
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: -1;
    font-weight: bold;
  }
    .silence {
      top: 30%;
      position: fixed;
      display: flex;
      width: 100vw;
      height: 100vh;
      left: 0;
      justify-content: center;
      align-items: center;
      z-index: -1;
      font-weight: bold;
    }
  ul {
    display: inline-block;
    list-style: none;
    padding-left: 0 !important;
    overflow: hidden;
  }

  ul li {
    font-weight: bold;
  }
</style>
