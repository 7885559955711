<script lang="ts">
  import { page, menuOpen } from '../../config/stores';
  import { navigate } from 'svelte-routing';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <img src="/assets/speculum-mundi/00029.png" alt="" />
  <div class="container">
    <p class="m-y-s"><b>Dimensions and Weight</b></p>
    <p><b>Ø</b> 215,00 cm / ca. 300 kg</p>
    <p class="m-y-s"><b>Material</b></p>
    <p>Hand polished stainless steel (joined and welded).</p>
    <p class="m-y-s"><b>Shape</b></p>
    <p>
      The spherical shape of the plastic comes from the formal analogy between
      numerous micro- to macroscopic "monadic" shapes, which can be traced back
      to the concept <b>∆NIM∆</b> itself. In the <b>∆NIM∆</b> project: <br />
      <b>∆NIM∆</b>
      appears as “air” and wind in the model of aerosols, microscopic particles
      with the microorganisms adhering to them. <br /> <b>∆NIM∆</b> appears also
      as “breath” in the model of the alveoli, the small cavities in the lungs
      in which the actual gas exchange takes place during breathing.<br /><b
        >∆NIM∆</b
      > appears not least as a “life principle” in the model of an organism evoking
      human habitat and hospitality.
    </p>
  </div>
  <img src="/assets/speculum-mundi/00030.png" alt="" />
  <div class="container">
    <p class="m-b-s">
      <b>SPECULUM MUNDI</b> therefore not only reflects the environment on its high-gloss
      and colour-changing surface, but also reflects the suggested "inner", mystical-atomistic
      principle, according to which the entire cosmos is reflected in every micro-logical
      element (monad, atom).
    </p>
    <p>
      From the scientific perspective, however, <b>SPECULUM MUNDI</b> represents
      also the reversal of the principle of order known from crystals (arrangement
      along a grid) – spheres are in contrast “perfect”, because they arrange themselves.
      Because the sphere is the thickest package of matter, any "disturbance" of
      this perfect cosmos brings the matter and form "behind grid" of "growth" -
      a "life" arises.
    </p>
  </div>
  <img src="/assets/speculum-mundi/00031.jpeg" alt="" />
  <div class="container">
    <p>
      <b>SPECULUM MUNDI</b> appears “perfect” not only because of its mystical meanings,
      its physical qualities or its “perfectly” hand-polished surface (the object
      is glazed to a high gloss including antistatic adhesion): The shape of the
      spherical object itself connects it with the whole range of isomorphic entities
      in the macro- until micro-universe, including the spherical galaxies, planets
      with their orbits, down to the Earth and further to the human body with its
      alveoli, which transport the air and aerosols, which again transport the also
      living matter such as bacteria.
    </p>
    <p class="m-y-s"><b>Spherical isomorphism for planetary health</b></p>
    <p class="m-t-s">
      Seen from the “life”-perspective, <b>SPECULUM MUNDI</b> has a close predecessor
      – Sabine Kacunko´s sculpture COSMOPOLITAN (2017): This project was shortlisted
      in the competition for major foyer at Humboldt Forum / Berliner Schloss and
      exhibited 2018 at the Federal Office for Building and Planning in Berlin and
      it had put the omnipresent, yet hardly representable aerosols and the bacteria
      attached to them in the focus of attention. It embodied the microbial dust
      that never really settles down, is always whirling up and again apparently
      taking root, which is transformed into a “cosmopolitan” (including the visitors
      virtually reflecting in it).
    </p>
  </div>
  <img src="/assets/speculum-mundi/00032.jpg" alt="" />
  <div class="container">
    <p>
      As a template for the sculpture, the shape and size of an unusually
      complex aerosol-cluster (consisting of 89 parts) were taken, which
      rendered possible its long residence time in the atmosphere. COSMOPOLITAN
      was supposed to be made of fiberglass and metal and to hang from the
      ceiling while also acting as a resonating body. It should absorb and
      reflect its surroundings both visually due to its mirroring surface, and
      acoustically, due to the sounds initiated by visitors. COSMOPOLITAN
      addressed hence a reflective relationship between the world-wide spread
      living beings and the human potential of becoming a „citizen of the
      world”. In this understanding, COSMOPOLITAN actively reflected the own
      state of the affairs and change them by putting higher demands on the
      environment as the “phlegmatic” ubiquists or “particularistic”, isolated
      endemites, would ever be able and willing to.
    </p>
    <p class="m-y-s">
      Likewise, <b>SPECULUM MUNDI</b> presents in a reduced form, a dynamic
      experimental setup that focuses on the omnipresent, yet barely
      representative aerosols and the bacteria (the first living beings) adhered
      to them, travelling through our alveolar structures of the lungs to
      influence the exchange of matter and energy in our bodies.
      <b>SPECULUM MUNDI</b>
      reflects hence also the equilibrium of nature and culture and stimulates
      humans´ interest in human health, ecological structures and sustainability
      concepts. As such, <b>SPECULUM MUNDI</b> brings together the strands of various
      artistic and scientific disciplines by linking today's concrete references
      to microbiology (the ‘reanimation’ of bacterial cultures) with the historical
      humanistic visions of the future.
    </p>
  </div>
  <img src="/assets/speculum-mundi/img1.jpg" alt="" />
  <div class="container">
    <p><b>Reviving revivals</b></p>
    <p class="m-t-s">
      <b>∆NIM∆</b> as “air” and “breath”, sensed and imagined as aerosols
      shooting through our alveoli to become the foundations of the very “life
      principle” – this is what <b>SPECULUM MUNDI</b>, pars pro toto,
      “represents” with its macro-narrative of the micro-logical scale: In the
      natural and human-polluted environment, winds directions cause dispersion
      of the particles (aerosols) and microorganisms adhering to them, which act
      as “natural fertilizers” of the soil or rainforest on their way from
      Sahara to Europe or South America. The natural and man-made aerosols that
      have been produced since the industrialization are of central importance
      for the chemistry and physics of the atmosphere, the biosphere, as well as
      for climate and health. The airborne particles affect the energy balance
      of the earth. With <b>SPECULUM MUNDI</b>, Sabine Kacunko reminds in a very
      reduced form (of single “aerosol”, “alveoli” and the like) on her previous
      work with the historical bacterial cultures as well.
    </p>
  </div>
  <img src="/assets/speculum-mundi/img4.jpg" alt="" />
  <div class="container">
    <p class="m-t-s">
      In fact, the Bacteria originated from a sample of Sahara dust collected in
      Italy (Calabria) brought Alexander von Humboldt to the insight that this
      “cosmic dust” connects the whole world! The dust sample received by
      Humboldt as a gift from Luigi Sementini in Paris in 1823 was the first
      scientific proof that ecosystems stand in dialogue. This makes the event a
      milestone on the way towards future environmental protection. The sample
      was “revived” by Sabine Kacunko in 2010 in her LIFE FLAG-project, when in
      the 16s rRNA sequence, a new subunit was presented, which occurs both in
      bacteria, plants, animals and humans.
    </p>
  </div>
  <img src="/assets/speculum-mundi/00033.png" alt="" />
  <div class="container">
    <p>
      The bacterial sequence with its concrete historical and geographical
      origins and the current aerosol (which serves as the carrier medium for
      the dust particles, i. a. air containing suspended particles) reflect pars
      pro toto the special interplay of historical (field) research and its
      present-day local mediation again. The increasing problems of air
      pollution, increasing traffic and industrial emissions have a negative
      impact on both the surfaces of historic buildings and humans (inhalated
      aerosol). <b>SPECULUM MUNDI</b> reflects hence on themes related to
      architecture, culture, science and environment through the direct,
      dialogical reference to their present (the used form and material of the
      sculpture) and past (the reflected environment). In conjunction with the
      constant change of perspective of the visitors, the sculpture's
      positioning recalls the perspectives of “cosmopolitan” at the intersection
      of theories and practices of cultural encounters. Both the (carried)
      bacterium and the (carrying) aerosol as well as their symbiosis can be
      identified as "cosmopolitans”. The sculpture embodies the microbial dust,
      which never really settles down, always swirls upwards and seems to take
      root again, and transforms itself into a "cosmopolitan”. It "floats" over
      identities and fixed attributions, from which the alleged opposites
      between "nature" and "culture" are bouncing off: The reflecting beholder
      is lifted, it stands out and still is always been here. Because it has no
      genuine form, <b>SPECULUM MUNDI</b> assumes almost every single one, because
      it is omnipresent, it withdraws from almost every perception.
    </p>
  </div>
  <img src="/assets/speculum-mundi/snipped-2.png" alt="" />
  <div class="container">
    <p>
      With respect to its shape, its changing points of view, light effects,
      partial views and interaction dynamics, <b>SPECULUM MUNDI</b> places the
      perceived contradiction between conception and perception itself in
      perspective and becomes the site of reflexive kinesthesia, focused on the
      temporary and kinetic character of encounter, perception, reflection and
      action. <b>SPECULUM MUNDI</b> attempts hence to achieve a concinnity of artistic,
      scientific and popular evidence that concerns the understanding of the interdependence
      between the human health, natural and cultural heritage, as mediated and exemplified
      by “aerialists” such as the air, the wind, the breeze or breath.
    </p>
  </div>
  <img src="/assets/speculum-mundi/snipped-3.png" alt="" />
  <div class="container">
    <p class="m-b-s"><b>∆NIM∆ <i>in ovo</i></b></p>
    <p>
      <b>SPECULUM MUNDI</b>´s core context is obviously the project ∆NIM∆, a
      conglomerate of artistic actions based on Sabine Kacunko´s previous,
      official UNESCO- and ICOMOS-promoted projects such as <i>Life Flag</i>
      (Berlin 2010), <i>Crystal Mirror</i> (Paris 2011) and <i>Invincible</i>
      (Rome 2015) as well as <i>Looping Life</i> (Berlin 2013) and
      <i>Cosmopolitan</i> (Berlin 2017).
    </p>
    <p class="m-t-s">
      While dealing with microbiology, material science, medicine, meteorology,
      astronomy and anthropology, Kacunko´s work complies to the actual global
      agenda by addressing the essential role of some of the basic sciences for
      sustainable development. In order to meet the variety and complexity of
      the issues in question, <b>∆NIM∆</b> is conceived as a comprehensive program
      of artistic setups and talks that are to be distributed on different venues,
      featuring also heritage sites.
    </p>
  </div>
  <img src="/assets/speculum-mundi/00034.png" alt="" />
  <div class="container">
    <p>
      At its core, <b>∆NIM∆</b> - as artist´s progressive web application - is
      featuring the interconnectedness of the global and local AQI (air quality
      index) as well as individual input to it. As such, <b>∆NIM∆</b> furthers a
      ubiquitous artistic application of aerosols and microorganisms, dust and wind
      at the center of a local and global attention. Being aware of the viral challenges
      on the global scale long before the Covid pandemic, the artist already pioneered
      the general recognition
    </p>
    <p class="m-y-s">
      of aerosols, viruses and bacteria as models for study of humans. The
      global awareness of this kind of interconnectedness has meanwhile
      generated a kind of global public, to be virtually reflected in <b
        >SPECULUM MUNDI.</b
      > The represented reflecting sphere stands pars pro toto for the living structures
      consisting of resembling spherical shapes. All of them emerge in parallel with
      the fading into the related processes situated even more beyond
    </p>
    <p>
      Sabine Kacunko´s project <b>∆NIM∆</b> allows in effect the structures and (hi-)stories
      of nature and culture to appear together from the point of view of art and
      science. The place of the event is, as its name suggests, the “world”, Latin
      mundus, Greek cosmos: The history and structure of nature and culture thus
      also become the medium of art and art the medium of knowledge. Art and science
      are sent on a local, yet “cosmo-political” journey: the local and global audience
      or citizens (polites) find themselves on the stage of the inhabited world (cosmos)
      as an acting observer and performer at the same time. The artistic direction,
      the "storyboard" and the result in this “play” of nature and culture are carried
      out performatively, taken from the experts and also subjected to a democratic
      process – intentionally also in the context of the “citizen science”. It is
      a game that only remains open-ended if it is decisively shaped by many.
    </p>
  </div>
</div>

<div class="bottom-buttons" class:menu-hidden={$menuOpen}>
  <div class="bottom-button" on:click={() => navigate('/stardust')}>
    <span>ST∆RDUST</span>
  </div>
  <div class="bottom-button" on:click={() => navigate('/speculum-mundi')}>
    <span>SPECULUM MUNDI</span>
  </div>
</div>

<div class="top-buttons" class:menu-hidden={$menuOpen}>
  <div class="animated" on:click={() => navigate('/animated-sculpture')}>
    <span>∆NIM∆TED SCULPTURE</span>
  </div>
</div>

<style>
  .grid {
    max-width: 800px;
    width: 90%;
    margin-bottom: 12%;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .container {
    position: relative;
    padding: 50px;
    margin: 12% 0;
    color: #ffffffe0;
    background: #27272745;
    mix-blend-mode: hard-light;
  }

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding-top: 12%;
  }

  p {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    p {
      font-size: 14px;
    }

    h2 {
      font-size: 1.2em;
    }
    .container {
      padding: 20px;
    }
  }
</style>
