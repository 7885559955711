<script lang="ts">
  import { random } from '@jaspero/utils';
  import { collection, doc, setDoc } from 'firebase/firestore';
  import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
  import { navigate } from 'svelte-routing';
  import { formSubmit } from '../../actions/form-submit';
  import Button from '../../components/Button.svelte';
  import Field from '../../components/Field.svelte';
  import { db, storage } from '../../config/firebase';
  import { notification, page, user } from '../../config/stores';

  page.set({
    background: 'transparent',
    footer: false,
    header: 'transparent',
    anima: true
  });

  let loading = false;
  let content = '';
  let file;

  async function submit() {
    // 8MB
    if (file[0].size > 8388608) {
      notification.set(`Please upload an image smaller then 8MB.`);
      return;
    }

    const { uid, username } = $user;

    loading = true;
    
    const id = `sg-${random.string(24)}`;

    const storageRef = ref(
      storage,
      `stardust-gallery/${id}.${file[0].type.split('/')[1]}`
    );
    const snapshot = await uploadBytes(storageRef, file[0]);
    const url = await getDownloadURL(snapshot.ref);

    await setDoc(
      doc(collection(db, 'stardust-gallery'), id),
      {
        createdOn: Date.now(),
        time: new Date().toISOString().split('T')[0],
        content,
        user: uid,
        username,
        url: url.split('&token')[0],
      },
      { merge: true }
    );

    notification.set(`Gallery item added successfully. Thank you!`);
    loading = false;
    navigate('/stardust/gallery');
  }
</script>

<div class="grid">
  <h1 class="col-12 m-b-l c-white">∆DD ITEM</h1>

  <form class="w-full" use:formSubmit={submit}>
    <div class="col-12">
      <Field
        label="Content"
        id="content"
        required={true}
        type="textarea"
        bind:value={content}
      />
    </div>

    <div class="col-12">
      <Field id="image" required={true} type="file" bind:value={file} />
    </div>

    <div class="col-12">
      <Button type="submit" {loading}>Submit</Button>
    </div>
  </form>
</div>

<style>
  .col-12 {
    margin-top: 15px;
  }
</style>
