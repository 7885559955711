<script lang="ts">
  import { navigate } from 'svelte-routing';
  import { page, menuOpen } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <img src="/assets/animated-sculpture/01.png" alt="" />
  <div class="container">
    <p>
      The bipartite generative sculpture examines relationships between natural
      and cultural heritage as well as human health and habitat. The dual
      arrangement is characterized by two space-repelling core plastics: a
      highly polished stainless steel sphere (<b>SPECULUM MUNDI,</b> Ø 215 cm)
      and an irregular polyhedron at approximately the same distance from it,
      executed as a sculpture made of Corten steel (<b>ST∆RDUST,</b> 215 x 257 x
      277 cm)
    </p>
  </div>
  <img src="/assets/animated-sculpture/02.png" alt="" />
  <div class="container">
    <p>
      The arrangement of the sculptural group <b>∆NIM∆TED SCULPTURE</b>
      primarily addresses the supposed bipolarity of "perfect" and "arbitrary"
      form, of "organic" and "mineral" and of "spherical" and "crystalline".
      With a view to the inconcludable dialogue between <b>SPECULUM MUNDI</b>
      and <b>ST∆RDUST</b>, the context of meaning (self-) reflexively begins to
      be opened up to both analytic and synthetic approach.
    </p>
  </div>
  <img src="/assets/animated-sculpture/03.png" alt="" />
  <div class="container">
    <p><b>Materials forming processes</b></p>
    <p class="m-y-s">
      On the formal and material level, addressed is the structural-historical
      relationship that the "spherical" and "crystalline" – represented by <b
        >SPECULUM MUNDI</b
      >
      and <b>ST∆RDUST</b> – enter into in order to produce such a "miracle" as
      "growth": the indentation in the <b>ST∆RDUST</b> is the only concave shape
      on this object, which shows that minerals like to use other minerals for
      their own growth, following a certain matrix or lattice order.
      <b>SPECULUM MUNDI</b>, on the other hand, represents the reversal of the
      principle of order – spheres arrange themselves
    </p>
    <p>
      The use of two industrial materials treated in a complementary way
      (stainless steel and Corten steel) testifies to a “relational” artistic
      approach that rather questions than exclaims. Almost all properties of the
      two objects reflect – conceptually, materially, tactilely, optically,
      structurally, historically – their respective counterparts as "the alien"
      (<b>ST∆RDUST</b> is factually the model of an extraterrestrial particle): to
      be moved by the "alien" always means to allow a dynamic that sets a new, common
      course.
    </p>
  </div>
  <img src="/assets/animated-sculpture/04.png" alt="" />
  <div class="container">
    <p>
      In contrast to the continuously progressing colorfulness of <b
        >ST∆RDUST, SPECULUM MUNDI</b
      >
      remains colorless, although itself reflecting all colors. The changing
      surface quality and color of the <b>ST∆RDUST</b> testifies to another essential
      property of the used Corten steel: This material is particularly suitable for
      outdoor use thanks to its special preparation, which promotes microbial corrosion
      – a subject that the artist has been dealing with for twenty years.
    </p>
  </div>
  <img src="/assets/animated-sculpture/05.jpeg" alt="" />
  <div class="container">
    <p>
      The choice of material therefore has a structural analogy of the Corten
      steel used and the "functioning" of the travertine stone, which
      represented an important aspect of Sabine Kacunko's "Bacteria Art"
      particularly succinctly, as with project <b>INVINCIBLE</b> (2015) the
      "iconic" NW Facade of the Colosseum in Rome was illuminated with its own
      bacteria: The special strength of travertine is not least due to the fact
      that the lime-excreting bacteria living in it always "consolidate" the
      material, which on the surface appears to be rather porous and vulnerable.
      The choice of the material used for extraterrestrial <b>ST∆RDUST</b> is therefore
      based on the comparable basic dynamics of "productive destruction" which –
      as far as is known – is peculiar only to our terrestrial, oxidizing milieu.
    </p>
  </div>
  <img src="/assets/animated-sculpture/06.jpg" alt="" />
  <div class="container">
    <p class="p-b-s"><b>AESTHETIC</b></p>
    <p>
      The effect of the sculptural group is achieved through the interplay of
      volume, mass and weight: the concise form of the <b>SPECULUM MUNDI</b> is
      negated or "dissolved" to the maximum thanks to its highly polished,
      reflective surface, which also underlines a "disproportion" between its
      specific and optical weight . The <b>SPECULUM MUNDI</b> and
      <b>ST∆RDUST</b>, which are optically related to each other qua mirror and
      distance, de facto lead an "ordered" presentation of the sculptural group
      ad absurdum by means of a specific or determining perspective.
    </p>
  </div>
  <img src="/assets/animated-sculpture/05.png" alt="" />
  <div class="container">
    <p>
      As an all-view, free-standing floor sculpture, <b>∆NIM∆TED SCULPTURE</b>
      dispenses with its own directness and therefore to a certain line of sight
      as well as to an “ideal” (Kabakov-like) course of an installation. Rather,
      it is "animated" by the attractive highlights and a changing repertoire of
      light and shadow play: The available light sources (day and night) set the
      plasticity and color of the materials used as well as their surface
      structure and - texture in scene: While the texture of the smooth,
      polished and "cold" sphere of <b>SPECULUM MUNDI</b> has highly ambivalent
      "haptic" qualities, the <b>ST∆RDUST</b>-polyhedron offers a varied play of
      light and shadow with the constant change between mostly convex shapes on
      the multitude of lines separating polygons.
    </p>
  </div>
  <img src="/assets/animated-sculpture/06.png" alt="" />
  <div class="container">
    <p>
      Material and color as well as content and form are released from the
      familiar contexts and set in new and exciting interrelationships: The
      optical and haptic nodes emerge, which invite you to linger but also to
      pause. The art piece as commodity becomes a wondrous and constantly
      fascinating, quasi-natural art object, which is created along the
      interface between art and technology, biology and history, but which also
      opens up when you look at it and walk through it personally. Natural forms
      and materials become art forms and materials and vice versa, as a timeless
      or “generative” sculptures, <b>SPECULUM MUNDI</b> and <b>ST∆RDUST</b> testify
      to transience and invite you to linger.
    </p>
  </div>
  <img src="/assets/animated-sculpture/00010.jpeg" alt="" />
  <div class="container">
    <p>
      <b>∆NIM∆TED SCULPTURE</b> implies not least the generic
      (in)distinguishability of the terms "sculpture" (in the sense of
      "removing" hard material), "plastic" (in the sense of "applying" soft
      material), "object" (in the sense of its found character ("ready- made",
      "objet trouv ") and "installation" (in the sense of its staged character).
      Externally, <b>ST∆RDUST</b> and <b>SPECULUM MUNDI</b> are two larger-than-life
      objects with a block-like effect that are treated in a complementary manner,
      but which enter into a series of relations – both with each other and with
      the chosen exhibition space. The assembled "assemblage" or "installation" in
      this way also fulfills the double function of "animating" and "being animated"
      in relation to its viewers, the surrounding space and the respective exhibition
      context.
    </p>
  </div>
  <!-- <img src="/assets/animated-sculpture/00011.jpeg" alt=""> -->
  <div class="container">
    <p><b>Conceptual background</b></p>
    <p class="m-y-s">
      <b>∆NIM∆</b>: the soul, the breath, the breeze, the air, the wind – it is
      the principle that pervades all life in which the micro- and macrocosm
      meet. <b>∆NIM∆</b> is neither material nor an immaterial substance, it is
      neither composed nor undivided. <b>∆NIM∆TED SCULPTURE</b> takes this
      age-old idea as a pretext to investigate the extent to which “<b>∆NIM∆</b
      >” can serve as a model for dealing with today’s ecological structures,
      sustainability concepts and democratization processes.
    </p>
    <p>
      <b>∆NIM∆TED SCULPTURE</b> belongs to the hybrid exhibition and mediation
      practice that ties in with the forms of public discourse developed by
      Sabine Kacunko since 2005. By linking art and design, sculpture and
      architecture as well as real and virtual space with natural and cultural
      heritage, <b>∆NIM∆TED SCULPTURE</b> takes place at the interface between museum
      and public space and essentially focuses on the relationship between natural
      and cultural heritage.
    </p>
    <p class="m-t-s">
      <b>∆NIM∆TED SCULPTURE</b> arises from the artistic, spiritual and scientific
      vision of a world in which living beings co-produce a peaceful and just coexistence
      with their environment. It "animates" through its transformative aesthetics
      to a dynamic and also interactive, proactive perspective and stimulates discussion
      about the current and future challenges and solution models for a common behavior
      in dealing with natural and cultural resources. Formally, technically and stylistically,
      the sculptural arrangement points to the conceptual space between minimalism
      and maximalism when dealing with steel sculpture (i.a. Tony Smith's "Wandering
      Rocks" / Richard Serra vs. Jeff Koons) but also more generally, when dealing
      with the traditions of artistic ( post-)modern.
    </p>
  </div>
  <img src="/assets/animated-sculpture/00012.png" alt="" />
  <div class="container">
    <p>
      <b
        >∆NIM∆TED SCULPTURE is part of Sabine Kacunko´s project ∆NIM∆, which is
        supported by the International Year of Basic Sciences for Sustainable
        Development 2022 (IYBSSD2022).</b
      >
    </p>
  </div>
</div>

<div class="bottom-buttons" class:menu-hidden={$menuOpen}>
  <div class="bottom-button" on:click={() => navigate('/stardust')}>
    <span>ST∆RDUST</span>
  </div>
  <div class="bottom-button" on:click={() => navigate('/speculum-mundi')}>
    <span>SPECULUM MUNDI</span>
  </div>
</div>

<div class="top-buttons" class:menu-hidden={$menuOpen}>
  <div class="animated" on:click={() => navigate('/animated-sculpture')}>
    <span>∆NIM∆TED SCULPTURE</span>
  </div>
</div>

<style>
  .grid {
    max-width: 800px;
    width: 90%;
    margin-bottom: 12%;
  }

  .container {
    position: relative;
    padding: 50px;
    margin: 12% 0;
    color: #ffffffe0;
    background: #27272745;
    mix-blend-mode: hard-light;
  }

  .container  {
      /*text-shadow: 1 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black;*/
  }


  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding-top: 12%;
  }

  p {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    p {
      font-size: 14px;
    }

    h2 {
      font-size: 1.2em;
    }
    .container {
      padding: 20px;
    }
  }
</style>
