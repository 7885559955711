<script lang="ts">
  import { signInWithEmailAndPassword } from 'firebase/auth';
  import { updateDoc } from 'firebase/firestore';
  import { navigate } from 'svelte-routing';
  import { formSubmit } from '../../actions/form-submit';
  import Button from '../../components/Button.svelte';
  import Field from '../../components/Field.svelte';
  import Password from '../../components/Password.svelte';
  import { auth } from '../../config/firebase';
  import { notification, page } from '../../config/stores';
  import { RESOURCES } from '../../state';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });

  let email = '';
  let password = '';
  let loading = false;

  async function submit() {
    loading = true;
    try {
      const {user} = await signInWithEmailAndPassword(auth, email, password);

      if (RESOURCES.saveResults) {

        await updateDoc(RESOURCES.saveResults as any, {user: user.uid});

        RESOURCES.saveResults = false;
      }

      notification.set('Login successful.');

      navigate(history.state?.redirect ||  '/place');
    } catch (e) {
      password = '';

      const errorMap = {
        'auth/wrong-password': `This username and password combination isn't correct.`,
        'auth/too-many-requests': `You tried to login too many times. Please try again later.`
      }

      notification.set(errorMap[e.code] || 'There was an error with your login. Please try again later.');
    }
    loading = false;
  }
</script>

<div class="grid">
  <h1 class="col-12 m-b-l c-white">LOG IN</h1>

  <form class="w-full" use:formSubmit={submit}>
    <div class="col-12">
      <Field label="E-mail" id="E-mail" required={true} bind:value={email} />
    </div>

    <div class="col-12">
      <Password
        label="Password"
        id="Password"
        required={true}
        bind:value={password}
      />
    </div>

    <div class="col-12">
      <Button type="submit" backgroundColor="#272727cc" {loading}>Log in</Button>
    </div>

    <div class="separator">or</div>

    <div class="col-12">
      <Button link="/sign-up" type="button" backgroundColor="#E445AB">
        Register
      </Button>
    </div>
  </form>
</div>

<style>
  .separator {
    position: relative;
    margin: 20px 0;
    text-align: center;
    opacity: 0.5;
  }
</style>
