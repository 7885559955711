let _DT = 0.016;
export default {
    DT: {
        get() {
            return _DT;
        },
        set(DT) {
            _DT = DT;
        }
    }
};
