import * as THREE from 'three';
import { Color, Matrix3, Mesh, Object3D, ShaderLib, ShaderMaterial, Spherical, UniformsUtils, Vector3 } from 'three';
import { BendingConstraint } from './constraints/BendingConstraint';
import { DistanceConstraint } from './constraints/DistanceConstraint';
import { NormalConstraint } from './constraints/NormalConstraint';
import { EmptyTentacleGeometry } from './geometry/EmptyTentacleGeometry';
import { Segment } from './Segment';
import { SHADERS } from './shaders';
import { clamp } from './utils/math';
import Noise from './utils/noise';
import variables from './variables';
const POINTS = 100;
const SEGMENTS = 10;
const SEGMENTS_GL = 25;
const K_DAMP = 0.00125;
const K_GLOBAL_DAMP = 0.98;
const fGrav = new Vector3(0, 0, 0);
export class Tentacle {
    constructor(pos, parent, name, color, size, bottomThickness, centerThickness, reduceThreshold, strainThreshold) {
        this.position = new Vector3();
        this.normal = new Vector3(0, 1, 0);
        this.angularVel = new Spherical(0, 0, 0);
        this.segments = [];
        this.mass = 1.0 / SEGMENTS;
        this.charge = 0;
        this.minSize = 0.01;
        this._size = this.minSize;
        this.userData = {
            tubularSegments: { value: SEGMENTS_GL },
            radialSegments: { value: POINTS },
            segmentPositions: { value: [] },
            size: { value: 0.0 },
            segmentDir: { value: this.normal },
            segmentNormal: { value: new Vector3() },
            roughness: { value: 0 },
            metalness: { value: 0 }
        };
        this.constraintOptions = {
            restLength: 0.00001,
            kStretch: 0.999,
            solverIterations: 10,
            normal: this.normal,
            kBend: 0.99,
            kNormal: 0.99
        };
        this.dConstraints = [];
        this.bConstraints = [];
        this.nConstraints = [];
        this.adding = true;
        this.removing = false;
        this.shaderMaterial = null;
        this.size = size;
        this.parent = parent;
        this.name = name;
        this.originalPos = pos;
        this.point = new Object3D();
        this.point.position.copy(pos);
        this.parent.group.add(this.point);
        this.point.updateMatrixWorld(true);
        this.position.setFromMatrixPosition(this.point.matrixWorld);
        this.normal.copy(this.position.clone().sub(this.point.parent.position).normalize());
        this.createSegments();
        this.setSegmentPositions();
        this.createGeometry();
        this.noise = new Noise();
        this.userData.diffuse = { value: new Color(color) };
        const uniforms = Object.assign(Object.assign({}, UniformsUtils.clone(ShaderLib.standard.uniforms)), this.userData);
        this.defaultRandoms = {
            bottomThickness: { value: bottomThickness },
            centerThickness: { value: centerThickness },
            reduceThreshold: { value: reduceThreshold },
            strainThreshold: { value: strainThreshold },
        };
        uniforms.bottomThickness = { value: bottomThickness };
        uniforms.centerThickness = { value: centerThickness };
        uniforms.reduceThreshold = { value: reduceThreshold };
        uniforms.strainThreshold = { value: strainThreshold };
        this.uniforms = uniforms;
        this.shaderMaterial = new ShaderMaterial({
            uniforms,
            vertexShader: SHADERS.loaded.tentacle.vertex.replace('NUM_SEGMENTS 10', `NUM_SEGMENTS ${SEGMENTS}`),
            fragmentShader: SHADERS.loaded.tentacle.fragment.replace('NUM_SEGMENTS 10', `NUM_SEGMENTS ${SEGMENTS}`),
            lights: true,
            transparent: false,
            blending: THREE.NoBlending,
            premultipliedAlpha: false
        });
        this.userData = this.shaderMaterial.uniforms;
        this.object = new Mesh(this.geometry, this.shaderMaterial);
        this.parent.scene.add(this.object);
        this.update();
    }
    set size(size) {
        this._size = size;
        this.userData.size.value = size;
        this.constraintOptions.restLength = size;
    }
    get size() {
        return this._size;
    }
    setPosition(pos) {
        this.point.position.copy(pos);
        this.position.setFromMatrixPosition(this.point.matrixWorld);
        this.normal.copy(this.position.clone().sub(this.point.parent.position).normalize());
        const startDir = new Vector3(1.0, 0.0, 0.0);
        let proj = this.normal.dot(startDir);
        const EPSILON = 0.0001;
        if (proj >= 1.0 - EPSILON || proj <= -1.0 + EPSILON) {
            startDir.add(new Vector3(0, 0.001, 0));
            proj = this.normal.dot(startDir);
        }
        this.userData.segmentNormal.value.copy(startDir.sub(this.normal.clone().multiplyScalar(proj))).normalize();
    }
    createSegments() {
        let pos;
        let prev;
        let prevPrev;
        for (let i = 0; i < SEGMENTS; i++) {
            pos = this.position.clone().add(this.normal.clone().multiplyScalar(i * this.size));
            const segment = new Segment(pos, this.normal.clone(), this.size);
            if (i === 0) {
                segment.invMass = 0;
            }
            this.segments.push(segment);
            if (prev) {
                this.dConstraints.push(new DistanceConstraint(prev, segment, this.constraintOptions));
            }
            if (prev && prevPrev) {
                this.bConstraints.push(new BendingConstraint(prevPrev, prev, segment, this.constraintOptions));
            }
            prev && (prevPrev = prev);
            prev = segment;
        }
        this.nConstraints.push(new NormalConstraint(this.segments[0], this.segments[1], this.constraintOptions));
    }
    setSegmentPositions() {
        this.userData.segmentPositions.value = this.segments.map(s => s.position);
    }
    createGeometry() {
        this.geometry = new EmptyTentacleGeometry(this.userData.tubularSegments.value, this.userData.radialSegments.value);
    }
    computeForces() {
        let segment;
        for (let i = 0; i < this.segments.length; i += 1) {
            segment = this.segments[i];
            segment.totalForce.set(0, 0, 0);
            if (segment.invMass > 0) {
                segment.totalForce.add(fGrav.clone().multiplyScalar(segment.mass));
                segment.totalForce.add(this.normal.clone().multiplyScalar(Math.max(0, (0.5 - this.constraintOptions.restLength)) * 5));
                const force = this.noise.simplex3(segment.position.x, (+new Date()) / 8000, segment.position.z) + 0.03;
                if (this.parent.target && i + 1 === this.segments.length) {
                    const diff = this.parent.target.clone().sub(segment.position);
                    const dist = diff.length();
                    diff.normalize();
                    diff.multiplyScalar(clamp(5 * Math.sqrt(dist), 1, 10));
                    segment.totalForce.add(diff);
                    segment.totalForce.add(new Vector3(0, force * 0.2, 0));
                }
                else {
                    segment.totalForce.add(new Vector3(0, force, 0));
                }
            }
        }
    }
    integrateExplicitWithDamping(deltaTime) {
        let positionMassCenter = new Vector3(0, 0, 0);
        let velocityMassCenter = new Vector3(0, 0, 0);
        let sumMass = 0;
        for (let segment of this.segments) {
            segment.velocity.multiplyScalar(K_GLOBAL_DAMP);
            segment.velocity.add(segment.totalForce
                .clone()
                .multiplyScalar(deltaTime)
                .multiplyScalar(segment.invMass));
            positionMassCenter.add(segment.position.clone().multiplyScalar(this.mass));
            velocityMassCenter.add(segment.velocity.clone().multiplyScalar(this.mass));
            sumMass += this.mass;
        }
        positionMassCenter.multiplyScalar(1 / sumMass);
        velocityMassCenter.multiplyScalar(1 / sumMass);
        let I = new Matrix3();
        let L = new Vector3();
        let omega;
        for (let segment of this.segments) {
            segment.rotationAxisDistance = segment.position.clone().sub(positionMassCenter);
            L.add(segment.rotationAxisDistance.clone().cross(segment.velocity.clone().multiplyScalar(this.mass)));
            let rad = segment.rotationAxisDistance;
            let inertiaMatrix = new Matrix3().set(0, -rad.z, rad.y, rad.z, 0, -rad.x, -rad.y, rad.x, 0);
            let res = inertiaMatrix.clone().multiply(inertiaMatrix.clone().transpose()).multiplyScalar(this.mass);
            let ie = I.elements;
            let re = res.elements;
            I.set(ie[0] + re[0], ie[1] + re[1], ie[2] + re[2], ie[3] + re[3], ie[4] + re[4], ie[5] + re[5], ie[6] + re[6], ie[7] + re[7], ie[8] + re[8]);
        }
        omega = L.applyMatrix3(I.copy(I).invert());
        for (let segment of this.segments) {
            let velocityDelta = velocityMassCenter
                .clone()
                .add(segment.rotationAxisDistance.clone().cross(omega))
                .sub(segment.velocity);
            segment.velocity.add(velocityDelta.clone().multiplyScalar(K_DAMP));
        }
    }
    calculatePredictedPosition(deltaTime) {
        for (let segment of this.segments) {
            if (segment.invMass <= 0.0) {
                segment.prediction = segment.position.clone();
            }
            else {
                segment.prediction = segment.position.clone()
                    .add(segment.velocity.clone().multiplyScalar(deltaTime));
            }
        }
    }
    updateInternalConstraints() {
        for (let si = 0; si < this.constraintOptions.solverIterations; si += 1) {
            for (let dConstraint of this.dConstraints) {
                dConstraint.update();
            }
            for (let bConstraint of this.bConstraints) {
                bConstraint.update();
            }
            for (let nConstraint of this.nConstraints) {
                nConstraint.update();
            }
        }
    }
    updateParticles(deltaTime) {
        const invDt = 1.0 / deltaTime;
        for (let segment of this.segments) {
            segment.velocity.copy(segment.prediction.clone()
                .sub(segment.position).multiplyScalar(invDt));
            if (segment === this.segments[0]) {
                segment.position.copy(this.position);
                segment.normal.copy(this.normal);
            }
            else {
                segment.position.copy(segment.prediction);
            }
        }
    }
    update() {
        const DT = variables.DT.get();
        this.computeForces();
        this.integrateExplicitWithDamping(DT);
        this.calculatePredictedPosition(DT);
        this.updateInternalConstraints();
        this.updateParticles(DT);
    }
}
