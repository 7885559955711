<script lang="ts">
  import { httpsCallable } from 'firebase/functions';
  import { onMount } from 'svelte';
  import Button from '../../components/Button.svelte';
  import { STRIPE } from '../../config/config';
  import { functions } from '../../config/firebase';
  import { page, notification } from '../../config/stores';

  let loading = true;
  let stripe: any;
  let elements: any;

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });

  async function submit() {
    loading = true;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://anima-web.de/complete-payment',
      },
    });

    if (error) {
      notification.set(error.message)
    }
    loading = false;
  }

  async function stripeLoaded() {
    // @ts-ignore
    stripe = window.Stripe(STRIPE.publicKey);

    const data: any = await httpsCallable(functions, 'stripe-paymentIntent')();

    elements = stripe.elements({
      clientSecret: data.data.clientSecret,
      appearance: {
        theme: 'night',
      },
    });
    const paymentElement = elements.create('payment');
    paymentElement.mount('#pe');

    loading = false;
  }

  onMount(async () => {
    // @ts-ignore
    if (window.Stripe) {
      await stripeLoaded();
    }
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">Payment</h1>
  <p class="col-12 p-y-0 c-white">Please fill in the details below to complete your payment.</p>
  <p class="col-12 p-y-0 c-white">You will be charged <b>10€</b> for the purchase.</p>
  <p class="col-12 p-y-0 c-white">For this you will receive <b>1 credit</b> that can be used for downloading a high resolution image.</p>

  <div id="pe" class="col-12" />

  <Button size="small" {loading} on:click={submit}>
    Submit
  </Button>
</div>

<svelte:head>
  {#if !window.Stripe}
    <script src="https://js.stripe.com/v3/" on:load={stripeLoaded}></script>
  {/if}
</svelte:head>

<style>
  #pe {
    margin: 20px;
    padding: 20px;
  }
</style>
