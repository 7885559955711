<script lang="ts">
  import { onMount } from 'svelte';
  import { Route, Router } from 'svelte-routing';
  import Header from './components/Header.svelte';
  import Notification from './components/Notification.svelte';
  import { menuOpen, page } from './config/stores';
  import Actual from './routes/actual/Actual.svelte';
  import AirPlay from './routes/airplay/AirPlay.svelte';
  import Berlin from './routes/airplay/Berlin.svelte';
  import AnimatedSculpture from './routes/animated-sculpture/AnimatedSculpture.svelte';
  import AnnouncingAnima from './routes/announcing-anima/AnnouncingAnima.svelte';
  import Artist from './routes/artist/Artist.svelte';
  import CompletePayment from './routes/complete-payment/CompletePayment.svelte';
  import Concept from './routes/concept/Concept.svelte';
  import DataAndAlgo from './routes/data-and-algo/DataAndAlgo.svelte';
  import Globe from './routes/globe/Globe.svelte';
  import Legal from './routes/legal/Legal.svelte';
  import Login from './routes/login/Login.svelte';
  import Manual from './routes/manual/Manual.svelte';
  import Payment from './routes/Payment/Payment.svelte';
  import Place from './routes/place/Place.svelte';
  import Privacy from './routes/privacy/Privacy.svelte';
  import Random from './routes/random/Random.svelte';
  import SignUp from './routes/sign-up/SignUp.svelte';
  import SpeculumMundi from './routes/speculum-mundi/SpeculumMundi.svelte';
  import AddGalleryItem from './routes/stardust/AddGalleryItem.svelte';
  import Gallery from './routes/stardust/Gallery.svelte';
  import SingleGallery from './routes/stardust/SingleGallery.svelte';
  import Stardust from './routes/stardust/Stardust.svelte';
  import Terms from './routes/terms/Terms.svelte';
  import Video from './routes/video/Video.svelte';
  import View from './routes/view/View.svelte';
  import WindroseView from './routes/windrose-view/WindroseView.svelte';
  import Windrose from './routes/windrose/Windrose.svelte';

  export let url = '';

  onMount(async () => {
    page.subscribe((data: any) => {
      document.body.style.background = data.background;
      menuOpen.set(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    });
  });
</script>

<Router {url}>
  <Header />
  <main>
    <Route path="login" component={Login} />
    <Route path="sign-up" component={SignUp} />
    <Route path="privacy" component={Privacy} />
    <Route path="terms" component={Terms} />
    <Route path="view" component={View} />
    <Route path="airplay" component={AirPlay} />
    <Route path="airplay/berlin" component={Berlin} />
    <Route path="windrose-view" component={WindroseView} />
    <Route path="announcing-anima" component={AnnouncingAnima} />
    <Route path="animated-sculpture" component={AnimatedSculpture} />
    <Route path="stardust" component={Stardust} />
    <Route path="stardust/gallery" component={Gallery} />
    <Route path="stardust/add" component={AddGalleryItem} />
    <Route path="stardust/gallery/:id" let:params>
      <SingleGallery id={params.id} />
    </Route>
    <Route path="speculum-mundi" component={SpeculumMundi} />
    <Route path="artist" component={Artist} />

    <!--Archive-->
    <Route path="actual" component={Actual} />
    <Route path="random" component={Random} />
    <Route path="windrose" component={Windrose} />
    <Route path="place" component={Place} />

    <!--Intro-->
    <Route path="manual" component={Manual} />
    <Route path="concept" component={Concept} />
    <Route path="video" component={Video} />
    <Route path="data-and-algorithms" component={DataAndAlgo} />

    <!--Public-->
    <Route path="legal" component={Legal} />

    <!--Private-->
    <Route path="payment" component={Payment} />
    <Route path="complete-payment" component={CompletePayment} />

    <Route path="" component={Globe} />
  </main>
</Router>

<Notification />
