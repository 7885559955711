<script lang="ts">
  import { onDestroy } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Anima from '../../components/anima/Anima.svelte';
  import Button from '../../components/Button.svelte';
  import { animationStarted, page, menuOpen } from '../../config/stores';
  import { RESOURCES } from '../../state';

  page.set({
    background: 'transparent',
    footer: false,
    header: 'transparent',
    anima: false
  });

  const data = {
    lat: RESOURCES.location.lat,
    lng: RESOURCES.location.lng,
  };

  let permissionGranted;
  let audioGranted;
  let locationGranted;

  async function currentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
      });
    });
  }

  async function permissions() {
    /**
     * Request audio permission
     */
    if (!audioGranted) {
      try {
        audioGranted = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });
        RESOURCES.stream = audioGranted;
      } catch (e) {
        console.error('Audio', e);
      }
    }

    if (!locationGranted && navigator.geolocation) {
      try {
        let state = 'granted';

        if (navigator.permissions) {
          state = (await navigator.permissions.query({ name: 'geolocation' }))
            .state;
        }

        if (state === 'prompt' || state === 'granted') {
          locationGranted = await currentPosition();
        }
      } catch (e) {
        console.error('Location', e);
      }
    }

    if (locationGranted) {
      data.lat = locationGranted.coords.latitude;
      data.lng = locationGranted.coords.longitude;

      RESOURCES.location.lat = data.lat;
      RESOURCES.location.lng = data.lng;
    }

    permissionGranted = !!(audioGranted && locationGranted);
  }

  async function start() {
    await permissions();

    if (permissionGranted) {
      animationStarted.set(true);
    }
  }

  function manual() {
    navigate('/manual');
  }

  onDestroy(() => {
    animationStarted.set(false);
  });
</script>

<Anima real={true} {data} {audioGranted} />

{#if permissionGranted === false}
  <footer>
    <div class="permission-warning">
      <p class="c-white">
        Please activate location tracking and microphone.
      </p>
    </div>
  </footer>
{:else if !permissionGranted}
  <footer class:menu-hidden={$menuOpen}>
    <div class="m-r-s">
      <Button size="small" on:click={manual}>
        M∆NU∆L
      </Button>
    </div>
    <Button size="small" on:click={start}>
      st∆rt
    </Button>
  </footer>
{/if}

<style>
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
</style>
