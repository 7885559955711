<script lang="ts">
  import { onDestroy } from 'svelte';
  import { Link } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import { animationStarted,page } from '../../config/stores';
  import { RESOURCES } from '../../state';
  import Scene from './components/Scene.svelte';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: false
  });

  const data = {
    lat: RESOURCES.location.lat,
    lng: RESOURCES.location.lng,
  };

  let permissionGranted;
  let audioGranted;
  let locationGranted;

  async function currentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
      });
    });
  }

  async function permissions() {
    /**
     * Request audio permission
     */
    if (!audioGranted) {
      try {
        audioGranted = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });
        RESOURCES.stream = audioGranted;
      } catch (e) {
        console.error('Audio', e);
      }
    }

    if (!locationGranted && navigator.geolocation) {
      try {
        let state = 'granted';

        if (navigator.permissions) {
          state = (await navigator.permissions.query({ name: 'geolocation' }))
            .state;
        }

        if (state === 'prompt' || state === 'granted') {
          locationGranted = await currentPosition();
        }
      } catch (e) {
        console.error('Location', e);
      }
    }

    if (locationGranted) {
      data.lat = locationGranted.coords.latitude;
      data.lng = locationGranted.coords.longitude;

      RESOURCES.location.lat = data.lat;
      RESOURCES.location.lng = data.lng;
    }

    permissionGranted = !!(audioGranted && locationGranted);
  }

  async function start() {
    await permissions();

    if (permissionGranted) {
      animationStarted.set(true);
    }
  }

  onDestroy(() => {
    animationStarted.set(false);
  });
</script>

<Scene results={{}} {data} {audioGranted} />

{#if permissionGranted === false}
  <footer>
    <div class="permission-warning">
      <p class="c-white">
        Please activate location tracking and microphone.
      </p>
    </div>
  </footer>
{:else if !permissionGranted}
  <footer>
    <Button size="small" on:click={start}>
      st∆rt
    </Button>
  </footer>
{/if}

<div class="history">
  <Link to="/actual">History</Link>
</div>

<style>
  footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .history {
    position: fixed;
    bottom: 36px;
    right: 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(39, 39, 39);
    height: 40px;
    padding: 0 24px;
    line-height: 40px;
    border-radius: 10px;
    font-size: 14px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Yellix Regular', sans-serif;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }

  @media (max-width: 600px) {
    .history {
      top: 23px;
      bottom: unset;
      right: 70px;
    }
  }
</style>
