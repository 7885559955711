<script>
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">PRIV∆CY POLICY</h1>
  <p class="col-12 c-white">
    ∆NIMA is project of the Studio Sabine Kacunko. This privacy policy will
    explain how our organization uses the personal data we collect from you when
    you use our website.
  </p>
  <p class="col-12 c-white">Topics:</p>
  <ul class="col-12 c-white">
    <li>What data do we collect?</li>
    <li>How do we collect your data?</li>
    <li>How will we use your data?</li>
    <li>How do we store your data?</li>
    <li>Marketing</li>
    <li>What are your data protection rights?</li>
    <li>What are cookies?</li>
    <li>How do we use cookies?</li>
    <li>What types of cookies do we use?</li>
    <li>How to manage your cookies</li>
    <li>Privacy policies of other websites</li>
    <li>Changes to our privacy policy</li>
    <li>How to contact us</li>
    <li>How to contact the appropriate authorities</li>
  </ul>
  <h2 class="col-12 c-white">What data do we collect?</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko collects the following data:
  </p>
  <ul class="col-12 c-white">
    <li>
      Personal identification information (Name, email address, phone number,
      etc.)
    </li>
  </ul>
  <p class="col-12 c-white">How do we collect your data?</p>
  <p class="col-12 c-white">
    You directly provide Studio Sabine Kacunko with most of the data we collect.
    We collect data and process data when you:
  </p>
  <ul class="col-12 c-white">
    <li>
      Register online or place an order for any of our products or services.
    </li>
    <li>
      Voluntarily complete a customer survey or provide feedback on any of our
      message boards or via email.
    </li>
    <li>Use or view our website via your browser&#39;s cookies.</li>
  </ul>
  <h2 class="col-12 c-white">How will we use your data?</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko collects your data so that we can:
  </p>
  <ul class="col-12 c-white">
    <li>Process your order, manage your account.</li>
    <li>
      Email you with special offers on other products and services we think you
      might like. lf you agree, Studio Sabine Kacunko will share your data with
      our partner companies so that they may offer you their products and
      services.
    </li>
  </ul>
  <p class="col-12 c-white">
    When Studio Sabine Kacunko processes your order, it may send your data to,
    and also use the resulting information from, credit reference agencies to
    prevent fraudulent purchases.
  </p>
  <p class="col-12 c-white">
  User will keep hold of the uploaded photographs, and we will not us it for any other purpose, except of keeping it visible on our Website. We are not responsible for what other users will potentially do with the uploaded and available photographs.
  </p>

  <h2 class="col-12 c-white">How do we store your data?</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko securely stores your data at our servers. Studio
    Sabine Kacunko will keep your input-data for one year. Once this time period
    has expired, we will delete your data from our servers, unless you wish to
    keep them there.
  </p>
  <h2 class="col-12 c-white">Marketing</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko would like to send you information about products and
    services of ours that we think you might like, as well as those of our
    partner companies. lf you have agreed to receive marketing, you may always
    opt out at a later date. You have the right at any time to stop Studio
    Sabine Kacunko from contacting you for marketing purposes or giving your
    data to others.
  </p>
  <h2 class="col-12 c-white">What are your data protection rights?</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko would like to make sure you are fully aware of all of
    your data protection rights. Every user is entitled to the following: The
    right to access - You have the right to request Studio Sabine Kacunko for
    copies of your personal data. We may charge you a small fee for this
    service. The right to rectification - You have the right to request that
    Studio Sabine Kacunko correct any information you believe is inaccurate. You
    also have the right to request Studio Sabine Kacunko to complete information
    you believe is incomplete.
  </p>
  <p class="col-12 c-white">
    <b>The right to erasure</b> - You have the right to request that Studio Sabine
    Kacunko erase your personal data, under certain conditions.
  </p>
  <p class="col-12 c-white">
    <b>The right to restrict processing</b> - You have the right to request that
    Studio Sabine Kacunko restrict the processing of your personal data, under certain
    conditions.
  </p>
  <p class="col-12 c-white">
    <b>The right to object to processing</b> - You have the right to object to Studio
    Sabine Kacunko's processing of your personal data, under certain conditions.
  </p>
  <p class="col-12 c-white">
    <b>The right to data portability</b> - You have the right to request that Studio
    Sabine Kacunko transfer the data that we have collected to another organization,
    or directly to you, under certain conditions.
  </p>
  <p class="col-12 c-white">
    lf you make a request, we have one month to respond to you. lf you would
    like to exercise any of these rights, please contact us at our email: <a
      href="mailto:info@anima-web.de">info@anima-web.de</a
    >
  </p>
  <p class="col-12 c-white">
    Call us at: <a href="tel:+4917643529053">+49 176 43529053</a>
  </p>
  <h2 class="col-12 c-white">What are cookies?</h2>
  <p class="col-12 c-white">
    Cookies are text files placed on your computer to collect standard Internet
    log information and visitor behavior information. When you visit our
    websites, we may collect information from you automatically through cookies
    or similar technology. For further information, visit allaboutcookies.org.
  </p>
  <h2 class="col-12 c-white">How do we use cookies?</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko uses cookies in a range of ways to improve your
    experience on our website, including:
  </p>
  <ul class="col-12 c-white">
    <li>Keeping you signed in</li>
    <li>Understanding how you use our website</li>
  </ul>
  <p class="col-12 c-white">What types of cookies do we use?</p>
  <p class="col-12 c-white">
    There are a number of different types of cookies, however, our website uses:
  </p>
  <ul class="col-12 c-white">
    <li>
      Functionality - Studio Sabine Kacunko uses these cookies so that we
      recognize you on our website and remember your previously selected
      preferences. These could include what language you prefer and location you
      are in. A mix of first-party and third-party cookies are used.
    </li>
    <li>
      Advertising - Studio Sabine Kacunko uses these cookies to collect
      information about your visit to our website, the content you viewed, the
      links you followed and information about your browser, device, and your
      log-in information.
    </li>
  </ul>
  <h2 class="col-12 c-white">How to manage cookies</h2>
  <p class="col-12 c-white">
    You can set your browser not to accept cookies, and the above website tells
    you how to remove cookies from your browser. However, in a few cases, some
    of our website features may not function as a result.
  </p>
  <h2 class="col-12 c-white">Privacy policies of other websites</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko website contains links to other websites. Our privacy
    policy applies only to our website, so if you click on a link to another
    website, you should read their privacy policy.
  </p>
  <h2 class="col-12 c-white">Changes to our privacy policy</h2>
  <p class="col-12 c-white">
    Studio Sabine Kacunko keeps its privacy policy under regular review and
    places any updates on this web page.
  </p>
  <p class="col-12 c-white">
    This privacy policy was last updated on August 15th 2022.
  </p>
  <h2 class="col-12 c-white">How to contact us</h2>
  <p class="col-12 c-white">
    lf you have any questions about Studio Sabine Kacunko&#39;s privacy policy,
    the data we hold on you, or you would like to exercise one of your data
    protection rights, please do not hesitate to contact us.
  </p>
  <p class="col-12 c-white">
    Email us at: <a href="mailto:info@anima-web.de">info@anima-web.de</a>
  </p>
  <p class="col-12 c-white m-b-l">
    Call us at: <a href="tel:+4917643529053">+49 176 43529053</a>
  </p>
</div>
