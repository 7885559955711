import { EPSILON } from './constraints';
export class DistanceConstraint {
    constructor(p1, p2, opts) {
        this.p1 = p1;
        this.p2 = p2;
        this.opts = opts;
        this.k_prime = Math.pow(1 - opts.kStretch, 1 / opts.solverIterations);
        if (1 - this.k_prime > 1.0) {
            this.k_prime = 1.0;
        }
    }
    update() {
        const dir = this.p1.prediction.clone().sub(this.p2.prediction);
        const len = dir.length();
        if (len <= EPSILON) {
            return;
        }
        const invMass1 = this.p1.invMass;
        const invMass2 = this.p2.invMass;
        const invMass = invMass1 + invMass2;
        if (invMass <= EPSILON) {
            return;
        }
        const dP = dir.clone().multiplyScalar(1 / len * (1 / invMass) * (len - this.opts.restLength) * (1 - this.k_prime));
        if (invMass1 > 0.0) {
            this.p1.prediction.sub(dP.clone().multiplyScalar(invMass1));
        }
        if (invMass2 > 0.0) {
            this.p2.prediction.add(dP.clone().multiplyScalar(invMass2));
        }
    }
}
