<script lang="ts">
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true,
  });
</script>

<div class="grid">
  <img src="/assets/announcing/001-upper-centre.jpg " alt="" class="" />
  <div class="container">
    <h2 class="col-12 p-y-0 ta-center title-medium">∆NNOUNCING</h2>
    <h1 class="col-12 p-y-0 ta-center title-big">∆NIM∆</h1>
    <p class="col-12 p-y-0 ta-center p-big">
      launching bilboard-campaign <br />on the Kahbaum area <br /> construction site
      in
    </p>
    <br /><br />
    <p class="col-12 p-y-0 ta-center location">Görlitz, <br />Germany</p>
    <p class="col-12 p-y-0 date ta-right">January, 2022/24</p>
  </div>
  <img src="/assets/building.png " alt="" class="" />
  <div class="container">
    <p>
      <b>∆NNOUNCING ∆NIM∆</b> - under this name, Sabine Kacunko announced her
      global and locally anchored art and culture project: <br /><br />It is an
      oversized announcement in the form of an approximately 400 square meter
      billboard on house 1 of the Kahlbaum area in Görlitz. Since January
      26/2022, the giant poster has surrounded the scaffolded building at the
      exposed inner-city intersection of Dr.-Kahlbaum-Allee and
      James-von-Moltke-Strasse.
    </p>
    <br />
    <p>
      <b>The poster campaign</b>, designed to last two years, is the prelude to
      a dialogical and process-oriented art and culture project that is intended
      also to provide information on the history, present and future of the
      Kahlbaum area.
    </p>
  </div>
  <img src="/assets/image-5.png " alt="" class="" />
  <div class="container">
    <p>
      <b>∆NIM∆</b> - the soul, the breath, the breeze, the air, the wind: It is
      the ancient motif of the principle that permeates all life was the
      inspiration and background of the art and culture project, which Sabine
      Kacunko will present in several stages.<br /><br /> The project should take
      place step by step in dialogue with interested parties and local initiatives
      as well as in parallel in virtual space - in a dialogue that involves art and
      science as well as business and politics and last but not least the citizens
      (citizen science).
    </p>
  </div>
  <img src="/assets/announcing/007-left-draft.jpg" alt="" class="" />
  <div class="container">
    <p>
      <b>ΔNIMΔ</b> is part of a cultural project on hybrid exhibition and mediation
      practice (ΔMPITHEΔTRUM MUNDI), which in turn ties in with the forms of public
      discourse developed by Sabine Kacunko since 2005.
    </p>
    <br />
    <p>
      These take place at the interfaces between museum and public space. They
      address the relationship between natural and cultural heritage, hence the
      close connection between the terms Human – Health – Heritage – Habitat.
    </p>
  </div>
  <img src="/assets/image-1.jpg " alt="" class="" />
  <div class="container">
    <p class="p-big ta-center">
      It is an oversized announcement in the form of an approximately 400 square
      meter billboard on house 1 of the Kahlbaum area in Görlitz.
    </p>
  </div>
  <img src="/assets/announcing/011-banner-detail-left.jpg " alt="" />
  <div class="container">
    <p>
      If you look at the sphere, you realize the shape of the universe: the
      shared idea of the cosmos respiring chaos.
      <br />
      This is where the ∆NIM∆ arises: from the swirl of matter and energy.
      <br /> <br />
      The animated prospect leads you through the remoted galaxies to the own one.
      Suns and planets are but the sand grains in your discerning eye. You cannot
      discern between the visions any longer: Is it artistic, scientific, spiritual?
      <br /> <br />
      If you take a deep breath, you can dive into your solar system: It´s like a
      fragile solar plexus of the Milky Way; a blow to this region would get the
      wind knocked out of it your world would stop to breathe.
      <br /> <br />
      However, the animated matter continues to emerge and end up in ever-new spiral
      rotations. The coincidence of the real and virtual requires a large narrative
      on a micro logical scale: The history and structure of nature and culture thus
      become the medium of art. The art becomes the medium of knowledge.
      <br /> <br />
      The spherical shapes of the distributed worlds stand out from the surroundings,
      while adhering to the dynamic structure: You apprehend the character and content
      of an artwork, which is both free-floating and anchored in a created reflective
      space.
      <br /> <br />
      Blow your mind in concert with others. To be moved by the other means to allow
      a dynamic that defines a new, common course. Human, habitat, health and heritage
      can be thought through with their cluster-like social models and survival strategies.
    </p>
  </div>
  <video width="100%" controls>
    <source src="/assets/MICRO.mp4" type="video/mp4" />
  </video>
  <div class="container">
    <p>
      This is where ∆NIM∆ arises: It is artistic, scientific, and spiritual.
      <br /> <br />
      Imagine the model of an immortal organism that reproduces the maintenance and
      renewal of the life cycle in itself. If you zoom onto your planet, it may evoke
      the emergence and formation of a lung, associated with the formation and transformation
      of the living structures consisting of resembling spherical shapes.
      <br /> <br />
      Zoom into the lung of Nature with its bronchial tree built out of the alveoli
      clusters; ∆NIM∆ appears as a “breath” in the model of the alveoli, the small
      cavities in the lungs in which the actual gas exchange takes place during breathing.<br
      /> <br /> Follow the path of grape-shaped aerosol clusters and fade into
      the processes that contribute decisively to the human life as a literally
      pervasive principle.
      <br /> <br />
      ∆NIM∆: the soul, the breath, the breeze, the air, and the wind: it is the principle
      that pervades all life in which the micro- and macrocosm meet. ∆NIM∆ is neither
      material nor an immaterial substance; it is neither composed nor undivided.
      It takes the age-old idea as an opportunity to investigate the extent to which
      it can serve as a model.
      <br /> <br />
      The history of your travel has changed its pace and rhythm, ending with the
      universe of a single aerosol. If you now look at the sphere, you realize the
      shape of an aerosol. You realize in it the shape of the universe – the shared
      idea of the cosmos respiring chaos.
    </p>
  </div>
  <img src="/assets/announcing/015-last-image.jpg" alt="" />
  <div class="container">
    <br />
    <h1 class="title">M∆NY TH∆NKS TO</h1>
    <br /><br />
    <h2 class="p-t-m">PROJEKT P∆RTNERS ∆ND SPONSORS</h2>
    <p>
      Max Planck Institut Mainz
      <br />
      Gerüstbau Tisch GmbH Berlin
      <br />
      Bannerheld Berlin
    </p>
    <h2 class="p-t-m">
      FELLOW CAMP∆IGNER ∆ND<br /> SYMP∆THIS∆NTS FROM GÖRLITZ
    </h2>
    <p>
      Pro Kahlbaum Initiative
      <br />
      goerlitz21 e.V.
      <br />
      Initiative Görlitz gGmbH
      <br />
      Micro Human gUG, Zweigstelle Görlitz
    </p>
  </div>
</div>

<style>
  .container {
    position: relative;
    margin: 12% 0;
    color: white;
    background: #27272745;
    mix-blend-mode: hard-light;
  }
  .title {
    font-size: 112px;
  }
  .title-big {
    margin-top: -90px;
    font-size: 190px;
  }
  .grid {
    max-width: 800px;
    width: 90%;
    margin-bottom: 12%;
  }
  .p-big {
    font-size: 50px;
    line-height: 45px;
    font-weight: bold;
  }

  .location {
    font-size: 120px;
    font-weight: bold;
    line-height: 95px;
  }
  .date {
    font-size: 40px;
    padding-right: 80px;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .title-medium {
    font-size: 70px;
  }

  .title {
    font-size: 160px;
    line-height: 140px;
  }

  p {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    .title-medium {
      font-size: 100px;
    }
    .title-big {
      margin-top: -70px;
      font-size: 160px;
    }
    .p-big {
      font-size: 40px;
    }
    .location {
      font-size: 80px;
    }
    .date {
      font-size: 30px;
    }
  }

  @media (max-width: 700px) {
    .title-medium {
      font-size: 90px;
    }
    .title-big {
      margin-top: -70px;
      font-size: 160px;
    }
    .p-big {
      font-size: 40px;
    }
    .location {
      font-size: 80px;
    }
    .date {
      font-size: 50px;
    }
  }

  @media (max-width: 600px) {
    .title-medium {
      font-size: 75px;
    }
    .title-big {
      margin-top: -70px;
      font-size: 160px;
    }
    .p-big {
      font-size: 40px;
    }
    .location {
      font-size: 80px;
    }
    .date {
      font-size: 50px;
    }
    .title {
      font-size: 100px;
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 14px;
    }
    .title {
      font-size: 80px;
      line-height: 70px;
    }
    h2 {
      font-size: 1.1em;
    }
    .title-medium {
      font-size: 36px;
    }
    .title-big {
      margin-top: -40px;
      font-size: 100px;
      line-height: 100px;
    }
    .p-big {
      font-size: 22px;
      line-height: 25px;
    }
    .location {
      font-size: 55px;
      line-height: 40px;
    }
    .date {
      font-size: 17px;
      line-height: 10px;
      padding-right: 40px;
    }
  }
  @media (max-width: 400px) {
    .title-medium {
      font-size: 36px;
    }
    .title-big {
      margin-top: -40px;
      font-size: 100px;
    }
  }
</style>
