<script lang="ts">
  import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
  } from 'firebase/firestore';
  import { onMount } from 'svelte';
  import Button from '../../components/Button.svelte';
  import WindroseHistoryCard from '../../components/WindroseHistoryCard.svelte';
  import { db } from '../../config/firebase';
  import { page } from '../../config/stores';
  import Scene from './components/Scene.svelte';
  import { Link } from 'svelte-routing';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: false
  });

  let results: {
    global: { [key: string]: number };
  };
  let items = [];
  let hasMore = true;
  let loading = true;
  let ref;

  async function loadMore() {
    loading = true;

    const operations = [
      orderBy('createdOn', 'desc'),
      ...(ref ? [startAfter(ref)] : []),
      limit(11),
    ];

    const { docs } = await getDocs(
      query(collection(db, 'windrose-history'), ...operations)
    );

    items = [...items, ...docs.slice(0, 10).map((it) => it.data())];

    hasMore = docs.length === 11;
    ref = docs[10];
    loading = false;
  }

  onMount(async () => {
    const [global] = await Promise.all([
      getDoc(doc(db, 'archive', 'main')),
      loadMore(),
    ]);

    results = {
      global: global.data() || {},
    };
  });
</script>

{#if results}
  <div class="anima-wrapper">
    <Scene {results} />
  </div>
{/if}

<div class="grid">
  {#each items as item}
    <div class="col-12 m-b-l">
      <WindroseHistoryCard {item} />
    </div>
  {/each}

  {#if hasMore}
    <div class="col-12">
      <Button {loading} on:click={loadMore}>Load More</Button>
    </div>
  {/if}
</div>

<div class="return-link bottom-button">
  <Link to="/windrose">RETURN</Link>
</div>

<style>
  .return-link {
    position: fixed;
    top: 23px;
    right: 70px;
    z-index: 10;
  }
</style>