<script>
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">∆RTIST</h1>
  <p class="col-12 p-y-0 c-white">
    Sabine Kacunko´s artistic practice investigate concepts of sustainability,
    ecological structures and social models with intention to boost and engender
    interdisciplinary cooperation between the local, micro logical worlds and
    the world (cosmos) in its global, macro logical sense. Her projects,
    installations and performances emerge from the vision of a world in which
    cultures and living beings co-produce their coexistence. As cosmopolitans,
    they inhabit and co-create the natural and cultural world they reflect.
    While stimulating strands of various artistic and scientific disciplines,
    Sabine Kacunko´s artworks link the concrete microbiological material with
    her micro-human visions of the future.
    <br /> <br />
    After studying art, design, photography, and art history in Göttingen, Düsseldorf,
    Krefeld and Essen, Sabine Kacunko continued her study with research residences
    in Paris, New York, Beijing, Zagreb, Reykjavik, before she established her studio
    in Berlin in 2008. Here, the work spreads over the major tracks of Sabine Kacunko´s
    artistic development including analogue b/w photography, slide photography and
    photographic installations, interactive light and closed circuit video installations,
    media performances and permanent installations as well as product designs. The
    range of the works produced covers audio and networked sculpture and choreography
    as well as performances and projections and collaborations with major cultural
    heritage sites and scientific institutions, media partners and diplomatic offices.
    <br /> <br />
    Sabine Kacunko´s work has been exhibited, among others, at the Ecolé des Beaux-Arts,
    Paris; Colosseum, Rome; MACRO Museum, Rome; Fotogalerie Fabrik Heeder, Krefeld;
    Jasim Galerie, Düsseldorf; Artist-Gallery, Kassel; Galerie New World, Düsseldorf;
    Galerie Schüppenhauer, Köln; Städtische Galerie Wesseling; Galerie 44, Barcelona;
    Galerie Mönter, Düsseldorf; Medical Museion, University Copenhagen; Plange Mühle,
    Düsseldorf; University of Osnabrück; Platform China, Beijing; Schlossplatz, Berlin-Mitte;
    Robert Koch Forum, Berlin; Collegium Hungaricum Berlin; Museum Viersen; Kunstraum,
    Düsseldorf; Art Museum Klovićevi Dvori, Zagreb; Raum X, Düsseldorf; Kunstmuseum
    Ehrenhof Düsseldorf; Gallery Hans Mayer, Düsseldorf; Artkino, Frankfurt; Art
    Pavillon, Zagreb; LichtRouten, Lüdenscheid; Rechtsrheinischer Kunstverein) Cologne;
    Aristotle Universität Thessaloniki; Concent Art, Berlin; Total Museum Seoul;
    Martin-Gropius- Bau, Berlin; Robert Koch-Institut Campus Seestraße; Bundesamt
    für Bauwesen und Raumordnung, Berlin.
    <br /> <br />
    <a
      href="https://sabinekacunko.com"
      target="_blank"
      style="text-decoration: underline;">https://sabinekacunko.com</a
    >
  </p>
</div>
