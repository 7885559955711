<script lang="ts">
  import { navigate } from 'svelte-routing';
  import { page, menuOpen } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <img src="/assets/stardust/05.png" alt="" />
  <div class="container">
    <p>
      The plastic made of Corten steel, weighing almost a ton, is reminiscent of
      the polyhedron from Dürer's "Melancolia I" (1514) or of a stylized, bitten
      apple. Sabine Kacunko's <b>ST∆RDUST</b> (2021) is nevertheless something
      very concrete, which goes beyond its physical presence: <b>ST∆RDUST</b> is
      the sculptural replica of a microscopic grain that fell to earth 25 years ago
      with a meteorite. This "space mineral" points to the similarities between (micro-)photographic,
      scientific and artistic processes: it is the suspension of sizes and relationships
      that brings art and science together, as does the mystical connection between
      "micro" and " macro".
    </p>
  </div>
  <img src="/assets/stardust/1.jpg" alt="" />
  <div class="container">
    <p>
      "Landed" in the "crater" of Körnerpark in Berlin-Neukölln (sculpture´s
      first public exhibition), Kacunko's <b>ST∆RDUST</b> tells us a story of its
      origin that coincides with the structure of the represented crystal. The extraterrestrial
      grain shows us the organizing principle of the “crystalline”: Its only "indentation"
      is – like a "navel" – the negative form of another mineral that it "fed" on
      for a while in order to be able to grow. Minerals apparently use other minerals
      for their own growth, always following a certain matrix. What do we learn from
      such objective limits to growth? Where do we look for the "navel of the world"?
      Where do we go?
    </p>
  </div>
  <img src="/assets/stardust/2.jpg" alt="" />
  <div class="container">
    <p>
      The sculpture is on display from August 15th until October 15th 2022
      around the clock in front of the <b>Galerie im Körnerpark</b> in Berlin, Neukölln’s
      first municipal gallery.
    </p>
  </div>
  <img src="/assets/stardust/3.jpg" alt="" />
  <div class="container">
    <p><b>Dimensions</b> and <b>Weight</b></p>
    <p class="m-y-s">2571,65 mm x 2774,27 mm x 2150,00 mm / ca. 900 kg</p>
    <p><b>Material</b></p>
    <p class="m-t-s">
      Corten steel 3mm + 2mm (construction and finish). The sculpture is made
      according to the artist's own 3-D model, based on an electron micrograph.
      Modeling, processing, cutting, welding, grinding and chemical treatment
      followed.
    </p>
  </div>
  <img src="/assets/stardust/00017.jpg" alt="" />
  <div class="container">
    <p>
      The changing surface texture and color address an essential property of
      the Corten steel used: This material is particularly suitable for outdoor
      use thanks to its special preparation, which promotes microbial corrosion.
      This special type of corrosion (MIC - Microbiologically Influenced
      Corrosion), which accounts for up to 40% of the total corrosion of steel
      in the industrial sector, is used here specifically as protection or
      "sealing".
    </p>
  </div>
  <img src="/assets/stardust/00018.jpg" alt="" />
  <div class="container">
    <p>
      The microorganisms that promote corrosion through their metabolic
      activities (especially bacteria) thus become the structural support of the
      sculpture, while the future of its surface color is only approximately
      determined. The main groups associated with MIC are sulfate-reducing
      bacteria and archaea, thiosulfate-reducing bacteria, acid-forming
      bacteria, iron-oxidizing bacteria, iron-reducing bacteria,
      nitrate-reducing bacteria, and methanogenic archaea.
    </p>
  </div>
  <img src="/assets/stardust/00019.jpg" alt="" />
  <div class="container">
    <p class="m-b-s"><b>Shape</b></p>
    <p>
      <b>ST∆RDUST</b> is a space-repellent core plastic (irregular polyhedron). The
      underlying 3-D model is about the three-dimensional completion of the two-dimensional,
      electron microscopic image of an extraterrestrial particle – a silicon nitride
      grain (Si3N4) –, which was chemically separated from the meteorite Sahara 97166
      in 1997.
    </p>
  </div>
  <img src="/assets/stardust/00020.jpg" alt="" />
  <div class="container">
    <p><b>Contents</b></p>
    <p class="m-y-s"><b>The “crystalline”</b></p>
    <p>
      It is a superhuman-sized, three-dimensional replica of the model of a
      possible “pre-solar grain”, which is so far only known and accessible
      through a two- dimensional electron micrograph. The chemical composition
      of the particle reveals that it is a hard "space mineral" of
      extraterrestrial origin that represents the ordering principle of the
      "crystalline" (the inverse of the ordering principle is the principle of
      the "spherical" – the spheres “arrange themselves”)
    </p>
  </div>
  <img src="/assets/stardust/00021.jpg" alt="" />
  <div class="container">
    <p>
      The sculpture therefore demonstrates the way in which a "miracle" such as
      "growth" is to be achieved: the "indentation" is the only concave shape on
      the object, which shows that minerals “like” to use other minerals for
      their own growth by following specific matrix or lattice order: The
      "indentation" represents the negative form of another mineral that was
      removed in the process of dissolution (chemical analysis).
    </p>
  </div>
  <img src="/assets/stardust/8.jpg" alt="" />
  <div class="container">
    <p class="m-b-s"><b>The "growth"</b></p>
    <p>
      If we want to go even further back in the history of the represented,
      paradigmatic "space mineral", we have to realize that it, representative
      of its "species ", initially "grows" only in two dimensions, and only
      after a certain phase (much later) does it go into the third dimension.
      The reason for this is that the structures of the crystals can only be
      ordered step by step – following a matrix or a lattice. Hence the
      corresponding idea of (limits to) growth(s) as catching-up becomes
      adressed.
    </p>
  </div>
  <img src="/assets/stardust/00023.jpeg" alt="" />
  <div class="container">
    <p class="m-b-s"><b>The “alien”</b></p>
    <p>
      The used extraterrestrial particle is a silicon nitride grain, chemically
      separated from the Sahara 97166 meteorite. Sahara 97166 is a so-called
      enstatite chondrite, forming a rare class of primitive meteorites. It was
      found in the Sahara in 1997, hence the name. Stardust, or "pre-solar
      grains" are tiny particles of matter either derived from primitive
      meteorites or collected as Interplanetary Dust Particles (IDP) during
      space missions. Their isotopic compositions show that they are
      extraterrestrial and this applies without exception to grains containing
      silicon carbide (SiC). The pre-solar origin of grains consisting of
      silicon nitride (Si3N4), on the other hand, can only be identified by
      isotopic analysis, which in the case of the used particle has not yet
      happened.
    </p>
  </div>
  <img src="/assets/stardust/00024.jpeg" alt="" />
  <div class="container">
    <p class="m-b-s"><b>The scientific collaboration</b></p>
    <p>
      <b>ST∆RDUST</b> is a superhuman-sized three-dimensional replica of the model
      of an extraterrestial particle that is so far only known and accessible through
      a two-dimensional electron micrograph. The chemical composition of the granule
      reveals that it is a hard "space mineral" of extraterrestrial origin.
    </p>
    <p>
      We thank <b>Peter Hoppe</b> (group leader of the particle chemistry department
      of the Max Planck Institute for Chemistry [https://www.mpic.de]) for the image
      usage rights to the selected grain.
    </p>
  </div>
  <img src="/assets/stardust/6.jpg" alt="" />
  <div class="container">
    <p>
      Our special thanks go to <b>Joachim Huth</b> (Max Planck Institute for Chemistry,
      Mainz) for the valuable tips, explanations and discussions: As MPI for Chemistry,
      J. Huth was responsible for a series of recordings, some of which originate
      from the Hubble Space Telescope and NASA's "Stardust" mission (1999-2006).
      In this context, for example, the dust grains condensed in the winds of the
      planetary nebula NGC 6751 were fetched, which were eventually incorporated
      back into the matter of a new star system, such as the Sun. "NanoSIMS" (a spatially
      high-resolution secondary ion mass spectrometer) has been in operation at the
      Max Planck Institute for Chemistry in Mainz since 2001 and is the only device
      in Germany to date that can detect presolar dust grains smaller than 0.3 μm
      , .
    </p>
  </div>
  <img src="/assets/stardust/5.jpg" alt="" />
  <div class="container">
    <p>
      Our thanks also goes to Dr. <b>Astrid Besmehn</b> (Mineralogist from the Central
      Institute for Engineering, Electronics and Analytics (ZEA), whose analyzes
      of pre-solar grains have contributed to the elucidation of the origin not only
      of the particles.
    </p>
  </div>
  <img src="/assets/stardust/4.jpg" alt=""/>
  <img src="/assets/stardust/7.jpg" alt="" style="margin: 12% 0"/>
  <div class="bottom-button higher" on:click={() => navigate('/stardust/gallery')}>
    <span>Gallery</span>
  </div>
  <div class="footer-space"></div>
</div>

<div class="bottom-buttons" class:menu-hidden={$menuOpen}>
  <div class="bottom-button" on:click={() => navigate('/stardust')}>
    <span>ST∆RDUST</span>
  </div>
  <div class="bottom-button" on:click={() => navigate('/speculum-mundi')}>
    <span>SPECULUM MUNDI</span>
  </div>
</div>

<div class="top-buttons" class:menu-hidden={$menuOpen}>
  <div class="animated" on:click={() => navigate('/animated-sculpture')}>
    <span>∆NIM∆TED SCULPTURE</span>
  </div>
</div>

<style>
  .grid {
    max-width: 800px;
    width: 90%;
    margin-bottom: 12%;
  }

  .higher {
    margin-top: 30px;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .container {
    position: relative;
    padding: 50px;
    margin: 12% 0;
    color: #ffffffe0;
    background: #27272745;
    mix-blend-mode: hard-light;
  }

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    padding-top: 12%;
  }

  p {
    font-size: 18px;
  }

  .footer-space {
    height: 120px;
    width: 100%;
  }

  @media (max-width: 500px) {
    p {
      font-size: 14px;
    }

    h2 {
      font-size: 1.2em;
    }
    .container {
      padding: 20px;
    }
  }
</style>
