<script>
  import { page } from '../../config/stores';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 p-y-0 c-white">CONCEPT</h1>

  <p class="col-12 p-y-0 c-white">
    The ∆NIM∆ ∆PP is an artistic work in progress (AWP) by Sabine Kacunko, conceived as a progressive web application (PWA) - a process with an open end:  AWP → PWA → AWP... 
    More concretely, the ∆NIM∆ ∆PP is an online platform to artistically explore the local
    intersections between the global air quality index and individual human
    activities. Based on actual data and real inputs of diverse research and
    common institutions and supported by the individual usages, the ∆NIM∆ ∆PP is
    an artistic effort to widen the scope of visualizing the conflux of global,
    local and individual air streams and to interpret them in an even more
    profound and pro-active way. By reaching out the broadest audience both in
    public and personal settings and providing an exciting interactive aesthetic
    and educational experience, the ∆NIM∆ ∆PP aims at leading to new awareness,
    knowledge and action to support global efforts in limiting the human-induced
    consequences of the climate change. Moreover, this platform addresses the
    aspired “healing” aspects also on conceptual level by pointing out the
    interconnectedness of all “living” or “animated” beings, being relevant not
    least to the human health and -medicine:
    <br /> <br />

    ∆NIM∆: the soul, the breath, the breeze, the air, the wind – it is the
    principle that pervades all life in which the micro- and macrocosm meet.
    ∆NIM∆ is neither material nor an immaterial substance, it is neither
    composed nor undivided. As such, it points to a shared experience that seems
    to be equally anchored in the real and virtual world.
    <br /> <br />
    ∆NIM∆ arises from the artistic, spiritual and scientific vision of a world in
    which living beings co-produce a peaceful and just coexistence with their environment.
    It stimulates through its transformative and transversal aesthetics, dynamic
    and responsive as well as proactive perspective the discussion about current
    and future challenges and solution models for a common behavior in dealing with
    natural and cultural resources.
    <br /> <br />
    ∆NIM∆ art project takes the age-old idea of the same name as an opportunity to
    investigate the extent to which it can serve as a model for dealing with today's
    ecological structures, sustainability concepts and democratization processes.
    <br /> <br />
    ∆NIM∆ circumscribes the relationship between natural and cultural heritage artistically
    implemented as a complex of generative sculptures designed for the public space.
    <br /> <br />
    ∆NIM∆ is a place of activity dedicated to the links between humans, health, heritage
    and habitat. It is both a homage and a prospect to their uncertain histories
    and futures.
    <br /> <br />
    ∆NIM∆ appears as "air" and wind in the model of aerosols, microscopic particles
    with the microorganisms adhering to them. ∆NIM∆ appears as "breath" in the model
    of the alveoli, the small cavities in the lungs in which the actual gas exchange
    takes place during breathing. ∆NIM∆ appears as a "life principle" in the model
    of an organism evoking human habitat.
    <br /> <br />
    ∆NIM∆ as “air”: The natural aerosols and those produced by humans are of central
    importance for the chemistry and physics of the atmosphere, for the biosphere
    and for the climate. They are distributed around the world, for example, on their
    way from Africa to Europe or to South America as a “natural fertilizer” in the
    rainforest. Thanks to their size, they also effortlessly penetrate human alveoli
    and reach our bloodstream. They hence have an immediate impact on our health.
    Because they are almost formless, the aerosols take on almost any shape, because
    they are omnipresent and almost imperceptible.
    <br /> <br />
    ∆NIM∆ as “breath”: Like their counterparts from the air, the aerosols, the alveoli
    also form grape-shaped clusters from their almost round, microscopic individual
    particles and contribute decisively to human life as a literally pervasive principle.
    <br /> <br />
    ∆NIM∆ a “life principle”: It appears in models of potentially biological organisms
    that reproduce the maintenance and renewal of the life cycle in itself.
    <br /> <br />

  <h1 class="col-12 p-y-0 c-white">Poetic introduction</h1>

  <p class="col-12 p-y-0 c-white">
    If you look at the sphere, you realize the shape of the universe: the shared
    idea of the cosmos respiring chaos.
    This is where the ∆NIM∆ arises: from the swirl of matter and energy.
    The animated prospect leads you through the remoted galaxies to the own one.
    <br /> <br />
    Suns and planets are but the sand grains in your discerning eye.
    You cannot discern between the visions any longer: Is it artistic, scientific,
    spiritual?
    If you take a deep breath, you can dive into your solar system: 
    It´s like a fragile solar plexus of the Milky Way; 
    a blow to this region would get the wind knocked out of it your world would stop to breathe.
    <br /> <br />
    However, the animated matter continues to emerge and end up in ever-new spiral
    rotations.
    The coincidence of the real and virtual requires a large narrative on a micro
    logical scale: 
    The history and structure of nature and culture thus become the medium of art.
    The art becomes the medium of knowledge.
    <br /> <br />
    The spherical shapes of the distributed worlds stand out from the surroundings,
    while adhering to the dynamic structure: 
    You apprehend the character and content of an artwork, which is both free-floating and anchored in a created reflective
    space.
    <br /> <br />
    Blow your mind in concert with others.
    To be moved by the other means to allow a dynamic that defines a new, common
    course.
    Human, habitat, health and heritage can be thought through with their cluster-like
    social models and survival strategies.
    This is where ∆NIM∆ arises: It is artistic, scientific, and spiritual.
    <br /> <br />
    Imagine the model of an immortal organism that reproduces the maintenance and
    renewal of the life cycle in itself.
    If you zoom onto your planet, it may evoke the emergence and formation of a lung,
    associated with the formation and transformation of the living structures consisting
    of resembling spherical shapes.
    <br /> <br />
    Zoom into the lung of Nature with its bronchial tree built out of the alveoli
    clusters; 
    ∆NIM∆ appears as a "breath" in the model of the alveoli, the small cavities in the lungs in which the actual gas exchange takes place during breathing.
    <br /> <br />
    Follow the path of grape-shaped aerosol clusters and fade into the processes that contribute decisively to the human life as a literally pervasive principle.
    ∆NIM∆: the soul, the breath, the breeze, the air, and the wind: it is the principle that pervades all life in which the micro- and macrocosm meet.
    <br /> <br />
    ∆NIM∆ is neither material nor an immaterial substance; it is neither composed nor undivided. 
    It takes the age-old idea as an opportunity to investigate the extent to which
    it can serve as a model.
    <br /> <br />
    The history of your travel has changed its pace and rhythm, ending with the universe
    of a single aerosol. 
    If you now look at the sphere, you realize the shape of an aerosol.
    You realize in it the shape of the universe – the shared idea of the cosmos respiring
    chaos.
    <br /> <br />

  </p>
</div>
