export class BendingConstraint {
    constructor(seg1, seg2, seg3, opts) {
        this.p1 = seg1;
        this.p2 = seg2;
        this.p3 = seg3;
        this.opts = opts;
        this.w = seg1.invMass + seg2.invMass + 2 * seg3.invMass;
        this.k_prime = Math.pow(1.0 - this.opts.kBend, 1.0 / this.opts.solverIterations);
        if (1 - this.k_prime > 1.0) {
            this.k_prime = 1.0;
        }
    }
    update() {
        const center = this.p1.prediction
            .clone()
            .add(this.p2.prediction)
            .add(this.p3.prediction)
            .multiplyScalar(0.3333);
        //glm::vec3 dir_center = tmp_X[c.p3]-center;
        const dirCenter = this.p3.prediction.clone().sub(center);
        const distCenter = dirCenter.length();
        const diff = 1.0 - (this.opts.restLength / distCenter);
        const dir_force = dirCenter.multiplyScalar(diff);
        if (this.p1.invMass > 0.0) {
            const fa = dir_force.clone().multiplyScalar((1 - this.k_prime) * ((2 * this.p1.invMass) / this.w));
            this.p1.prediction.add(fa);
        }
        if (this.p2.invMass > 0.0) {
            const fb = dir_force.clone().multiplyScalar((1 - this.k_prime) * ((2 * this.p2.invMass) / this.w));
            this.p2.prediction.add(fb);
        }
        if (this.p3.invMass > 0.0) {
            const fc = dir_force.clone().multiplyScalar(-(1 - this.k_prime) * ((4 * this.p3.invMass) / this.w));
            this.p3.prediction.add(fc);
        }
    }
}
