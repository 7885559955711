export const MIC_INPUT = {
    /**
     * Defines the range for the final calculation
     */
    inputRange: 233,
    /**
     * Defines the maximum duration in milliseconds for breathing out.
     */
    maxBreatheOutDuration: 20 * 1000
};
export const AQI_TOKEN = 'c0a680a23513e50d68fd7226b13d5835fb8d6da1';
export const STRIPE = {
    publicKey: 'pk_live_51KfUDmD6tLG8ylEMUWLvE9M93MWQxoy7Mkjvd0HpzdcPZRW8cU7yBRlbPYNYVrMCemdPDIR4pu8t2e8LjBnkeChP00PdmLkYPx'
};
