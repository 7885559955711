<script>
  import { page } from '../../config/stores';
  import { RESOURCES } from '../../state';

  page.set({
    background: 'black',
    footer: false,
    header: 'transparent',
    anima: true
  });
</script>

<div class="grid">
  <h1 class="col-12 c-white m-b-m">VIDEO</h1>
</div>

<div class="video-container">
  <video src={RESOURCES.video} controls />
</div>

<style>
  .video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
