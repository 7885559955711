<script>
  /**
   *
   * @type {string}
   */
  export let type = 'text';

  /**
   *
   * @type {boolean}
   */
  export let required = false;

  /**
   *  Specify input label
   *  @type {string}
   */
  export let label = '';

  /**
   *  Specify input id
   *  @type {string}
   */
  export let id = undefined;

  /**
   *  Specify input value
   *  @type {string}
   */
  export let value = undefined;

  /**
   * Specify autocomplete
   * @type {"email" | "username" | "off" | "on"}
   */
  export let autocomplete = 'off';
</script>

<label for={id}>
  {#if type === 'email'}
    <input
      type="email"
      placeholder={label}
      {id}
      {autocomplete}
      {required}
      bind:value
    />
  {:else if type === 'textarea'}
    <textarea
      maxlength="233"
      placeholder={label}
      {id}
      {required}
      rows="10"
      bind:value
    />
  {:else if type === 'file'}
    <input
      type="file"
      class="file"
      accept="image/gif, image/png, image/jpeg"
      placeholder={label}
      {id}
      {autocomplete}
      {required}
      bind:files={value}
    />
  {:else}
    <input
      type="text"
      placeholder={label}
      {id}
      {autocomplete}
      {required}
      bind:value
    />
  {/if}
  {#if label}
    <span>{label}</span>
  {/if}
</label>

<style>
  label {
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0 0 0 1px transparent;
    background-color: white;
    height: 50px;
    border-radius: 16px;
    transition: border 0.3s, box-shadow 0.3s;
  }

  label:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  }

  label:focus-within {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
    -webkit-background-clip: text;
  }

  input,
  textarea {
    display: flex;
    position: relative;
    line-height: 16px;
    font-size: 16px;
    padding: 12px 16px;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    color: rgba(0, 0, 0, 0.9);
    caret-color: rgba(0, 0, 0, 0.9);
  }

  textarea {
    height: 100px;
    padding: 18px 16px;
  }

  .file {
    padding: 13px !important;
  }

  input:not(:placeholder-shown),
  input:focus:not(:placeholder-shown) {
    padding: 24px 16px 4px;
  }

  textarea:not(:placeholder-shown),
  textarea:focus:not(:placeholder-shown) {
    padding: 20px 16px 4px;
  }

  input:not(:placeholder-shown) + span,
  input:focus:not(:placeholder-shown) + span,
  textarea:not(:placeholder-shown) + span,
  textarea:focus:not(:placeholder-shown) + span {
    transform: translateY(-10px);
    font-size: 10px;
  }

  textarea + span {
    top: -8px;
  }

  span {
    display: block;
    position: absolute;
    left: 16px;
    color: rgba(0, 0, 0, 0.6);
    transition: transform 0.3s, font-size 0.3s;
    pointer-events: none;
    user-select: none;
    font-size: 16px;
  }

  ::placeholder {
    visibility: hidden;
  }
</style>
