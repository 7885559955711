<script lang="ts">
  import { Link } from 'svelte-routing';
  import { onMount } from 'svelte';
  import { COLORS } from '../config/colors.const';
  import { hsvToHex } from '../utils/hsv-to-rgb';
  import { convertDEGToDMS } from './anima/utils/convert-deg-to-dms';
  import { user } from '../config/stores';

  export let item: {
    lat: number;
    lng: number;
    airQuality: number;
    createdOn: number;
    lungCapacity: number;
    anima: any;
    user?: string;
    scaledAverageVolume?: number;
    maxScaledVolume?: number;
  };

  let lungColor;
  let date;
  let lat;
  let lng;
  let globeColor;

  onMount(() => {
    date = new Date(item.createdOn).toLocaleDateString();
    lat = convertDEGToDMS(item.lat);
    lng = convertDEGToDMS(item.lng, false);
    globeColor = COLORS[Math.round((item.airQuality * COLORS.length) / 500)];
    lungColor = hsvToHex((item.lungCapacity * 100) / 233, 1, 1);
  });
</script>

<Link
  to="/view"
  state={{
    createdOn: item.createdOn,
    globeColor: globeColor,
    lungColor: lungColor,
    lungCapacity: item.lungCapacity,
    anima: item.anima,
    lat: lat,
    lng: lng,
    scaledAverageVolume: item.scaledAverageVolume,
    maxScaledVolume: item.maxScaledVolume
  }}
>
  <div class="card">
    <div class="card-header">
      <div class="coordinates">
        <p>{lat}</p>
        <p>{lng}</p>
      </div>
      {#if $user && $user.uid === item.user}
        <div class="user-flag">
          <span>YOU</span>
        </div>
      {/if}
    </div>

    <p class="ta-center">PRESS TO VIEW</p>

    <div class="flex jc-center ai-center m-t-m m-b-m relative">
      <div class="circle" style="background-color:{globeColor}" />
      <div class="bar" style="background-color:{lungColor}" />
    </div>

    <div class="grid nogutter">
      <div class="col-4 ta-center">
        <p class="info-title m-b-xs">D∆TE</p>
        <p class="info">{date}</p>
      </div>

      <div class="col-4 ta-center">
        <p class="info-title m-b-xs">∆IR</p>
        <p class="info">{item.airQuality.toFixed(2)}</p>
      </div>

      <div class="col-4 ta-center">
        <p class="info-title m-b-xs">BRE∆TH</p>
        <p class="info">{item.lungCapacity.toFixed(2)}</p>
      </div>
    </div>
  </div>
</Link>

<style>
  .card {
    background-color: #21212180;
    border: 0.5px solid #392f2f;
    border-radius: 20px;
    padding: 20px 20px 10px;
    color: #ffffffe0;
    cursor: pointer;
  }

  .nogutter {
    margin: auto !important;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
  }
  .coordinates {
    font-size: 10px;
  }

  .user-flag span {
    border: 1px solid white;
    padding: 5px 10px;
  }
  .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .bar {
    z-index: 1;
    position: relative;
    width: 10px;
    height: 68px;
    border-radius: 5px;
  }

  .info-title {
    font-family: 'Yellix SemiBold', sans-serif;
    font-size: 12px;
    text-decoration: underline;
  }
  .info {
    font-size: 10px;
  }
  .grid {
    padding: 0;
  }
  .relative {
    position: relative;
  }
</style>
